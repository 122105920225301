import moment from "moment";

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

const AVAILABLE_TABS = {
  'epackData': 'Epack Data',
  'constructor': 'Editor',
  'dataSources': 'Data Sources'
}

// WARNING order matters
const AVAILABLE_SIZES = {
  'desktop': 1200,
  'laptop': 1100,
  'tablet': 768,
  'mobile': 320
}

const AVAILABLE_SIZE_NAMES = {
  'desktop': 'Desktop',
  'laptop': 'Laptop',
  'tablet': 'Tablet',
  'mobile': 'Mobile'
}

const AVAILABLE_SIZES_LIST = Object.entries(AVAILABLE_SIZES).map(([sizeSlug, sizeValue]) => ({
  slug: sizeSlug,
  name: AVAILABLE_SIZE_NAMES[sizeSlug],
  value: sizeValue
}))

const AVAILABLE_COUNTRIES = [
  'ae',
  'az',
  'by',
  'kg',
  'kz',
  'ro',
  'md',
  'lv',
  'ru',
  'ua',
]

const AVAILABLE_LANGUAGES = [
  'ar',
  'az',
  'by',
  'en',
  'ro',
  'kg',
  'kz',
  'lv',
  'ru',
  'uk',
]

const DEFAULT_TEMPLATES = [
  'master_template',
]

const EPACK_TYPE_SIS = {
  key: 'sis',
  name: 'Shop In Shop'
}

const EPACK_TYPE_MINISITE = {
  key: 'minisite',
  name: 'Minisite'
}

const AVAILABLE_EPACK_TYPES = {
  [EPACK_TYPE_SIS.key]: EPACK_TYPE_SIS,
  [EPACK_TYPE_MINISITE.key]: EPACK_TYPE_MINISITE
}

const AVAILABLE_EPACK_TYPES_LIST = Object.values(AVAILABLE_EPACK_TYPES)

const DEFAULT_EPACK_DATA = {
  type: AVAILABLE_EPACK_TYPES.sis.key,
  manifest: {
    createdDate: moment().format(DATE_FORMAT),
    updatedDate: moment().format(DATE_FORMAT),
    version: "1.0",
    packageID: "",
    emsId: "constructor",
    author: localStorage.getItem("ttlUserInfo") !== null ? JSON.parse(localStorage.getItem("ttlUserInfo"))["username"] : "",
    productData: {
      brandName: "",
      productName: "",
      mappingId: String(moment().unix()),
    },
    data: {
      ru: {
        minisite: {},
      },
    },
  },
  components: {
    ru: (() => {
      const templates = {}
      DEFAULT_TEMPLATES.forEach(t => {
        templates[t] = { 'index.html': [] }
      })
      return templates
    })()
  },
  usedFonts: [],
  dataSources: {},
  customComponents: [],
}

export {
  DATE_FORMAT,
  AVAILABLE_TABS,
  AVAILABLE_SIZES,
  AVAILABLE_SIZE_NAMES,
  AVAILABLE_SIZES_LIST,
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGUAGES,
  AVAILABLE_EPACK_TYPES,
  AVAILABLE_EPACK_TYPES_LIST,
  DEFAULT_TEMPLATES,
  DEFAULT_EPACK_DATA,
}

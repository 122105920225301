/**
 * User: denisverstov
 * Date: 15/09/2020
 * Time: 22:20
 */

/**
 *
 * @param {array} array
 * @param {string} key
 * @param {array} arrayOfObject
 * @returns {[]}
 */

const getUniqArrayOfObjects = (arrayOfObject, array = [], key = "id") => {
  arrayOfObject.forEach(item => {
    let findExist = array.find(uniqItem => uniqItem[key] === item[key]);
    if (!findExist) {
      array.push(item);
    }
  });

  return array;
};

/**
 *
 * @param {object} object
 * @returns {object} {{}}
 */

const sortObject = object => {
  const obj = {};
  let keys = Object.keys(object);

  keys.sort().forEach(key => {
    obj[key] = object[key];
  });

  return obj;
};

/**
 *
 * @param {array} array
 * @param {string} sortKey
 * @returns {[]}
 */

const sortArrayOfObject = (array, sortKey = "name") => {
  const sortArray = [];
  let keys = [];

  array.forEach(obj => {
    keys.push(obj[sortKey]);
  });

  keys = sortAsc(keys);

  keys.forEach(key => {
    const findElm = array.find(info => info[sortKey] === key);
    sortArray.push(findElm);
  });

  return sortArray;
};

/**
 *
 * @param {array} items
 * @param {(string | object)} [field]
 * @returns {*}
 */

const sortAsc = (items, field) => {
  return items.slice().sort((a, b) => {
    a = a?.[field] || a;
    b = b?.[field] || b;
    return a.localeCompare(b);
  });
};

/**
 *
 * @param {array} items
 * @param {(string | object)} field
 * @returns {*}
 */

const sortDesc = (items, field) => {
  return items.slice().sort((a, b) => {
    a = a?.[field] || a;
    b = b?.[field] || b;
    return b.localeCompare(a);
  });
};

/**
 * Remove All dots from string
 * @param {string} string
 * @returns {*}
 */

const clearSymbols = string => {
  const splitString = string.split(".");
  string = splitString[0];
  if (splitString[1]) {
    string += splitString[1];
  }
  return string.toLowerCase();
};

export {
  sortObject,
  sortArrayOfObject,
  sortAsc,
  sortDesc,
  clearSymbols,
  getUniqArrayOfObjects,
};

<template>
  <div class="container">
    <span v-html="text"></span>
  </div>
</template>

<script>
  export default {
    name: "ToastifyErrorSave",
    props: {
      text: {
        type: String,
        default: "Error",
      },
    },
  };
</script>

<style lang="scss">
  .Vue-Toastification__toast--error {
    align-items: flex-start;
    background: #db4545;
    width: 310px;
    min-width: auto;
    min-height: auto;

    .va-button {
      color: #000000;
      border: 1px solid #ffffff;
      background: #ffffff !important;
      transition: 0.3s;
      display: block;
      margin-top: 40px;
      width: 100%;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
        background-color: transparent !important;
      }
    }
  }

  .Vue-Toastification__toast--success {
    align-items: flex-start;
    background: linear-gradient(to right, rgb(102, 229, 181), rgb(41, 224, 105));
    width: 310px;
    min-width: auto;
    min-height: auto;

    .va-button {
      color: #000000;
      border: 1px solid #ffffff;
      background: #ffffff !important;
      transition: 0.3s;
      display: block;
      margin-top: 40px;
      width: 100%;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
        background-color: transparent !important;
      }
    }
  }

  .Vue-Toastification__toast--info {
    align-items: flex-start;
    background: linear-gradient(35.34deg, rgb(76, 150, 197) 0%, rgb(154, 106, 162) 50.52%, rgb(216, 72, 133) 100%);
    width: 310px;
    min-width: auto;
    min-height: auto;

    .va-button {
      color: #000000;
      border: 1px solid #ffffff;
      background: #ffffff !important;
      transition: 0.3s;
      display: block;
      margin-top: 40px;
      width: 100%;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
        background-color: transparent !important;
      }
    }
  }
</style>

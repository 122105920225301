import colors from '../configs/colors';
import colorsWaves from '../configs/colorsWaves';

export class HelperColor {

  /**
   *
   * @param {boolean} useHEX
   * @param {(number | string)} opacity
   * @param {boolean} colorsLimited
   */

  constructor(useHEX = true, opacity = 1, colorsLimited = false) {
    this.selected = [];
    this.keys = Object.keys(colors);
    this.keysLength = this.keys.length;
    this.colorsLimited = colorsLimited;
    this.opacity = opacity;
    this.useHEX = useHEX;
  }

  /**
   *
   * @returns {*}
   */

  getRandomDarkColor() {
    const lum = -0.1;
    let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let rgb = "#";
    let color;
    let index;
    for (index = 0; index < 3; index++) {
      color = parseInt(hex.substr(index * 2, 2), 16);
      color = Math.round(Math.min(Math.max(0, color + (color * lum)), 255)).toString(16);
      rgb += ("00" + color).substr(color.length);
    }
    return this.checkRandomColor(rgb);
  }

  /**
   *
   * @param {string} hex
   * @returns {string}
   */

  hexToRGBA(hex) {
    let color;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      color = hex.substring(1).split('');
      if (color.length === 3) {
        color = [color[0], color[0], color[1], color[1], color[2], color[2]];
      }
      color = '0x' + color.join('');
      return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',')}, ${this.opacity})`;
    }
    throw new Error('Bad Hex');
  }

  /**
   *
   * @param {string} color
   * @returns {*}
   */

  checkRandomColor(color) {
    if (this.selected.includes(color)) {
      color = this.getRandomDarkColor();
    }
    return color;
  };

  /**
   *
   * @returns {*}
   */

  getColor() {
    let color = null;
    if (this.colorsLimited) {
      color = this.getRandomDarkColor();
    } else {
      const colorKey = this.keys[this.selected.length];
      color = colors[colorKey];
      this.selected.push(colorKey);
      this.colorsLimited = this.keysLength === this.selected.length;
    }

    if (!this.useHEX) {
      color = this.hexToRGBA(color);
    }

    return color;
  };

}

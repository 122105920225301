<template>
  <div class="screen-warning">
    <div class="screen-warning-close" @click="$emit('disable')"/>
    <div class="screen-warning-header">
      Warning!
    </div>
    <div class="screen-warning-text">
      You may have some trouble with your Report because of your browser
    </div>
    <div class="screen-warning-text">
      Please use Google Chrome in order to create correct visual version of your Report
    </div>
    <div class="screen-warning-text-download">
      <a
        href="https://www.google.ru/intl/ru/chrome/?brand=IXYC&gclid=CjwKCAiA3L6PBhBvEiwAINlJ9B6q4fDm9T-XgbUxYNbbRjp95j4nFB5CjlU74q_fwG-7jNwYiYgtCxoC-3UQAvD_BwE&gclsrc=aw.ds"
        target="blank">
        Download</a>
      the last version of Google Chrome browser
    </div>
    <div class="screen-warning-button" @click="$emit('continueCreating')">
      Continue anyway
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningScreen"
}
</script>

<style lang="scss">
.screen-warning {
  margin: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: start;

  &-header {
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-top: 16px;

    &-download {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #727e85;
      margin-top: 16px;

      a {
        text-decoration: none;
        color: #4598db;
      }
    }
  }

  &-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 30px;
    background: #000000;
    border-radius: 6px;
    margin-top: 32px;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #1e1e1e !important;
    }
  }

  &-close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -19px;
    right: -19px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before {
        background-color: black;
      }

      &::after {
        background-color: black;
      }
    }
  }
}
</style>

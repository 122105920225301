<template>
  <div class="content-box">
    <div class="content-box-wrapper">
      <ReportContent
        :is-brands="isBrands"
        :is-browsers="isBrowsers"
        :is-devices="isDevices"
        :is-info-tiles="isInfoTiles"
        :is-map="isMap"
        :is-o-s="isOS"
        :is-per-time="isPerTime"
        :is-pie-chart="isPieChart"
        :is-platforms="isPlatforms"
        :is-retailers="isRetailers"
        :is-stamp="isStamp"
        :stamp-type="stampType"
        :is-stats="isStats"
        :is-title="isTitle"
        :show-values-in-labels="showValuesInLabels"
      />
    </div>
  </div>
</template>

<script>
import ReportContent from "@/components/report/ReportContent";
import html2pdf from "html2pdf.js";

export default {
  name: "ContentBox",
  components: {ReportContent},
  props: {

    isTitle: {
      type: Boolean,
      default: false
    },
    isStamp: {
      type: Boolean,
      default: false
    },
    isPerTime: {
      type: Boolean,
      default: false
    },
    isPieChart: {
      type: Boolean,
      default: false
    },
    isInfoTiles: {
      type: Boolean,
      default: false
    },
    isStats: {
      type: Boolean,
      default: false
    },
    isBrowsers: {
      type: Boolean,
      default: false
    },
    isOS: {
      type: Boolean,
      default: false
    },
    isPlatforms: {
      type: Boolean,
      default: false
    },
    isDevices: {
      type: Boolean,
      default: false
    },
    isRetailers: {
      type: Boolean,
      default: false
    },
    isBrands: {
      type: Boolean,
      default: false
    },
    isMap: {
      type: Boolean,
      default: false
    },
    stampType: {
      type: String,
      default: 'TTL'
    },
    showValuesInLabels: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.$root.$once('downloadPdfReport', () => this.downloadPdfReport())

  },

  methods: {
    async downloadPdfReport() {
      const chartCanvases = this.$el.querySelectorAll('.export')
      chartCanvases.forEach(canvasParent => {
        const CHART_CANVAS = canvasParent.children[0];
        const CANVAS_PARENT = canvasParent;
        const CANVAS_DATA_URL = CHART_CANVAS.toDataURL();
        let printable_image = document.createElement('IMG');
        // if (CANVAS_PARENT.className === 'doughnut-small export browser') {
        //   CANVAS_PARENT.style.paddingTop = '100px'
        // }
        //Create image and add canvas data URL to image as source

        printable_image.src = CANVAS_DATA_URL;
        printable_image.style.width = '75%';
        printable_image.style.height = 'auto';

        //Hide the canvas chart
        CHART_CANVAS.style.display = 'none !important';
        CANVAS_PARENT.append(printable_image);
      })
      const WinPrint = window.open('', '', 'width=920');
      WinPrint.document.write('<html><head>');
      WinPrint.document.write(document.querySelector('head').innerHTML);
      WinPrint.document.write('<style>' +
        'html{margin: 0 auto;}' +
        '.export{margin-left: auto;margin-right: auto;display: block;position:relative;}' +
        '.export > *{margin-left: auto;margin-right: auto;display: block;position:relative;}' +
        '#report-content > .flex{display: block;position:relative;page-break-after: always}' +
        '.browser{padding-top: 450px}' +
        '#report-content{position: relative;display:block;}' +
        '*{page-break-inside: avoid;page-break-before: avoid; page-break-after: avoid; max-width: 750px;}' +
      '</style>')
      WinPrint.document.write('</head>');
      WinPrint.document.write('</html>');
      WinPrint.document.close();
      const body = WinPrint.document.createElement('div')
      document.querySelector('#report-content-filters').remove()
      body.appendChild(document.querySelector('#report-content'))
      WinPrint.document.querySelector('html').appendChild(body)
      setTimeout(() => {}, 200);
      setTimeout(() => {
         WinPrint.focus();
         WinPrint.document.querySelector('body').remove()
          WinPrint.print();
        this.$emit('disable');
      }, 3000)
      WinPrint.onafterprint = WinPrint.close;
    }
  }
}
</script>

<style lang="scss">
.content-box {
  width: 584px;
  height: fit-content;
  margin: 20px 24px 0 24px;
  background-color: #ffffff;
  overflow-y: hidden;
}
</style>

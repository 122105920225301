export default {
  OzonTitle: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue: "Это пример заголовка",
      },
    ],
  },
  OzonDescription: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue: "Это пример текста, здесь букв больше, а размер их меньше",
      },
    ],
  },
  OzonDisclaimer: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue: "Это пример текста, здесь букв больше, а размер их меньше",
      },
    ],
  },
  OzonTitleWithDescription: {
    template: "ozon_template",
    props: [
      {
        name: "padding",
        nameText: "Padding around the text",
        type: "select",
        defaultValue: "type1",
        values: ["type1", "type2"],
      },
      {
        name: "space",
        nameText: "Space between title and description",
        type: "select",
        defaultValue: "m",
        values: ["s", "m"],
      },
      {
        name: "theme",
        nameText: "Theme",
        type: "select",
        defaultValue: "default",
        values: ["default", "primary", "secondary", "tertiary"],
      },
      {
        name: "titleTextAlign",
        nameText: "Title Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "select",
        defaultValue: "color1",
        values: ["color1", "color2", "color3", "color4"],
      },
      {
        name: "titleContent",
        nameText: "Title",
        type: "text",
        defaultValue: "Это пример заголовка.",
      },
      {
        name: "descriptionTextAlign",
        nameText: "Description Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "select",
        defaultValue: "color1",
        values: ["color1", "color2", "color3", "color4"],
      },
      {
        name: "descriptionContent",
        nameText: "Description",
        type: "text",
        defaultValue:
          "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
      },
    ],
  },
  OzonPicture: {
    template: "ozon_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1416x708.png",
        recommendedWidth: 1416,
      },
      {
        name: "srcMob",
        nameText: "Image Mobile",
        type: "file",
        defaultValue: "/placeholders/708x708.png",
        recommendedWidth: 708,
      },
      {
        name: "imgLink",
        nameText: "Image link(without https://ozon.ru/)",
        type: "text",
        defaultValue: "",
      },
    ],
  },
  OzonGrid: {
    template: "ozon_template",
    props: [
      {
        name: "direction",
        nameText: "Direction",
        type: "select",
        defaultValue: "left",
        values: ["left", "right"],
      },
      {
        name: "titleTextAlign",
        nameText: "Title Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleContent",
        nameText: "Title",
        type: "text",
        defaultValue: "Это пример заголовка.",
      },
      {
        name: "descriptionTextAlign",
        nameText: "Description Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionContent",
        nameText: "Description",
        type: "text",
        defaultValue:
          "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
      },
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/708x708.png",
        recommendedWidth: 708,
      },
      {
        name: "srcMob",
        nameText: "Image Mobile",
        type: "file",
        defaultValue: "/placeholders/640x640.png",
        recommendedWidth: 640,
      },
      {
        name: "imgLink",
        nameText: "Image link(without https://ozon.ru/)",
        type: "text",
        defaultValue: "",
      },
    ],
  },
  OzonIcons: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/50x50.png",
                recommendedWidth: 50,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  OzonCol2: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/700x700.png",
                recommendedWidth: 700,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/700x700.png",
                recommendedWidth: 700,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  OzonCol3: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  OzonCol4: {
    template: "ozon_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "srcMob",
                nameText: "Image Mobile",
                type: "file",
                defaultValue: "/placeholders/640x640.png",
                recommendedWidth: 640,
              },
              {
                name: "imgLink",
                nameText: "Image link(without https://ozon.ru/)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  OzonYoutube: {
    template: "ozon_template",
    props: [
      {
        name: "youtubeID",
        nameText: "Youtube ID",
        type: "text",
        defaultValue: "Mt81r2LuVzo",
      },
    ],
  },
  OzonVideo: {
    template: "ozon_template",
    props: [
      {
        name: "src",
        nameText: "Video",
        type: "video",
        defaultValue: "/placeholders/video.mp4",
      },
    ],
  },
  OzonSpecs: {
    template: "ozon_template",
    props: [
      // {
      //   name: "titleFontSize",
      //   nameText: "Title Font Size",
      //   type: "fontSize",
      //   defaultValue: "6",
      //   values: ["1","2","3","4","5","6","7"],
      // },
      // {
      //   name: "descriptionFontSize",
      //   nameText: "Description Font Size",
      //   type: "fontSize",
      //   defaultValue: "4",
      //   values: ["1","2","3","4","5","6","7"],
      // },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Spec",
            props: [
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
}
import {DEFAULT_FONTS} from "@/store/modules/constructor2/fonts/consts";
import {showToastError} from "@/services/Helpers/HelperToast";
import axios from "axios";

const state = {
  availableFonts: [
    ...DEFAULT_FONTS
  ],
}

const actions = {
  async loadFonts ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(
        `${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL}/api/constructor/fonts`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        })
        .then(res => {
          if (res.data.code === 200 && res.data.data?.length) {
            commit('set', {
              stateProp: 'availableFonts',
              value: [...state.availableFonts, ...res.data.data.map(f => ({
                fontFamily: f.family,
                fontWeight: f.weight,
                fontStyle: f.style,
                fonts: f.formats
              }))]
            })
          }
          resolve(res)
        })
        .catch(error => {
          showToastError(
            "Something wrong while loading fonts. <br/> Please try later",
            this._vm.$toast,
          );
          reject(error)
        })
    })
  }
}

const getters = {
  usedFonts (state) {
    return state.epackData?.usedFonts || []
  }
}

export default {
  state,
  actions,
  getters
}

import Vue from 'vue';
import Router from 'vue-router';
import AuthLayout from '../components/layouts/AuthLayout';
import AppLayout from '../components/layouts/AppLayout';
import store from '../store';

Vue.use(Router);

const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

const demoRoutes = [];

const router = new Router({
  mode: 'history',
  routes: [
    ...demoRoutes,
    {
      name: 'EmptyParentPage',
      path: '',
      component: EmptyParentComponent,
      children: [
        {
          name: 'constructor2-preview',
          path: 'panel/builder-pro/preview',
          meta: {
            globalPage: true,
            noAuthRequired: true
          },
          component: () => import('../containers/Constructor2Preview'),
        }
      ]
    },
    {
      path: '/auth',
      component: AuthLayout,
      meta: {
        globalPage: true
      },
      children: [
        {
          name: 'login',
          path: 'login',
          meta: {
            globalPage: true
          },
          component: () => import('../components/auth/login/Login.vue'),
        },
        {
          name: 'signup',
          path: 'signup',
          meta: {
            globalPage: true
          },
          component: () => import('../components/auth/signup/Signup.vue'),
        },
        {
          name: 'amazonAuth',
          path: 'amazon',
          meta: {
            globalPage: true
          },
          component: () => import('../components/auth/amazon/Login.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          meta: {
            globalPage: true
          },
          component: () => import('../components/auth/recover-password/RecoverPassword.vue'),
        },
        {
          path: '',
          meta: {
            globalPage: true
          },
          redirect: {name: 'login'},
        },
      ],
    },
    {
      name: 'AmazonRedirect',
      path: '/panel',
      component: AuthLayout,
      children: [
        {
          name: 'amazon',
          path: 'amazon',
          meta: {
            requiredPermmission: 'Connect_to_amazon'
          },
          component: () => import('../components/amazon/Amazon.vue'),
        },
      ]
    },
    {
      path: '/404',
      meta: {
        globalPage: true
      },
      component: EmptyParentComponent,
      children: [
        {
          name: 'not-found-advanced',
          path: 'not-found-advanced',
          meta: {
            globalPage: true
          },
          component: () => import('../components/pages/404-pages/VaPageNotFoundSearch.vue'),
        },
        {
          name: 'not-found-simple',
          path: 'not-found-simple',
          meta: {
            globalPage: true
          },
          component: () => import('../components/pages/404-pages/VaPageNotFoundSimple.vue'),
        },
        {
          name: 'not-found-custom',
          path: 'not-found-custom',
          meta: {
            globalPage: true
          },
          component: () => import('../components/pages/404-pages/VaPageNotFoundCustom.vue'),
        },
        {
          name: 'not-found-large-text',
          path: '/pages/not-found-large-text',
          meta: {
            globalPage: true
          },
          component: () => import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
        },
      ],
    },
    {
      name: 'Panel',
      path: '/panel',
      component: AppLayout,
      children: [
        {
          name: 'reports',
          path: 'reports',
          redirect: 'reports/products',
          component: () => import('../components/pages/ReportProducts.vue'),
          children: [
            {
              name: 'report-products',
              path: 'products',
              component: () => import('../components/pages/ReportProducts.vue'),
              meta: {
                globalPage: true
              },
            },
            {
              name: 'report-traffic',
              path: 'traffic',
              component: () => import('../components/pages/ReportProducts.vue'),
            },
            {
              name: 'report-categories',
              path: 'categories',
              component: () => import('../components/pages/ReportProducts.vue'),
            },
          ],
        },
        {
          name: 'users',
          path: 'users',
          component: () => import('../components/users/Users.vue'),
        },
        {
          name: 'usersAdd',
          path: 'users/add',
          component: () => import('../components/users/Add.vue'),
        },
        {
          name: 'edits',
          path: 'users/edit/:id',
          component: () => import('../components/users/Edit.vue'),
        },
        {
          name: 'groups',
          path: 'groups',
          component: () => import('../components/groups/Groups.vue'),
        },
        {
          name: 'groupsAdd',
          path: 'groups/add',
          component: () => import('../components/groups/Add.vue'),
        },
        {
          name: 'groupsEdits',
          path: 'groups/edit/:id',
          component: () => import('../components/groups/Edit.vue'),
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          meta: {
            globalPage: true
          },
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          name: 'insights',
          path: 'insights',
          meta: {
            globalPage: true
          },
          component: () => import('../components/insights/Insights.vue'),
          default: true,
        },
        {
          name: 'trends',
          path: 'trends',
          meta: {
            requiredPermmission: 'Trends'
          },
          component: () => import('../components/trends/Trends.vue'),
        },
        {
          name: 'profile',
          path: 'profile',
          meta: {
            globalPage: true
          },
          component: () => import('../components/profile/Profile.vue'),
          default: true,
        },
        {
          name: 'content-manager',
          path: 'content-manager',
          component: () => import('../components/content-manager/ContentManager'),
          default: true,
        },
        {
          name: 'retail-manager',
          path: 'retail-manager',
          component: () => import('../components/retail-manager/RetailManager'),
          default: true,
        },
        {
          name: 'statistics-template',
          path: 'statistics/',
          component: EmptyParentComponent,
          children: [
            {
              name: 'charts',
              path: 'charts',
              component: () => import('../components/statistics-template/charts/Charts.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Charts',
              },
            },
            {
              name: 'progress-bars',
              path: 'progress-bars',
              component: () => import('../components/statistics-template/progress-bars/ProgressBars.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Progress-Bars',
              },
            },
          ],
        },
        {
          name: 'statistics',
          path: 'statistics',
          component: EmptyParentComponent,
          children: [
            {
              name: 'live-monitor',
              path: 'live-monitor',
              meta: {
                requiredPermmission: 'Live_monitor'
              },
              component: () => import('../components/statistics/LiveMonitor.vue'),
            },
            {
              name: 'impressions',
              path: 'impressions',
              component: () => import('../components/statistics/Impressions.vue'),
            },
            {
              name: 'products',
              path: 'products',
              component: () => import('../components/statistics/Products.vue'),
            },
          ],
        },
        {
          name: 'forms',
          path: 'forms',
          component: EmptyParentComponent,
          children: [
            {
              name: 'form-elements',
              path: 'form-elements',
              component: () => import('../components/forms/form-elements/FormElements.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/inputs',
              },
            },
            {
              name: 'medium-editor',
              path: 'medium-editor',
              component: () => import('../components/forms/medium-editor/MediumEditor.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Medium-Editor',
              },
            },
          ],
        },
        {
          name: 'tables',
          path: 'tables',
          component: EmptyParentComponent,
          children: [
            {
              name: 'markup',
              path: 'markup',
              component: () => import('../components/markup-tables/MarkupTables.vue'),
              wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables', // TODO Update docs
            },
            {
              name: 'data',
              path: 'data',
              component: () => import('../components/data-tables/DataTables.vue'),
              wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables', // TODO Add docs
            },
          ],
        },
        {
          name: 'ui',
          path: 'ui',
          component: EmptyParentComponent,
          children: [
            {
              name: 'typography',
              path: 'typography',
              component: () => import('../components/ui/typography/Typography.vue'),
            },
            {
              name: 'buttons',
              path: 'buttons',
              component: () => import('../components/ui/buttons/Buttons'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Buttons',
              },
            },
            {
              name: 'rating',
              path: 'rating',
              component: () => import('../components/ui/rating/Rating'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Rating',
              },
            },
            {
              name: 'color-pickers',
              path: 'color-pickers',
              component: () => import('../components/ui/color-pickers/ColorPickers'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Color-Pickers',
              },
            },
            {
              name: 'timelines',
              path: 'timelines',
              component: () => import('../components/ui/timelines/Timelines'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Timelines',
              },
            },
            {
              name: 'notifications',
              path: 'notifications',
              component: () => import('../components/ui/notifications/Notifications'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Notifications',
              },
            },
            {
              path: 'icons',
              component: () => import('../components/ui/icons/Icons'),
              children: [
                {
                  name: 'icon-sets',
                  path: '', // Default route
                  component: () => import('../components/ui/icons/SetsList'),
                  meta: {
                    wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Icons',
                  },
                },
                {
                  name: 'icon-set',
                  path: ':name',
                  component: () => import('../components/ui/icons/IconSet'),
                  props: true,
                  meta: {
                    wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Icons',
                  },
                },
              ],
            },
            {
              name: 'spinners',
              path: 'spinners',
              component: () => import('../components/ui/spinners/Spinners'),
            },
            {
              name: 'grid',
              path: 'grid',
              component: () => import('../components/ui/grid/Grid'),
            },
            {
              name: 'modals',
              path: 'modals',
              component: () => import('../components/ui/modals/Modals'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Modals',
              },
            },
            {
              name: 'cards',
              path: 'cards',
              component: () => import('../components/ui/cards/Cards'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Cards',
              },
            },
            {
              name: 'file-upload',
              path: 'file-upload',
              component: () => import('../components/ui/file-upload/FileUpload'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/File-Upload',
              },
            },
            {
              name: 'chips',
              path: 'chips',
              component: () => import('../components/ui/chips/Chips'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Chips',
              },
            },
            {
              name: 'tree-view',
              path: 'tree-view',
              component: () => import('../components/ui/tree-view/TreeView'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tree-view',
              },
            },
            {
              name: 'collapses',
              path: 'collapses',
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Collapse',
              },
              component: () => import('../components/ui/collapse/Collapses'),
            },
            {
              name: 'colors',
              path: 'colors',
              component: () => import('../components/ui/colors/Colors'),
            },
            {
              name: 'spacing',
              path: 'spacing',
              component: () => import('../components/ui/spacing/Spacing'),
            },
            {
              name: 'sliders',
              path: 'sliders',
              component: () => import('../components/ui/sliders/Sliders'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Sliders',
              },
            },
            {
              name: 'popovers',
              path: 'popovers',
              component: () => import('../components/ui/popovers/Popovers'),
            },
            {
              name: 'chat',
              path: 'chatPage',
              component: () => import('../components/ui/chat/ChatPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Chat',
              },
            },
            {
              name: 'tabs',
              path: 'tabs',
              component: () => import('../components/ui/tabs/Tabs'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tabs',
              },
            },
            {
              name: 'lists',
              path: 'lists',
              component: () => import('../components/lists/Lists.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Lists',
              },
            },
          ],
        },
        {
          name: 'maps',
          path: 'maps',
          component: EmptyParentComponent,
          children: [
            {
              name: 'leaflet-maps',
              path: 'leaflet-maps',
              component: () => import('../components/maps/leaflet-maps/LeafletMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'bubble-maps',
              path: 'bubble-maps',
              component: () => import('../components/maps/bubble-maps/BubbleMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'line-maps',
              path: 'line-maps',
              component: () => import('../components/maps/line-maps/LineMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
          ],
        },
        {
          name: 'pages',
          path: 'pages',
          component: EmptyParentComponent,
          children: [
            {
              name: '404-pages',
              path: '404-pages',
              component: () => import('../components/pages/404-pages/404PagesPage'),
            },
          ],
        },
        {
          name: 'epacks-list',
          path: 'builder',
          meta: {
            requiredPermmission: 'Builder',
          },
          component: () => import('../components/constructor/EpacksList.vue'),
        },
        {
          name: 'epack-constructor',
          path: 'builder/:id',
          meta: {
            requiredPermmission: 'Builder',
          },
          component: () => import('../components/constructor/Constructor.vue'),
        },
        {
          name: 'epacks-list-2',
          path: 'builder-pro',
          meta: {
            requiredPermmission: 'BuilderPro',
          },
          component: () => import('../containers/Constructor2Page'),
        },
        {
          name: 'epack-constructor2',
          path: 'builder-pro/:id',
          meta: {
            requiredPermmission: 'BuilderPro',
          },
          component: () => import('../containers/Constructor'),
        },
      ],
    },
    {
      path: '/*',
      component: () => import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('ttlUserInfo'));
  if (to.path !== '/auth/amazon' && !to.meta?.noAuthRequired) {
    const isNotAuth = to.fullPath !== '/auth/login' && to.fullPath !== '/auth/login/?status=expired' && to.fullPath !== '/auth/login/?status=success' && to.fullPath !== '/auth/recover-password';
    const isAuth = to.fullPath === '/auth/login' || to.fullPath === '/auth/recover-password' || to.fullPath === '/' || to.fullPath === '/auth/login/?status=expired' || to.fullPath === '/auth/login/?status=success';

    if (!userInfo && isNotAuth) {
      store.dispatch('refreshToken').then(() => {
        store.dispatch('getProfile').then(() => {
          next()
        }).catch(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('constructorAccessToken');
          localStorage.removeItem('ttlUserInfo');
          localStorage.removeItem('refresh-token');
          next('/auth/login');
        })
      }).catch(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('constructorAccessToken');
        localStorage.removeItem('ttlUserInfo');
        localStorage.removeItem('refresh-token');
        next('/auth/login');
      })
      return;
    }

    store.commit('setLoading', true);
    store.dispatch('fetchAccessToken');

    if (to.fullPath.includes('users') &&
      userInfo.roles &&
      !userInfo.roles.includes('ROLE_ADMIN')) {
      return next('/panel/dashboard');
    }

    if (userInfo && isAuth) {
      return next('/panel/dashboard');
    }
  }
  return next();
});

router.afterEach((to, from) => {
  store.commit('setLoading', false);

  let appLayout = document.querySelector(".app-layout__main-layout") || window;
  appLayout.scrollTo(0, 0);

  try {
    ym('72693058', 'hit', to.fullPath);
  } catch (error) {
    console.error(error.message);
  }
});

export default router;

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4142 6C17.5233 6 17.0771 7.07714 17.7071 7.70711L18.29 8.29L14.1171 12.4629C13.7266 12.8534 13.0934 12.8534 12.7029 12.4629L10.1176 9.87758C9.72687 9.48687 9.09334 9.48709 8.70289 9.87806L2.70429 15.8848C2.31524 16.2743 2.31546 16.9055 2.70476 17.2948C3.09425 17.6843 3.72575 17.6843 4.11524 17.2948L8.70289 12.7071C9.09342 12.3166 9.72658 12.3166 10.1171 12.7071L12.7035 15.2935C13.0938 15.6838 13.7265 15.684 14.1171 15.294L19.71 9.71L20.2929 10.2929C20.9229 10.9229 22 10.4767 22 9.58579V7C22 6.44772 21.5523 6 21 6H18.4142Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "IconTrends"
}
</script>

<style scoped>

</style>

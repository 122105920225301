import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import dcopy from "deep-copy";
import moment from "moment";

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const updateHistory = (state) => {
  if (state.historyIndex < state.history.length - 1)
    state.history = state.history.slice(0, state.historyIndex + 1)
  else if (state.lastHistoryUpdate
    && moment().subtract(state.updateHistoryAfter[0], state.updateHistoryAfter[1])
        .isBefore(moment(state.lastHistoryUpdate, DATE_FORMAT)))
    state.history = state.history.slice(0, state.historyIndex)
  else
    state.lastHistoryUpdate = moment().format(DATE_FORMAT)
  state.history.push(dcopy({
    epackData: state.epackData,
    activeComponent: state.activeComponent,
    activeLocale: state.activeLocale,
    activePage: state.activePage,
    activeTemplate: state.activeTemplate,
    activeTab: state.activeTab,
    activeSize: state.activeSize,
  }))
  if (state.history.length > state.maxHistoryCapacity) {
    state.history = state.history.slice(1, state.history.length + 1)
  }
  state.historyIndex = state.history.length - 1
}

const onManualHistoryStep = (state) => {
  const epack = state.history[state.historyIndex]
  state.epackData = {...epack.epackData}
  state.activeComponent = epack.activeComponent
  state.activeLocale = epack.activeLocale
  state.activePage = epack.activePage
  state.activeTemplate = epack.activeTemplate
  state.activeTab = epack.activeTab
  state.activeSize = epack.activeSize
  onEpackDataUpdate(state, true)
}

const state = {
  updateHistoryAfter: [10, 'seconds'], // moment (after last change)
  maxHistoryCapacity: 10, // steps
  lastHistoryUpdate: null,
  historyIndex: -1,
  history: []
}

const mutations = {
  resetHistory(state) {
    state.history = []
    updateHistory(state)
    state.lastHistoryUpdate = null
  },

  undoHistory(state) {
    if (state.historyIndex > 0) {
      state.historyIndex--
      onManualHistoryStep(state)
    }
  },

  redoHistory(state) {
    if (state.historyIndex < state.history.length - 1) {
      state.historyIndex++
      onManualHistoryStep(state)
    }
  }
}

const getters = {
  historyRedoAvailable(state) {
    return state.historyIndex < state.history.length - 1
  },

  historyUndoAvailable(state) {
    return state.historyIndex > 0
  }
}

export default {
  state,
  mutations,
  getters,
}

export {
  updateHistory
}

/**
 * User: denisverstov
 * Date: 02/08/2020
 * Time: 04:45
 */

import API from './API';
import {
  calcLastVisited,
  getPeriodFromDates,
  refactoringByTimeZone,
  refactoringDate
} from "../../services/Helpers/HelperTime";
import {HelperColor} from '../../services/Helpers/HelperColor';
import parseReportProductAggregations from "../../services/API/helpers/parseReportProductAggregations";
import getEpackInterval from "../../services/API/helpers/getEpackInterval";
import store from '../../store/index'
import da from "element-ui/src/locale/lang/da.js";

const UAParser = require('ua-parser-js');

/**
 * Return random HSL color
 * @returns {string}
 */

const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 *
 * @param {string} epackageID
 * @param {object} config
 * @returns {*}
 */

const setUpEpackageID = (epackageID, config) => {
  config.query.bool.filter.push({
    "match_phrase": {
      "epackage_uid": {
        "query": epackageID,
      },
    },
  });

  return config;
};

/**
 * Create elastic core config
 * @param {Object} [gte=null] – Start of time range
 * @param {Object} [lte=null] - End of time range
 * @param {string} [contentType="minisite"] – Type of content
 * @param {number} [status=200] – Response status
 * @param {array} [licenseMatches=null]
 * @param {array} [retailerMatches=null]
 * @param {array} [categoriesMatches=null]
 * @param {boolean} [existUser=true]
 * @param {array} [filter=[]]
 * @param {boolean} [useMustNot=true]
 * @return {{size: number, _source: {excludes: Array}, stored_fields: string[], script_fields: {}, docvalue_fields: *[], query: {bool: {must: Array, filter: Array, should: Array, must_not: *[]}}}}
 */

const getElasticConfig = (gte = null, lte = null, contentType = "minisite", status = 200, licenseMatches = null, categoriesMatches = null, retailerMatches = null, existUser = false, filter = [], useMustNot = true, mustNotEpack = false) => {


  if (filter && typeof filter.length !== "number") return

  let must_not = [];

  if (gte && typeof gte === "object") {
    gte = gte.time_local.gte;
  }

  if (lte && typeof lte === "object") {
    lte = lte.time_local.lte;
  }

  filter.push({
    "match_all": {},
  });

  if (existUser) {
    filter.push({
      exists: {
        field: 'user_id',
      },
    });
  }

  if (status) {
    filter.push({
      match_phrase: {
        status: {
          query: status,
        },
      },
    });
  }

  if (contentType) {
    filter.push({
      match_phrase: {
        content_type: {
          query: contentType,
        },
      },
    });
  }

  if (licenseMatches) {
    filter.push({
      "bool": {
        "should": licenseMatches,
        "minimum_should_match": 1,
      },
    });
  }

  if (retailerMatches) {
    filter.push({
      "bool": {
        "should": retailerMatches,
        "minimum_should_match": 1,
      },
    });
  }

  if (categoriesMatches) {
    filter.push({
      "bool": {
        "should": categoriesMatches,
        "minimum_should_match": 1,
      },
    });
  }

  if (gte && lte) {
    filter.push({
      range: {
        time_local: {
          format: 'strict_date_optional_time',
          gte: refactoringDate(gte, 'gte'),
          lte: refactoringDate(lte, 'lte'),
        },
      },
    });
  }

  if (useMustNot) {
    if (mustNotEpack) {
      must_not.push({
        match_phrase: {
          epackage_uid: {
            query: "not_found",
          }
        },
      });
    }
    must_not.push({
      match_phrase: {
        retailer_uid: {
          query: "not_found",
        }
      },
    })
  }

  return {
    size: 0,
    _source: {
      excludes: [],
    },
    stored_fields: ["*"],
    script_fields: {},
    docvalue_fields: [
      {
        field: "epackage_created",
        format: "date_time",
      },
      {
        field: "epackage_updated",
        format: "date_time",
      },
      {
        field: "time_local",
        format: "date_time",
      },
    ],
    query: {
      bool: {
        must: [],
        filter,
        should: [],
        must_not,
      },
    },
    highlight: {
      pre_tags: [
        '@kibana-highlighted-field@',
      ],
      post_tags: [
        '@/kibana-highlighted-field@',
      ],
      fields: {
        '*': {},
      },
      fragment_size: 2147483647,
    },
  };
};

/**
 * Return object with Elastic search matches license_uid or retailer_uid
 * @param {array} data
 * @param {string} type
 * @returns {Array}
 */

const getMatches = (data, type = "license_uid") => {
  const matches = [];

  data.forEach(uid => {
    const obj = {
      "match_phrase": {
        [type]: uid,
      },
    };

    matches.push(obj);
  });

  return matches;
};

/**
 * Return Elastic matches of licenses and retailers
 * @param {object} filters
 * @returns {{licenseMatches: Array, retailerMatches: Array}}
 */

const getFilterMatched = ({licenses, retailers, categories = []}) => {
  const licenseMatches = getMatches(licenses, "license_uid");
  const retailerMatches = getMatches(retailers, "retailer_uid");
  const categoriesMatches = getMatches(categories, "category_list");

  return {licenseMatches, categoriesMatches, retailerMatches};
};

/**
 * @param {object} filters
 * @returns {Promise<any>}
 */

const getEpackagesCount = ({licenses, retailers, categories, contentType}) => {
  const userIsAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN');
  let urlLink = "/statistics/get-license-sku/";
  let promises = [];
  Array.from(licenses).forEach(value => {
    promises.push(new Promise((resolve, reject) => API.APIPost(`${process.env.VUE_APP_API_URL}${urlLink}${value}`, {}, value => {
      resolve(Object.keys(value)?.filter(epack => {
        if (!value[epack]?.categories?.length && [...categories].length !== 0)
          return userIsAdmin;
        else if (value[epack]?.categories?.filter(category => [...categories]?.find(cat => cat === category)).length > 0 || [...categories].length === 0) {
          return Object.keys(value[epack].retailers)?.filter(retailer => {
            return retailers?.find(id => id === value?.[epack]?.retailers[retailer]?.id);
          }).length > 0
        } else {
          return false
        }
      }).length);
    }, error => reject(error))))
  });

  return new Promise((resolve, reject) => Promise.allSettled(promises).then((results) => {
    if (results.filter(status => status.status === 'rejected').length !== 0)
      reject(results)
    let count = 0
    results.forEach((result) => {
      count += result.value;
    });
    resolve(count)
  }));
};

/**
 * @param {object} filters
 * @returns {Promise<any>}
 */

const getProductsCount = (filters) => {
  const {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched(filters);
  const {licenses, retailers, categories, range, contentType} = filters;
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        retailers,
        licenses,
        body: {
          "aggs": {
            "2": {
              "terms": {
                "field": "epackage_uid",
                "order": {
                  "_count": "desc",
                },
                "size": 15000,
              },
            },
          },
          ...config,
        },
      }, stat => {
        let count = 0
        if (stat?.aggregations?.[2])
          count = stat.aggregations[2].buckets.reduce(sum => sum + 1, 0);
        resolve(count);
      },
      error => reject(error));
  });
};

/**
 * @param filters
 * @returns {Promise<any>}
 */

const getUsersCount = (filters, epackageID) => {
  const {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched(filters);
  const {licenses, retailers, categories, contentType, range} = filters;
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
      retailers,
      licenses,
      body: {
        aggs: {
          2: {
            cardinality: {
              field: "user_id",
            },
          },
        },
        ...config,
      },
    }, stat => {
      resolve(stat.aggregations[2].value);
    }, error => reject(error));
  });
};


/**
 * @param filters
 * @returns {Promise<any>}
 */

const getTrendsUsersCount = (filters, epackageID) => {
  const {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched(filters);
  const {licenses, retailers, categories, contentType, range} = filters;
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, null, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search-for-trends`, {
      retailers,
      body: {
        aggs: {
          2: {
            terms: {
              field: "license_uid",
              order: {
                _count: "desc",
              },
              size: 10000,
            },
            aggs: {
              3: {
                value_count: {
                  field: "user_id"
                }
              }
            }
          },
        },
        ...config,
      },
    }, stat => {
      resolve(stat);
    }, error => reject(error));
  });
};

/**
 *
 * @param {object} filters
 * @returns {Promise<any>}
 */

const getRetailersStats = ({licenses, retailers, categories, contentType, range}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);
  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'retailer_uid',
                order: {
                  _count: 'desc',
                },
                size: 20,
              },
            },
          },
          ...config,
        },
      }, stat => {
        const datasets = [];
        const labels = [];

        const helperColor = new HelperColor();

        if (stat?.aggregations?.[2])
          stat.aggregations?.[2]?.buckets?.forEach(({key, doc_count}) => {

            const retailer = store.state?.retailers?.filter(retailer => retailer.id === key)?.[0].name;

            const obj = {
              label: retailer,
              data: [doc_count],
              backgroundColor: helperColor.getColor(),
              borderColor: 'transparent',
            };

            labels.push(key);
            datasets.push(obj);
          });

        resolve(datasets);
      },
      error => reject(error));
  });
};

const getTrendsRetailersStats = ({licenses, retailers, categories, contentType, range}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, null, categoriesMatches, retailerMatches);
  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search-for-trends`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: "license_uid",
                order: {
                  _count: "desc"
                },
                size: 10000
              },
              aggs: {
                3: {
                  terms: {
                    field: "retailer_uid",
                    order: {
                      _count: "desc"
                    },
                    size: 10000
                  }
                }
              }
            }
          },
          ...config,
        },
      }, stat => {


        resolve(stat);
      },
      error => reject(error));
  });
};

const getTrendsCategoriesStats = ({licenses, retailers, categories, contentType, range}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});
  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, null, categoriesMatches, retailerMatches);
  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search-for-trends`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: "license_uid",
                order: {
                  _count: "desc"
                },
                size: 10000
              },
              aggs: {
                3: {
                  terms: {
                    field: "category_list",
                    order: {
                      _count: "desc"
                    },
                    size: 10000
                  }
                }
              }
            }
          },
          ...config,
        },
      }, stat => {
        resolve(stat);
      },
      error => reject(error));
  });
};

/**
 *
 * @param {object} filters
 * @param {object} BubbleMapData
 * @returns {Promise<any>}
 */

const getMapStats = ({licenses, retailers, categories, contentType, range}, BubbleMapData, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        retailers,
        licenses,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'country',
                order: {
                  _count: 'desc',
                },
                size: 10000,
              },
            },
          },
          ...config,
        },
      }, stat => {
        const array = [];

        if (stat?.aggregations?.[2])
          stat.aggregations[2].buckets.forEach(({key, doc_count}) => {
            let name = '';
            let color = '';

            BubbleMapData.data.forEach(place => {
              if (place.code === key) {
                name = place.name;
                color = place.color;
              }
            });

            const obj = {
              code: key,
              value: doc_count,
              name,
              color,
            };

            array.push(obj);
          });

        resolve(array);
      },
      error => reject(error));
  });
};

/**
 *
 * @param {array} licenses
 * @param {array} retailers
 * @param {string} contentType
 * @param {object} range
 * @returns {Promise<unknown>}
 */

const getTimeStats = ({licenses, retailers, categories, contentType, range}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  const gte = range.time_local.gte;
  const lte = range.time_local.lte;

  const period = getPeriodFromDates(gte, lte);

  let config = getElasticConfig(gte, lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              date_histogram: {
                field: 'time_local',
                calendar_interval: period,
                time_zone: 'Europe/Moscow',
                min_doc_count: 1,
              },
              aggs: {
                3: {
                  terms: {
                    field: 'retailer_uid',
                    order: {
                      _count: 'desc',
                    },
                    size: 10000,
                  },
                },
              },
            },
          },
          ...config,
        },
      }, stat => {
        let datasets = [];
        const labels = [];
        const collectData = [];

        if (stat?.aggregations?.[2])
          stat.aggregations[2].buckets.forEach(data => {
            const retailers = data[3].buckets.map(value => {
              value.key = store?.state?.retailers?.filter(retailer => retailer.id === value.key)[0].name;
              return value
            });
            const dateObj = retailers.length ? refactoringByTimeZone(data.key_as_string, null) : null;

            let formats = {
              "1h": "HH:mm",
              "1Y": "YYYY",
              "1M": "MM-YYYY",
            };

            let date = dateObj.format(formats?.[period] || "DD-MM-YYYY");

            labels.push(date);

            const obj = {date, retailers};

            collectData.push(obj);
          });

        const helperColor = new HelperColor(false, "0.38");

        collectData.forEach((info, index) => {

          const {date, retailers} = info;
          retailers.forEach(({key, doc_count}) => {

            const retailerIndex = datasets?.findIndex(obj => obj.label === key);
            const labelIndex = labels?.findIndex(label => label === date);

            if (retailerIndex !== -1) {
              datasets[retailerIndex].data[index] = doc_count;
            } else {
              const obj = {
                label: key,
                data: [],
                fill: true,
                lineTension: 0.57,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
              };

              for (let i = 0; i < labels.length; i++) {
                obj.data.push(0);
              }

              if (labelIndex !== -1) {
                obj.data[labelIndex] = doc_count;
              }

              datasets.push(obj);
            }

          });
        });
        resolve({datasets, labels});
      },
      error => reject(error));
  });
};

/**
 *
 * @param {array} licenses
 * @param {array} retailers
 * @param {string} contentType
 * @param {object} range
 * @returns {Promise<unknown>}
 */

const getTrendsTimeStats = ({licenses, retailers, categories, contentType, range}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  const gte = range.time_local.gte;
  const lte = range.time_local.lte;

  const period = getPeriodFromDates(gte, lte);

  let config = getElasticConfig(gte, lte, contentType, 200, null, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search-for-trends`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              date_histogram: {
                field: 'time_local',
                calendar_interval: period,
                time_zone: 'Europe/Moscow',
                min_doc_count: 1,
              },
              aggs: {
                3: {
                  terms: {
                    field: 'license_uid',
                    order: {
                      _count: 'desc',
                    },
                    size: 10000,
                  },
                },
              },
            },
          },
          ...config,
        },
      }, stat => {


        // let datasets = [];
        // const labels = [];
        // const collectData = [];
        //
        // if (stat?.aggregations?.[2])
        //   stat.aggregations[2].buckets.forEach(data => {
        //     const retailers = data[3].buckets.map(value => {
        //       value.key = store?.state?.retailers?.filter(retailer => retailer.id === value.key)[0].name;
        //       return value
        //     });
        //     const dateObj = retailers.length ? refactoringByTimeZone(data.key_as_string, null) : null;
        //
        //     let formats = {
        //       "1h": "HH:mm",
        //       "1Y": "YYYY",
        //       "1M": "MM-YYYY",
        //     };
        //
        //     let date = dateObj.format(formats?.[period] || "DD-MM-YYYY");
        //
        //     labels.push(date);
        //
        //     const obj = {date, retailers};
        //
        //     collectData.push(obj);
        //   });
        //
        // const helperColor = new HelperColor(false, "0.38");
        //
        // collectData.forEach((info, index) => {
        //
        //   const {date, retailers} = info;
        //   retailers.forEach(({key, doc_count}) => {
        //
        //     const retailerIndex = datasets?.findIndex(obj => obj.label === key);
        //     const labelIndex = labels?.findIndex(label => label === date);
        //
        //     if (retailerIndex !== -1) {
        //       datasets[retailerIndex].data[index] = doc_count;
        //     } else {
        //       const obj = {
        //         label: key,
        //         data: [],
        //         fill: true,
        //         lineTension: 0.57,
        //         backgroundColor: helperColor.getColor(),
        //         borderColor: 'transparent',
        //       };
        //
        //       for (let i = 0; i < labels.length; i++) {
        //         obj.data.push(0);
        //       }
        //
        //       if (labelIndex !== -1) {
        //         obj.data[labelIndex] = doc_count;
        //       }
        //
        //       datasets.push(obj);
        //     }
        //
        //   });
        // });
        //
        // resolve({datasets, labels});
        resolve(stat)
      },
      error => reject(error));
  });
};


/**
 *
 * @param {object} filters
 * @returns {Promise<any>}
 */

const getLicenseStats = ({licenses, retailers, categories, range, contentType}, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        retailers,
        licenses,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'license_uid',
                order: {
                  _count: 'desc',
                },
                size: 20,
              },
            },
          },
          ...config,
        },
      }, stat => {
        const datasets = [];
        const labels = [];

        const helperColor = new HelperColor();

        if (stat?.aggregations?.[2])
          stat.aggregations[2].buckets.forEach(({key, doc_count}) => {

            const licenseName = store.state.licenses?.filter(license => license.id === key)[0].name;

            const obj = {
              label: licenseName,
              data: [doc_count],
              backgroundColor: helperColor.getColor(),
              borderColor: 'transparent',
            };

            labels.push(key);
            datasets.push(obj);
          });

        resolve(datasets);
      },
      error => reject(error));
  });
};

const getPieStats = ({licenses, retailers, categories, range, contentType}, epackageID) => {

  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'retailer_uid',
                order: {
                  _count: 'desc',
                },
                size: 20,
              },
            },
          },
          ...config,
        },
      }, (stat) => {
        const datasets = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];
        const labels = [];

        const helperColor = new HelperColor();

        if (stat?.aggregations?.[2])
          stat.aggregations[2].buckets.forEach(data => {
            const retailer = store.state.retailers?.find(retailer => retailer.id === data.key)?.name;
            if (retailer === undefined)
              return;
            labels.push(retailer);
            datasets[0].backgroundColor.push(helperColor.getColor());
            datasets[0].data.push(data.doc_count);
          });

        resolve({datasets, labels});
      },
      error => reject(error));
  });
};

const getPieTrendsStats = ({licenses, retailers, categories, range, contentType}, epackageID) => {

  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, null, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search-for-trends`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'license_uid',
                order: {
                  _count: 'desc',
                },
                size: 20,
              },
            },
          },
          ...config,
        },
      }, (stat) => {
        resolve(stat)
      },
      error => reject(error));
  });
};

const getPieCategories = async ({licenses, retailers, categories, range, contentType}, epackageID) => {

  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }
  let licenseCategories = await getLicensesNames(licenses);
  licenseCategories = licenseCategories?.filter(category => typeof categories?.find(id => id === category.id) === "number")

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          aggs: {
            2: {
              terms: {
                field: 'category_list',
                order: {
                  _count: 'desc',
                },
                size: 20,
              },
            },
          },
          ...config,
        },
      }, (stat) => {
        const datasets = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];
        const labels = [];

        const helperColor = new HelperColor();
        if (stat?.aggregations?.[2])
          stat.aggregations[2].buckets.forEach(data => {
            const categoryName = licenseCategories?.find(cat => +cat.id === +data.key)?.name
            if (categoryName === undefined)
              return
            labels.push(categoryName);
            datasets[0].backgroundColor.push(helperColor.getColor());
            datasets[0].data.push(data.doc_count);
          });

        resolve({datasets, labels});
      },
      error => reject(error));
  });
};

function getLicensesNames(licenseKeys) {
  return new Promise((resolve, reject) => {
    API.APIGet(`${process.env.VUE_APP_API_URL}/categories?criteria[licenseIds]=${licenseKeys.join(',')}`, {}, (data) => {
      resolve(data);
    }, error => reject(error));
  })
}

const getPopularProductsStat = ({licenses, retailers, categories, range, contentType}, status = 200, epackageID) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, status, licenseMatches, categoriesMatches, retailerMatches, false, [], true, true);
  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
      licenses,
      retailers,
      body: {
        aggs: {
          2: {
            terms: {
              field: 'epackage_uid',
              order: {
                _count: 'desc',
              },
              size: 30,
            },
          },
        },
        ...config,
      },
    }, stat => {
      const datasets = [];
      let resultDatasets = []
      let progressMax = 0;
      let promises = [];
      licenses.forEach(license => {
        promises.push(new Promise((resolve, reject) => {
          API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/get-license-sku/${license}`, {}, data => resolve(data), error => reject(error));
        }));
      });

      const helperColor = new HelperColor();
      if (stat?.aggregations?.[2]) {
        stat.aggregations[2].buckets.forEach(data => {
          progressMax = data.doc_count > progressMax ? data.doc_count : progressMax;
          data.color = helperColor.getColor();
          datasets.push(data);
        });

        Promise.allSettled(promises).then(results => {
          if (results.filter(status => status.status === 'rejected').length !== 0)
            throw results
          results.forEach((result, index) => {
            datasets.forEach((elem, index) => {
              Object.keys(result.value).forEach(epack => {

                if (result.value[epack].id === elem.key && result.value[epack].name) {
                  datasets[index].key = result.value[epack].name;
                  datasets[index].mpn = result.value[epack].mpn;
                  datasets[index].ean = result.value[epack].ean;
                }
              })
            })
          });
          let i = 0
          datasets.forEach(ds => {
            if (ds.mpn && i < 15) {
              resultDatasets.push(ds)
              i++
            }
          })
          resolve({datasets: resultDatasets, progressMax});
        });
      } else {
        resolve({datasets: [], progressMax: 0});
      }

    }, error => reject(error));
  });
};

const getDataStats = ({licenses, retailers, categories, range, contentType}) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          ...config,
          size: 100,
          version: true,
          sort: [
            {
              time_local: {
                order: 'desc',
                unmapped_type: 'boolean',
              },
            },
          ],
        },
      }, ({hits}) => {
        let datasets = [];

        hits.hits.forEach(({_source}) => {
          let {retailer_name, license_name, product_name, ean, mpn, referrer, country, time_local} = _source;
          referrer = referrer.split('?');
          const obj = {
            retailerName: retailer_name,
            licenseName: license_name,
            productName: product_name,
            ean: ean,
            mpn: mpn,
            referrer: referrer[0],
            country: country,
            time: refactoringDate(time_local),
          };

          datasets.push(obj);
        });

        resolve(datasets);
      },
      error => reject(error));
  });
};

const getDevicesStats = ({licenses, retailers, categories, range, contentType}, epackageID) => {

  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          ...config,
          aggs: {
            2: {
              date_histogram: {
                field: 'time_local',
                calendar_interval: '1h',
                time_zone: 'Europe/Moscow',
                min_doc_count: 1,
              },
            },
          },
          version: true,
          size: 1000,
          sort: [
            {
              time_local: {
                order: 'desc',
                unmapped_type: 'boolean',
              },
            },
          ],
        },
      }, (stat) => {
        const browsers = {};
        const devices = {};
        const statOS = {};
        const platforms = {};

        stat.hits.hits.forEach(data => {
          const desktopOS = ["Windows", "Linux", "Mac OS"];
          const useragent = new UAParser(data._source.useragent);
          let browser = useragent.getBrowser().name;

          function capitalizeFirstLetter(string) {
            if (string === "undefined")
              return undefined;
            return string.charAt(0).toUpperCase() + string.slice(1);
          }

          let os = capitalizeFirstLetter(String(useragent.getOS().name));
          let device = useragent.getDevice().type;
          let platform = useragent.getDevice().vendor;

          if (desktopOS.includes(os)) {
            device = "Desktop";
          }

          if (browser) {
            browsers[browser] = browsers[browser] ? browsers[browser] + 1 : 1;
          }
          if (os) {
            statOS[os] = statOS[os] ? statOS[os] + 1 : 1;
          }
          if (device) {
            device = device.charAt(0).toUpperCase() + device.slice(1);
            devices[device] = devices[device] ? devices[device] + 1 : 1;
          }
          if (platform) {
            platforms[platform] = platforms[platform] ? platforms[platform] + 1 : 1;
          }
        });

        const browsersLabels = Object.keys(browsers).sort((prev, next) => {
          return browsers[prev] > browsers[next] ? -1 : 1;
        }).slice(0, 5);

        const OSLabels = Object.keys(statOS).sort((prev, next) => {
          return statOS[prev] > statOS[next] ? -1 : 1;
        }).slice(0, 5);

        const devicesLabel = Object.keys(devices).sort((prev, next) => {
          return devices[prev] > devices[next] ? -1 : 1;
        }).slice(0, 5);

        const platformsLabel = Object.keys(platforms).sort((prev, next) => {
          return platforms[prev] > platforms[next] ? -1 : 1;
        }).slice(0, 5);

        const browsersData = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];

        const devicesData = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];

        const OSData = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];

        const platformsData = [{
          label: '',
          backgroundColor: [],
          data: [],
        }];

        const helperColor = new HelperColor();

        browsersLabels.map(label => {
          browsersData[0].backgroundColor.push(helperColor.getColor());
          browsersData[0].data.push(browsers[label]);
        });

        devicesLabel.map(label => {
          devicesData[0].backgroundColor.push(helperColor.getColor());
          devicesData[0].data.push(devices[label]);
        });

        OSLabels.map(label => {
          OSData[0].backgroundColor.push(helperColor.getColor());
          OSData[0].data.push(statOS[label]);
        });

        platformsLabel.map(label => {
          platformsData[0].backgroundColor.push(helperColor.getColor());
          platformsData[0].data.push(platforms[label]);
        });

        resolve({
          browser: {
            labels: browsersLabels,
            datasets: browsersData,
          },
          os: {
            labels: OSLabels,
            datasets: OSData,
          },
          platforms: {
            labels: platformsLabel,
            datasets: platformsData,
          },
          device: {
            labels: devicesLabel,
            datasets: devicesData,
          },
        });
      },
      error => reject(error));
  });
};

const getIPMap = ({licenses, retailers, categories, range, contentType}, epackageID) => {

  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, 200, licenseMatches, categoriesMatches, retailerMatches);

  if (epackageID) {
    config = setUpEpackageID(epackageID, config);
  }

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          ...config,
          aggs: {
            2: {
              date_histogram: {
                field: 'time_local',
                calendar_interval: '1h',
                time_zone: 'Europe/Moscow',
                min_doc_count: 1,
              },
            },
          },
          version: true,
          size: 1000,
          sort: [
            {
              time_local: {
                order: 'desc',
                unmapped_type: 'boolean',
              },
            },
          ],
        },
      }, (stat) => {
        const latlong = {};
        const datasets = [];

        stat.hits.hits.forEach(data => {
          const {country, city, location} = data._source;

          if (!location) {
            return false;
          }

          if (!latlong?.[city]) {
            const locate = location.split(',');
            latlong[city] = {
              latitude: locate[0],
              longitude: locate[1],
            };
          }

          const exist = datasets?.find(info => info.code === city);

          if (exist) {
            datasets.forEach(info => {
              if (info.code === city) {
                info.value++;
              }
            });
          } else {
            const obj = {
              code: city,
              country: country,
              name: city,
              value: 1,
              color: getRandomColor(),
            };
            datasets.push(obj);
          }
        });

        resolve({datasets, latlong});
      },
      error => reject(error));
  });
};

const getErrorFromInsight = (product) => {
  let status = "Unknown Product";
  if (product.includes('/manifest')) {
    product = product.split("/manifest")[0];
    product = product.split("/");
    product = product[product.length - 1];
  } else {
    product = product.split('/')[4]
  }

  let hasError = product.includes("%") || product.includes(".");
  if (hasError) {
    let newProducts = "";
    let products = product.split("%20");
    products.forEach(product => {
      newProducts += product + "<br/>";
    });
    product = newProducts;
    status = "Broken";
  } else if (!product) {
    product = "Empty";
    status = "Empty";
  }

  return {hasError, product, status};
};

const getInsights = (filters) => {
  const {licenseMatches, retailerMatches} = getFilterMatched(filters);
  let {licenses, retailers, range} = filters;

  let elasticFilters = [
    {
      "match_phrase": {
        "product_name": {
          "query": "not_found",
        },
      },
    },
  ];

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, "", 204, licenseMatches, null, retailerMatches, false, elasticFilters, false);

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        body: {
          ...config,
          "version": true,
          "size": 10000,
          "sort": [
            {
              "time_local": {
                "order": "desc",
                "unmapped_type": "boolean",
              },
            },
            {
              "status": {
                "order": "desc",
                "unmapped_type": "boolean",
              },
            },
            {
              "epackage_uid": {
                "order": "desc",
                "unmapped_type": "boolean",
              },
            },
            {
              "session_id": {
                "order": "asc",
                "unmapped_type": "boolean",
              },
            },
          ],
          "_source": {
            "excludes": [],
          },
          "aggs": {
            "2": {
              "date_histogram": {
                "field": "time_local",
                "fixed_interval": "1h",
                "time_zone": "Europe/Moscow",
                "min_doc_count": 1,
              },
            },
          },
        },
      }, (stat) => {
        const array = [];
        stat.hits.hits.forEach(info => {
          info = info["_source"];
          const obj = {...info};
          obj.epackage_created = refactoringDate(obj.epackage_created);
          obj.epackage_updated = refactoringDate(obj.epackage_updated);
          obj.time_local = refactoringDate(obj.time_local);
          obj.referrer = obj.referrer.split("?")[0];

          const {hasError, product, status} = getErrorFromInsight(obj.request);
          obj.product = decodeURI(product) + "";
          obj.hasError = hasError;
          obj.status = status;
          obj.product_name = obj.product_name === "not_found" ? "Empty" : obj.product_name;

          if (obj.status === 'Unknown Product')
            array.push(obj);
        });
        resolve(array);
      },
      error => {
        reject(error)
      });
  });

};

const getLiveMonitor = (licenseID, licenseName) => {
  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/get-license-sku/${licenseID}`, {}, data => {
      resolve({
          data,
          licenseName,
        })
      },
      error => reject(error));
  });
};

/**
 *
 * @param {array} selectedLicenses
 * @param {array} selectedRetailers
 * @returns {Promise<any>}
 */

const getDataLiveMonitor = (selectedLicenses, selectedRetailers) => {
  const promises = [];
  let checks = {
    total: 0,
    checked: 0,
  };
  let retailersConnected = [];

  return new Promise((resolve, reject) => {
    (async () => {
    for (let i = 0; i < selectedLicenses.length; i++) {
        const promise = getLiveMonitor(selectedLicenses[i].id, selectedLicenses[i].name);
        promises.push(promise);
        promise.then(({data, licenseName}) => {
          if (typeof data !== "object") {
            return false;
          }

          Object.keys(data).forEach(epackageID => {
            const {retailers, product_name, mpn, ean} = data[epackageID];
            selectedRetailers.forEach((retailer, index) => {
              const retailerData = retailers?.[retailer.id];

              if (!retailerData) {
                return false;
              }

              let {check, status} = retailerData;

              checks = getCheckedStats(checks, check, status);
              retailersConnected = getConnectedRetailers(retailersConnected, retailer.id, retailerData, selectedRetailers);

            });

          });

        }).catch(error => reject(error));
        // break;
    }
      await Promise.all(promises).then(() => {
        checks = {
          datasets: checks,
          labels: selectedRetailers,
        };
        if (retailersConnected.length > 0) {
          resolve({checks, retailersConnected});
        } else {
          resolve(0)
        }
      });
    })()
  });
};

const epackagesTraversal = (epackages, licenseName, selectedRetailers = [], returnData) => {

  let {checks, retailersConnected, monitorLabels} = returnData;

  Object.keys(epackages).forEach(epackageID => {
    let epackage = epackages[epackageID];
    const {retailers} = epackages[epackageID];

    let monitorField = generateMonitorField(epackage, epackageID, licenseName);

    selectedRetailers.forEach((retailer, index) => {
      retailer = retailer.toLowerCase();
      const retailerData = retailers?.[retailer];

      if (!retailerData) {
        return false;
      }

      let {check} = retailerData;

      checks = getCheckedStats(checks, check, index);
      retailersConnected = getConnectedRetailers(retailersConnected, retailer, retailerData, selectedRetailers);

    });

    return {checks, retailersConnected};
  });
};

const generateMonitorField = (epackage, epackageID, licenseName) => {
  const {product_name, mpn, ean} = epackage;
  return {
    epackID: epackageID,
    licenseName,
    mpn,
    ean,
    productName: product_name,
    skus: [],
    sku: "",
    status: [],
    statusStock: [],
  };
};

/**
 *
 * @param {array} retailersConnected
 * @param {string} retailerName
 * @param {object} retailerData
 * @param {array} retailers
 * @returns {*}
 */

const getConnectedRetailers = (retailersConnected, retailerName, retailerData, retailers) => {
  let isNotVisited = true;

  retailers = retailers.map(retailer => retailer.id.toLowerCase());

  if (typeof retailerData === "object") {
    let {last_visited, state, belongsOzonGroup} = retailerData;
    let {status} = calcLastVisited(last_visited, state, belongsOzonGroup);

    if (status === "no-visits" || status === "no-map") {
      isNotVisited = false;
    }
  } else {
    isNotVisited = false;
  }

  let retailerPassed = typeof retailerData === "object" && retailers.includes(retailerName) && !retailersConnected.includes(retailerName) && isNotVisited;

  if (retailerPassed) {
    retailersConnected.push(retailerName);
  }

  return retailersConnected;
};

/**
 * Method collect Chart data for in stock
 * and not in stock status of each retailer
 * @param {array} array
 * @param {string} stock
 * @param {string} status
 * @param {number} retailerIndex
 * @returns {*}
 */

const getStockStats = (array, stock, status, retailerIndex) => {
  if (status === "no-map") {
    return array;
  }

  if (status === "success") {
    stock = "in-stock";
  }

  let colorDefault = stock === "in-stock" ? "#45db54" : "#db4545";
  stock = stock === "in-stock" ? "In stock" : "Not in stock";
  return updateChartObject(array, stock, retailerIndex, colorDefault);
};

/**
 * Method collect Chart data for check
 * and not check status of each retailer
 * @param {object} object
 * @param {number} object.checked
 * @param {number} object.total
 * @param {string} object.value
 * @param {boolean} check
 * @param {string} status
 * @returns {*}
 */

const getCheckedStats = (object, check, status) => {
  if (status === "no-map") {
    return object;
  }

  if (!object.total) {
    object = {
      total: 0,
      checked: 0,
      value: "0/0",
      text: "PDPs Checked",
      className: "white-color",
      rowClass: "xl12",
      textColor: "#000000",
    };
  }

  if (check) {
    object.checked++;
  }

  object.total++;
  object.value = `${object.checked}/${object.total}`;

  return object;
};

const getStaticCheckedStats = ({quantityCheckedEpackageRetailers, totalEpackageRetailers}) => ({
  total: totalEpackageRetailers,
  checked: quantityCheckedEpackageRetailers,
  value: `${quantityCheckedEpackageRetailers}/${totalEpackageRetailers}`,
  text: "PDPs Checked",
  className: "white-color",
  rowClass: "xl12",
  textColor: "#000000",
});

/**
 * Method collect Chart data for in Checked
 * and Not checked average of each licenses
 * @param {array} array
 * @param {boolean} check
 * @param {string} status
 * @param {number} licenseIndex
 * @returns {*}
 */

const getCheckedAverage = (array, check, status, licenseIndex) => {
  if (status === "no-map") {
    return array;
  }
  return updateChartAverage(array, check, licenseIndex);
};

/**
 * Method collect chart Data for Average ChartJS
 * @param {array} array
 * @param {boolean} check
 * @param {number} licenseIndex
 * @return {*}
 */

const updateChartAverage = (array, check, licenseIndex) => {
  let HelperColorInst = new HelperColor;
  let checkAsNumber = Number(check);
  let color = HelperColorInst.getRandomDarkColor();

  if (array.length) {
    let chartData = array[0];
    let retailerData = chartData?.total?.[licenseIndex];
    if (retailerData) {
      chartData.checked[licenseIndex] = chartData.checked[licenseIndex] + checkAsNumber;
      chartData.notChecked[licenseIndex] = chartData.notChecked[licenseIndex] + checkAsNumber;
      chartData.total[licenseIndex] = chartData.total[licenseIndex] + 1;
      chartData.data[licenseIndex] = Number(+chartData.checked[licenseIndex] / +chartData.total[licenseIndex]).toFixed(3);
    } else {
      chartData.checked.push(checkAsNumber);
      chartData.notChecked.push(checkAsNumber);
      chartData.total.push(1);
      chartData.data.push(checkAsNumber);
      chartData.backgroundColor.push(color);
    }
  } else {
    array.push({
      data: [checkAsNumber],
      total: [1],
      checked: [checkAsNumber],
      notChecked: [checkAsNumber],
      backgroundColor: [color],
      borderColor: 'transparent',
    });
  }

  return array;
};

/**
 * Try to find in array Chart data
 * push new data or add to exists
 * @param {array} array
 * @param {string} label
 * @param {number} retailerIndex
 * @param {string} color
 * @returns {*}
 */

const updateChartObject = (array, label, retailerIndex, color) => {
  let existDataIndex = array?.findIndex(obj => obj.label === label);
  let HelperColorInst = new HelperColor;

  color = color || HelperColorInst.getColor();

  if (existDataIndex !== -1) {
    let chartData = array[existDataIndex];
    let retailerData = chartData?.data?.[retailerIndex];
    if (retailerData) {
      chartData.data[retailerIndex] = chartData.data[retailerIndex] + 1;
    } else {
      chartData.data.push(1);
    }
  } else {
    array.push({
      label,
      key: label,
      data: [1],
      backgroundColor: color,
      borderColor: 'transparent',
    });
  }

  return array;
};

const getReportProducts = ({licenses, retailers, categories, range, contentType, interval}) => {
  let {licenseMatches, categoriesMatches, retailerMatches} = getFilterMatched({licenses, retailers, categories});

  let config = getElasticConfig(range.time_local.gte, range.time_local.lte, contentType, "200", licenseMatches, categoriesMatches, retailerMatches, false, [], false);

  // delete config.highlight
  delete config.must_not

  const intervalEpack = getEpackInterval(interval)

  return new Promise((resolve, reject) => {
    API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/search`, {
        licenses,
        retailers,
        categories,
        body: {
          ...config,
          size: 0,
          "aggs": {
            "2": {
              "date_histogram": {
                "field": "time_local",
                "calendar_interval": intervalEpack,
                "time_zone": "Europe/Moscow",
                "min_doc_count": 1
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "retailer_uid",
                    "order": {
                      "_count": "desc"
                    },
                    "size": 40
                  },
                  "aggs": {
                    "4": {
                      "terms": {
                        "field": "license_uid",
                        "order": {
                          "_count": "desc"
                        },
                        "size": 40
                      },
                      "aggs": {
                        "5": {
                          "terms": {
                            "field": "epackage_uid",
                            "order": {
                              "_count": "desc"
                            },
                            "size": 500
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
      }, async ({aggregations}) => {
        let epacks = {},
          callIndex = 0;
        for (let i = 0; i < licenses.length; i++) {
          API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/get-license-sku/${licenses[i]}`, {}, res => {
            if (res) {
              Object.values(res).forEach(e => {
                epacks[e.id.toString()] = e
              })
            }

            if (callIndex++ === licenses.length - 1) {
              if (aggregations?.[2]?.["buckets"]?.[0]?.[3]?.["buckets"]) {
                for (let j = 0; j < Object.keys(aggregations[2]["buckets"]).length; j++)
                  aggregations[2]["buckets"][j][3]["buckets"] = aggregations[2]["buckets"][j][3]["buckets"].map(retialers => {
                    retialers[4]["buckets"] = retialers[4]["buckets"].map(licenses => {
                      licenses.key = store.state.licenses?.filter(license => license.id === licenses.key)?.[0].name
                      return licenses
                    })
                    retialers.key = store.state.retailers?.filter(retailer => retailer.id === retialers.key)?.[0].name
                    return retialers;
                  })
              }

              const {rawData, tableData} = parseReportProductAggregations({aggregations, interval, epacks, range})
              resolve({rawData, tableData});
            }
          })
        }
      },
      error => reject(error));
  });
};

export default {
  getPieCategories,
  getRetailersStats,
  getTrendsRetailersStats,
  getTrendsCategoriesStats,
  getMapStats,
  getTimeStats,
  getTrendsTimeStats,
  getLicenseStats,
  getPieStats,
  getPieTrendsStats,
  getPopularProductsStat,
  getDataStats,
  getDevicesStats,
  getIPMap,
  getLiveMonitor,
  getEpackagesCount,
  getProductsCount,
  getUsersCount,
  getTrendsUsersCount,
  getInsights,
  getConnectedRetailers,
  getDataLiveMonitor,
  getReportProducts
};

export {
  getCheckedStats,
  getStaticCheckedStats,
  getStockStats,
  getCheckedAverage,
  getElasticConfig
};

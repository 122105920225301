/**
 * User: denisverstov
 * Date: 04/08/2020
 * Time: 11:50
 */

export default {
  one: '#A245DB',
  two: '#45C9DB',
  // three: '#DB4545',
  // four: '#5145DB',
  // five: '#ABDB45',
  // six: '#45DBB7',
  // seven: '#45DB54',
  // eight: '#F3972B',
  // nine: '#F4DF22',
  // ten: '#B90606',
  // eleven: '#CC00FF',
  // twelve: '#5A3084',
  // thirteen: '#3702A9',
  // fourteen: '#7A98E7',
};

import store from '../../../store'

const otherNavigationRoutes = {
  liveMonitor: {
    name: 'live-monitor',
    displayName: 'Live monitor',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-statistics',
    },
  },
  users: {
    name: 'users',
    displayName: 'menu.users',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-user',
    },
  },
  groups: {
    name: 'groups',
    displayName: 'User groups',
    meta: {
      iconClass: 'fa fa-group',
    },
  },
  constructor: {
    name: 'epacks-list',
    displayName: 'Builder',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-components',
    },
  },
  constructor2: {
    name: 'epacks-list-2',
    displayName: 'Builder Pro',
    meta: {
      iconClass: 'fa fa-cube',
    },
  },
  trends: {
    name: 'trends',
    displayName: 'Trends',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-trends',
    },
  },
  contentManager: {
    name: 'content-manager',
    displayName: 'Content Manager',
    meta: {
      iconClass: 'fa fa-group',
    },
  },
  retailManager: {
    name: 'retail-manager',
    displayName: 'Retail Manager',
    meta: {
      iconClass: 'fa fa-group',
    },
  },
  // reports: {
  //   name: 'reports',
  //   displayName: 'Reports',
  //   meta: {
  //     iconClass: 'fa fa-clipboard',
  //   },
  //   children: [
  //     {
  //       name: 'report-traffic',
  //       displayName: 'Traffic',
  //       meta: {
  //         iconClass: 'fa fa-group',
  //       },
  //     },
  //     {
  //       name: 'report-categories',
  //       displayName: 'Categories',
  //       meta: {
  //         iconClass: 'fa fa-group',
  //       },
  //     },
  //   ],
  // },
};

const defaultNavigationRoutes = [
  {
    name: 'dashboard',
    displayName: 'menu.dashboard',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
    },
  },
  {
    name: 'insights',
    displayName: 'Insights',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-files',
    },
  },
  {
    name: 'reports',
    displayName: 'Reports',
    meta: {
      iconClass: 'fa fa-clipboard',
    },
    children: [
      {
        name: 'report-products',
        displayName: 'Products',
        meta: {
          iconClass: 'fa fa-group',
        },
      }
    ]
  },
];

const currentUserPermissionsArray = () => {
  return store.state.currentUserProfilePermissions?.map(p => p.name)
}

const getNavigationRoutes = () => {
  if (store.state.currentUserProfile) {
    if (store.state.currentUserProfile.roles.includes('ROLE_ADMIN')) {
      const routes = [...defaultNavigationRoutes]
      Object.values(otherNavigationRoutes).forEach(v => {
        const routeIndex = routes.findIndex(r => r.name === v.name)
        if (routeIndex === -1)
          routes.push(v)
        else {
          v.children?.forEach(c => {
            if (routes[routeIndex].children?.findIndex(rc => rc.name === c.name) === -1)
              routes[routeIndex].children.push(c)
          })
        }
      })
      return routes
    } else if (store.state.currentUserProfilePermissions) {
      const routes = defaultNavigationRoutes.slice()
      const permissions = {}
      store.state.currentUserProfilePermissions.forEach(p => permissions[p.name] = p.id)
      if (permissions.Builder) {
        routes.push(otherNavigationRoutes.constructor)
      }
      if (permissions.BuilderPro) {
        routes.push(otherNavigationRoutes.constructor2)
      }
      if (permissions.Trends) {
        routes.splice(1, 0, otherNavigationRoutes.trends)
      }
      if (permissions.Live_monitor) {
        routes.splice(1, 0, otherNavigationRoutes.liveMonitor)
      }
      return routes
    }
  }
  return []
};

export {
  getNavigationRoutes,
  currentUserPermissionsArray
};

<template>
  <li
    class="app-sidebar-link"
    :class="computedLinkClass"
  >
    <router-link
      class="app-sidebar-link__item"
      @mouseenter.native="updateHoverState(true)"
      @mouseleave.native="updateHoverState(false)"
      :style="computedLinkStyles"
      :to="to"
      :target="target"
    >
      <VnSideBarIcon
        v-if="icon"
        class="app-sidebar-link__item-icon"
        :style="computedIconStyles"
        :name="icon"
      />
      <div class="app-sidebar-link__item-title" v-if="title">
        <slot>
          {{title}}
        </slot>
      </div>

      <va-icon
        v-if="iconRight && iconRight !== 'fa fa-angle-up' && iconRight !== 'fa fa-angle-down'"
        class="app-sidebar-link__item-icon-right"
        :style="computedIconStyles"
        :name="iconRight"
      >{{iconRightContent}}
      </va-icon>
      <IconAngleUp
        v-else-if="iconRight"
        class="app-sidebar-link__item-icon-right app-sidebar-link__item-icon-right-toggle"
        :style="computedIconStyles"
        :class="{'app-sidebar-link__item-icon-right-toggle__opened': expanded}"
      />
    </router-link>
  </li>
</template>

<script>
import { ColorThemeMixin } from '../../../../services/vuestic-ui';
import IconAngleUp from "../../../ui/icons/IconAngleUp.vue";
import VnSideBarIcon from "../../../ui/icons/VnSideBarIcon.vue";

export default {
  name: 'app-sidebar-link',
  inject: ['contextConfig'],

  components: {
    IconAngleUp,
    VnSideBarIcon
  },
  mixins: [ColorThemeMixin],
  props: {
    to: {
      type: [Object, String],
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    icon: {
      type: [String, Array],
    },
    iconRight: {
      type: [String, Array],
    },
    iconRightContent: {
      type: String,
    },
    title: {
      type: String,
    },
    activeByDefault: {
      type: Boolean,
    },
    minimized: {
      type: Boolean,
    },
    expanded: {
      type: Boolean
    }
  },
  data () {
    return {
      isHovered: false,
      isActive: this.activeByDefault,
    };
  },
  watch: {
    $route () {
      this.updateActiveState();
    },
  },
  computed: {
    computedLinkClass () {
      return {
        'app-sidebar-link--minimized': this.minimized,
      };
    },
    computedLinkStyles () {
      const style = {};

      style.color = '#8d9ca8';
      style['--color'] = '#8d9ca8'

      if (this.isActive || this.isHovered) {
        style.background = '#f1f4f8';
        style.color = '#000000';
        style['--color'] = '#000000'
        style.borderRight = '2px solid #000000';
      }

      return style;
    },

    computedIconStyles () {
      return {
        color: this.isHovered || this.isActive ? '#000000' : '#8d9ca8',
      };
    },
  },

  methods: {

    updateHoverState (isHovered) {
      this.isHovered = isHovered;
    },

    updateActiveState () {
      let active = (this.$route.name === this.to.name) || this.activeByDefault;
      this.$set(this, 'isActive', active)
      // this.isActive =
    },

  },

  mounted () {
    this.updateActiveState();
  },

};
</script>

<style lang="scss">
  .app-sidebar-link {
    display: flex;

    &__item {
      position: relative;
      display: flex;
      min-height: 3rem;
      cursor: pointer;
      padding: 0.75rem 0.75rem 0.75rem 0.75rem; // 0.75 - border size as 0.25
      align-items: center;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.65);
      box-sizing: border-box;
      width: 20rem;
      transition: 0.25s;

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
      }

      .app-sidebar-link--minimized & {
        justify-content: center;
        padding-left: 0.5rem;
        width: 3.5rem;
      }

      &-icon {
        width: 1.5rem;
        text-align: center;
        font-size: $sidebar-menu-item-icon-size;
        transition: 0.25s;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          path {
            transition: fill 0.3s ease;
            fill: var(--color);
          }
        }
      }

      &-icon-right {
        display: block;
        font-weight: bold;
        position: absolute;
        right: 0.75rem;
        bottom: auto;
        &-toggle {
          transform: rotateX(180deg);
          svg {
            width: 10.3px;
          }
          &__opened {
            transform: rotateX(0);
          }
        }
        .app-sidebar-link--minimized & {
          bottom: 1.25rem;
          right: auto;
          height: 0;
          display: block;
          text-align: center;
          font-weight: normal;
        }
      }

      &-title {
        line-height: 1.5em;

        .app-sidebar-link__item-icon + & {
          margin-left: 0.5rem;
        }

        .app-sidebar-link--minimized & {
          display: none;
        }
      }
    }
  }
</style>

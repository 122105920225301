export default {
  ImgPicture: {
    template: "img_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
        recommendedWidth: 1200,
      },
    ],
  },
}
import {
  DEFAULT_EPACK_DATA,
  DATE_FORMAT,
  AVAILABLE_EPACK_TYPES, AVAILABLE_SIZES_LIST,
} from "@/store/modules/constructor2/consts";
import axios from "axios";
import moment from "moment";
import dcopy from "deep-copy";
// import {updateHistory} from "@/store/modules/constructor2/history";
import syncComponents from "@/store/modules/constructor2/components/utils/syncComponents";
import {
  addToUpdatedComponents,
  decorateComponents,
  SPECIAL_COMPONENTS_STRING
} from "@/store/modules/constructor2/components";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

const request = async (
  axiosRequest,
  resolve,
  reject,
) => (
  axiosRequest
    .then((res) => {
      resolve(res);
    })
    .catch((e) => {
      reject(e);
    })
);

const state = {
  activeEpackage: null,
  epackData: DEFAULT_EPACK_DATA,
  epackSavedInBackend: false,
}

const mutations = {
  updateEpackDataManifest(state, payload) {
    state.epackData = {
      ...state.epackData,
      manifest: {
        ...state.epackData.manifest,
        ...(payload.isProductData ? {
          productData: {
            ...state.epackData.manifest.productData,
            [payload.propName]: payload.value
          }
        } : {
          [payload.propName]: payload.value
        })
      }
    }
    onEpackDataUpdate(state)
  },

  changeEpackDataType(state, type) {
    if ([AVAILABLE_EPACK_TYPES.sis.key, AVAILABLE_EPACK_TYPES.minisite.key].includes(type)) {
      if (type === AVAILABLE_EPACK_TYPES.sis.key) {
        const { MPN, EAN, categories, ...productData } = state.epackData.manifest.productData
        state.epackData.manifest.productData = {
          ...productData,
          mappingId: Date.now().toString()
        }
      } else if (type === AVAILABLE_EPACK_TYPES.minisite.key) {
        const { mappingId, ...productData } = state.epackData.manifest.productData
        state.epackData.manifest.productData = {
          ...productData,
          EAN: '',
          MPN: '',
          categories: []
        }
      }
      state.epackData.type = type
      onEpackDataUpdate(state, true, false)
    }
  },

  updateSizes(state, newSizes) {
    state.epackData = {
      ...state.epackData,
      sizes: newSizes
    }
    onEpackDataUpdate(state, true, false)
  }
}

const actions = {
  /* *
    * Если есть id и он актуальный, ставит данные из бэка.
    * А если нет, то остаются default-ные данные.
   **/
  async getEpackage({state, commit, dispatch}, {id, setIntoCurrent = false}) {
    let epackBackend

    if (id) {
      await axios.get(`${BASE_URL}/api/constructor/epackages/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      })
        .then(res => {
          epackBackend = res.data.data
          commit('set', {stateProp: 'activeEpackage', value: id})
        })
        .catch(e => {
          console.error(e)
        })
    }

    if (epackBackend) {
      if (!setIntoCurrent) {
        return epackBackend
      } else {
        epackBackend = epackBackend.structure
      }
    }

    let isDefault = false
    let epackData
    if (epackBackend) {
      epackData = dcopy(epackBackend.epackData)
      commit('set', {stateProp: 'epackData', value: epackBackend.epackData})
      commit('set', {stateProp: 'epackSavedInBackend', value: true})
      commit('set', {stateProp: 'updatedComponents', value: []})
    } else {
      isDefault = true
      commit('set', {stateProp: 'epackSavedInBackend', value: false})
      commit('set', {stateProp: 'activeEpackage', value: null})
      const manifest = dcopy(DEFAULT_EPACK_DATA.manifest)
      try {
        manifest.author = JSON.parse(localStorage.getItem('ttlUserInfo') || {})?.username || ''
      } catch {
      }
      manifest.createdDate = moment().format(DATE_FORMAT)
      manifest.updatedDate = moment().format(DATE_FORMAT)
      epackData = dcopy({...DEFAULT_EPACK_DATA, manifest})
      commit('set', {stateProp: 'epackData', value: dcopy(epackData)})
      commit('set', {stateProp: 'updatedComponents', value: []})
    }

    // if the epackage is not default, traverse through all components and sync props
    if (!isDefault) {
      // custom-components
      syncComponents(epackData.customComponents)
      if (!epackData.type) epackData.type = AVAILABLE_EPACK_TYPES.sis.key
      if (epackData.components instanceof Array) epackData.components = { ru: { master_template: { index: [] } } }
      commit('set', {stateProp: 'epackData', value: dcopy(epackData)})
    }

    // show Epack Data tab if creating a new epackage, otherwise show the Constructor tab
    commit('set', {stateProp: 'activeTab', value: isDefault ? 'epackData' : 'constructor'})

    commit('set', {stateProp: 'activeSize', value: 'desktop'})
    commit('setActiveComponent', {component: null})
    commit('set', {stateProp: 'activeLocale', value: 'ru'})
    commit('set', {stateProp: 'activePage', value: 'index.html'})
    commit('set', {stateProp: 'activeTemplate', value: 'master_template'})
    commit('set', {stateProp: 'loadedComponents', value: []})
  },

  async getEpackages(_, {page, perPage, search, type}) {
    return new Promise((resolve, reject) => {
      request(axios.get(
        `${BASE_URL}/api/constructor/epackages?${
          search
            ? `criteria[search]=${search}&`
            : ''
        }${
          type
            ? `criteria[type]=${type}&`
            : ''
        }offset=${(page - 1) * perPage}&limit=${perPage}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  /* *
    * Создает/Редактирует Epackage в зависимости от наличия id
   **/
  async saveEpackage({commit, state, dispatch}, id) {
    return new Promise(async (resolve, reject) => {
      const {components, ...epackData} = state.epackData
      let epackId, error
      await axios[id ? 'put' : 'post'](
        `${BASE_URL}/api/constructor/epackages${id ? `/${id}` : ''}`,
        {
          structure: {
            activeEpackage: state.activeEpackage,
            epackData,
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      )
        .then((res) => {
          if (!id) {
            commit('set', {stateProp: 'activeEpackage', value: res.data?.data?.id || null})
            epackId = res.data?.data?.id || null
          } else {
            epackId = id
          }
        })
        .catch(e => {
          console.error(e)
          error = e
        })

      if (!error && epackId) {
        const updateComponentsPromises = []

        state.updatedComponents.forEach(updatedComponent => {
          let [locale, template, page] = updatedComponent.split(SPECIAL_COMPONENTS_STRING)
          let currentComponents

          if (!components[locale]) {
            template = null
            page = null
            currentComponents = null
          } else if (!components[locale][template]) {
            page = null
            currentComponents = null
          } else if (!components[locale][template][page]) {
            currentComponents = null
          } else {
            currentComponents = components[locale][template][page] ? dcopy(components[locale][template][page]) : null
            // removing empty size values from value
            if (currentComponents) {
              currentComponents.forEach(component => {
                if (component.props) {
                  Object.entries(component.props).forEach(([, prop]) => {
                    if (prop?.value) {
                      AVAILABLE_SIZES_LIST.forEach(({ slug: size }) => {
                        if (
                          typeof prop.value[size] !== 'boolean' &&
                          _.isEmpty(prop.value[size])
                        ) {
                          delete prop.value[size]
                        }
                      })
                    }
                    if (prop && _.isEmpty(prop.value)) {
                      delete prop.value
                    }
                  })
                }
              })
            }
          }

          updateComponentsPromises.push(
            axios
              .put(
                `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
                {
                  lang: locale,
                  template: template,
                  page: page,
                  components: currentComponents
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                  }
                }
              )
              .then(() => {
                updateComponentsPromises.push(dispatch('loadComponents', {
                  force: true,
                  epackId,
                  lang: locale,
                  template,
                  page
                }))
              })
              .catch(e => {
                error = e
                console.error(e)
              })
          )
        })

        await Promise.all(updateComponentsPromises)
          .catch(e => {
            error = e
            console.error(e)
          })
      }

      if (!error) {
        resolve(epackId)
        commit('resetUpdatedComponents')
        commit('set', {stateProp: 'epackSavedInBackend', value: true})
      } else {
        reject(error)
      }
    })
  },

  async deleteEpackage(_, id) {
    return new Promise((resolve, reject) => {
      request(axios.delete(
        `${BASE_URL}/api/constructor/epackages/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async downloadEpackage(_, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${BASE_URL}/api/constructor/epackages/${id}/download`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.style.display = 'none'
        link.href = url;
        link.setAttribute('download', `epack-${id}.zip`);
        document.body.appendChild(link);
        link.click();
        resolve(true)
      }).catch(e => {
        reject(e)
      })
    })
  },

  async publishEpackage(_, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${BASE_URL}/api/constructor/epackages/${id}/publish-to-stream`, undefined, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      }).then(() => {
        resolve(true)
      }).catch(e => {
        reject(e)
      })
    })
  },

  async transferOwnership (_, { epackId, userId }) {
    return new Promise((resolve, reject) => {
      request(axios.put(
        `${BASE_URL}/api/constructor/epackages/${epackId}/change-owner`, {
          userId
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async copyEpackage (_, epackId) {
    return new Promise((resolve, reject) => {
      request(axios.post(
        `${BASE_URL}/api/constructor/epackages/${epackId}/copy`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  }
}

export default {
  state,
  mutations,
  actions
}

const onEpackDataUpdate = (state, isHistoryChange, addToUpdated = true, epackSavedInBackend = undefined) => {
  if (!epackSavedInBackend) state.epackSavedInBackend = false
  state.epackData.manifest.updatedDate = moment().format(DATE_FORMAT)
  if (addToUpdated)
    addToUpdatedComponents(state, decorateComponents(state.activeLocale, state.activeTemplate, state.activePage))
  // isHistoryChange means whether this function was called from the history module (if so, then no need to updateHistory)
  // hence -> !isHistoryChange
  // todo
  // if (!isHistoryChange)
  //   updateHistory(state)
}

export {
  onEpackDataUpdate
}

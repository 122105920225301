<template>
  <div class="app-navbar-actions">

    <va-button type="submit" class="my-0 btn--caps btn--small" large>
      <router-link :to="{name: 'profile'}">
        {{ $t('My profile') }}
      </router-link>
    </va-button>

    <div class="btn--logout" @click="logout">
      <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V18C14 19.1046 13.1046 20 12 20H2C0.89543 20 0 19.1046 0 18V14C0 13.4477 0.447715 13 1 13C1.55228 13 2 13.4477 2 14V18H12V2H2V6C2 6.55228 1.55228 7 1 7C0.447715 7 0 6.55228 0 6V2ZM7.58579 11L5.29289 13.2929C4.90237 13.6834 4.90237 14.3166 5.29289 14.7071C5.68342 15.0976 6.31658 15.0976 6.70711 14.7071L10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L7.58579 9H1C0.447715 9 0 9.44771 0 10C0 10.5523 0.447715 11 1 11H7.58579Z" fill="#8D9CA8" />
      </svg>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ColorThemeMixin } from '../../../../services/vuestic-ui';
import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';

export default {
  name: 'app-navbar-actions',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  components: {
    VaButton,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
    isTopBar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAdmin: false
    }
  },

  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
  },

  methods: {
    ...mapActions([
      'logout',
    ]),
  },
  computed: {
    isTopBarProxy: {
      get () {
        return this.isTopBar;
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar);
      },
    },
  },
};
</script>

<style lang="scss">
  .app-navbar-actions {
    display: flex;

    .btn--small {
      margin: 0 16px;
    }

    .btn--logout {
      background-color: #f1f4f8;
      border-radius: 6px;
      height: 40px;
      width: 40px;
      cursor: pointer;
      transition: 0.28s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #d6dee2;
      }
    }
  }
</style>

export const intervals = {
  "Yearly": '1y',
  "Monthly": '1M',
  // "Weekly": '1w',
   "Daily": '1d'
}

export default function (interval) {
  if (!intervals[interval]) console.error('Inteval not found, returning 1M by default')
  return intervals[interval] ?? '1M'
}

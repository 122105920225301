/**
 * User: denisverstov
 * Date: 15/09/2020
 * Time: 22:20
 */

const Moment = require('moment');
import { extendMoment } from 'moment-range';

const formats = {
  date: "YYYY-MM-DD",
  time: "HH:mm:ss",
  full: "YYYY-MM-DD HH:mm",
};

/**
 *
 * @param {(Date | Moment | object)} date
 * @param {string} [format="date"]
 * @param {string} [timeZone="Europe/Moscow"]
 * @returns {string|*|moment.Moment}
 */

const refactoringByTimeZone = (date, format = "date", timeZone = "Europe/Moscow") => {
  date = date || "";
  let returnDate = Moment(date);
  returnDate = returnDate.format(format) === "Invalid date" ? Moment() : returnDate;
  returnDate = timeZone ? returnDate.locale(timeZone) : returnDate;
  format = formats[format] || format;
  return format ? returnDate.format(format) : returnDate;
};

/**
 *
 * @param {(Date | Moment)} dateOne
 * @param {(Date | Moment)} dateTwo
 * @param {string} [time="before"]
 * @param {string} [mode="year"]
 * @returns {boolean}
 */

const compareTwoDates = (dateOne, dateTwo, time = "before", mode = "year") => {
  let bool = false;

  if (time === "before") {
    bool = Moment(dateOne).isBefore(dateTwo, mode);
  } else if (time === "after") {
    bool = Moment(dateOne).isAfter(dateTwo, mode);
  } else {
    bool = Moment(dateOne).isSame(dateTwo, mode);
  }

  return bool;
};

/**
 *
 * @param {(Date | Moment)} start
 * @param {(Date | Moment)} end
 * @returns {string[]}
 */

const getEveryDayFromRange = (start, end) => {
  const moment = extendMoment(Moment);
  start = moment(start, 'YYYY-MM-DD');
  end = moment(end, 'YYYY-MM-DD');
  const range = moment.range(start, end);
  const acc = Array.from(range.by("quarters"));
  return acc.map(m => m.format('YYYY-MM-DD'));
};

/**
 *
 * @param {(Date | Moment)} start
 * @param {(Date | Moment)} end
 * @returns {number}
 */

const getDiffTowDates = (start, end = new Date()) => {
  return parseInt(Moment.duration(Moment(end).diff(Moment(start))).asDays());
};


/**
 *
 * @param {(string)} link
 * @returns {boolean}
 */
const linkWithNoSubdirs = link => {
  if (link) {
    return /^(?:\S+:\/\/)?[^/]+\/?$/g.test(link)
  } else {
    return false
  }
}

/**
 *
 * @param {(Date | Moment | Object)} [date=null]
 * @param {(Object | string)} [state=null]
 * @param linkToProduct
 * @param belongsOzonGroup
 * @param {string} [state=null]
 * @returns {{humanDate: string, text: string, status: string}}
 */

const calcLastVisited = (date = null, state = null, linkToProduct = null, belongsOzonGroup = false) => {
  let text = "No visits";
  let status = "no-visits";
  let humanDate = "None";
  let noLink = false
  if (!date) {
    text = state === "no-map" ? "No map" : text;
    status = state === "no-statistics-data" ? "no-visits" : state;
  } else {
    const currentDate = new Date();
    const skuDate = new Date(date);
    humanDate = [refactoringByTimeZone(date), refactoringByTimeZone(date, "time")];
    const daysLag = getDiffTowDates(skuDate, currentDate);
    if (belongsOzonGroup) {
      status = daysLag < 2 ? 'success' : 'no-visits';
      text = status === 'success' ? 'Often' : 'No visits'
    } else {
      status = daysLag < 7 ? 'success' : daysLag < 14 ? 'warning' : 'rarely';
      text = status === 'success' ? 'Often' : status === 'warning' ? 'Last week' : 'Rarely';
    }
  }
  if ((linkToProduct && linkWithNoSubdirs(linkToProduct) || linkToProduct === '') && text !== 'No visits' && text !== "No map") {
    noLink = true
  }

  return { humanDate, text, status, noLink };
};

/**
 *
 * @param {(Date | Moment | string)} date
 * @param mode
 * @returns {string|*|moment.Moment}
 */

const refactoringDate = (date = '', mode = null) => {
  let newDate = refactoringByTimeZone(date);

  switch (mode) {
    case 'skuStart':
      newDate += 'T00:00:00.000+03:00';
      break;
    case 'skuEnd':
      newDate += 'T23:59:59.999+03:00';
      break;
    case 'startRange':
      newDate += ' 00:00';
      break;
    case 'endRange':
      newDate += ' 23:59';
      break;
    case 'gte':
      newDate += 'T00:00:00.000+03:00';
      break;
    case 'lte':
      newDate += 'T23:59:59.999+03:00';
      break;
    default:
      newDate = refactoringByTimeZone(date, "full");
      break;
  }

  return newDate;
};

/**
 *
 * @returns {{time_local: {gte: *, lte: *}}}
 */

const getCurrentDateRange = () => {
  return {
    time_local: {
      gte: refactoringDate('', 'startRange'),
      lte: refactoringDate('', 'endRange'),
    },
  };
};

/**
 *
 * @param {(Date | Moment)} fromDate
 * @param {(Date | Moment)} toDate
 * @returns {string}
 */

const getPeriodFromDates = (fromDate, toDate) => {
  const differance = getDiffTowDates(fromDate, toDate);

  let period = '1Y';

  if (differance === 0) {
    period = '1h';
  } else if (differance <= 30) {
    period = '1d';
  } else if (differance <= 366) {
    period = '1M';
  }

  return period;
};

export {
  refactoringByTimeZone,
  compareTwoDates,
  getEveryDayFromRange,
  calcLastVisited,
  getDiffTowDates,
  refactoringDate,
  getCurrentDateRange,
  getPeriodFromDates,
  linkWithNoSubdirs,
};

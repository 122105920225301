import {DEFAULT_FONTS} from "@/store/modules/constructor2/fonts/consts";

const CSS_PROPS = {
  width: true,
  cursor: true,
  minWidth: true,
  maxWidth: true,
  height: true,
  minHeight: true,
  maxHeight: true,
  margin: true,
  padding: true,
  justifyContent: true,
  alignItems: true,
  flexDirection: true,
  flexWrap: true,
  textAlign: true,
  color: true,
  fontSize: true,
  fontFamily: true,
  fontWeight: true,
  fontStyle: true,
  lineHeight: true,
  background: true,
  backgroundColor: true,
  backgroundImage: true,
  backgroundPosition: true,
  backgroundSize: true,
  backgroundRepeat: true,
  border: true,
  borderLeft: true,
  borderTop: true,
  borderRight: true,
  borderBottom: true,
  borderRadius: true,
  objectFit: true,
  position: true,
  top: true,
  left: true,
  right: true,
  bottom: true,
  zIndex: true,
  transition: true,
}

const COMMON_PROPS = {
  hide: {
    type: 'tab-select',
    name: 'Hidden',
    defaultValue: 'off',
    items: ['on', 'off']
  },
  width: {
    type: 'cur-min-max-input',
    name: 'Width',
    defaultValue: ''
  },
  height: {
    type: 'cur-min-max-input',
    name: 'Height',
    defaultValue: ''
  },
  minWidth: {
    defaultValue: ''
  },
  maxWidth: {
    defaultValue: ''
  },
  minHeight: {
    defaultValue: ''
  },
  maxHeight: {
    defaultValue: ''
  },
  margin: {
    type: 'input',
    name: 'Margin',
    defaultValue: ''
  },
  padding: {
    type: 'input',
    name: 'Padding',
    defaultValue: ''
  },
  border: {
    type: 'border',
    name: 'Border',
    defaultValue: ''
  },
  borderRadius: {
    type: 'input',
    name: 'Border radius',
    defaultValue: ''
  },
  borderTop: {
    type: 'input',
    name: 'Border top',
    defaultValue: ''
  },
  borderRight: {
    type: 'input',
    name: 'Border right',
    defaultValue: ''
  },
  borderBottom: {
    type: 'input',
    name: 'Border bottom',
    defaultValue: ''
  },
  borderLeft: {
    type: 'input',
    name: 'Border left',
    defaultValue: ''
  },
  background: {
    name: 'Background',
    defaultValue: ''
  },
  backgroundColor: {
    type: 'color-pick',
    name: 'Background color',
    defaultValue: ''
  },
  backgroundImage: {
    type: 'file',
    name: 'Background Image',
    defaultValue: ''
  },
  backgroundRepeat: {
    type: 'tab-select',
    name: 'Background Repeat',
    defaultValue: 'repeat',
    items: ['repeat', 'no-repeat']
  },
  backgroundPosition: {
    type: 'input',
    name: 'Background Position',
    defaultValue: ''
  },
  backgroundSize: {
    type: 'input',
    name: 'Background Size',
    defaultValue: ''
  },
  cursor: {
    type: 'select',
    name: 'Cursor',
    defaultValue: undefined,
    clearable: true,
    items: ['auto', 'pointer', 'not-allowed', 'copy', 'progress', 'text', 'wait', 'grab', 'move', 'zoom-in', 'zoom-out']
  },
  attributes: {
    type: 'attributes',
    name: 'Data Attributes',
    defaultValue: [] // will be key-value objects, e.g. {key: 'data-smth', value: 'smth-else'}
  },
  cssStop: {
    type: 'tab-select',
    name: 'Ignore global style',
    defaultValue: 'off',
    items: ['on', 'off']
  },
  classNames: {
    type: 'input',
    name: 'Class names',
    defaultValue: ''
  },
  transition: {
    type: 'input',
    name: 'Transition',
    defaultValue: ''
  },
  availableToForms: {
    type: 'tab-select',
    name: 'Make available to forms',
    defaultValue: 'off',
    items: ['on', 'off']
  }
}

const FLEX_PROPS = {
  flexDirection: {
    type: 'select',
    name: 'Flex Direction',
    defaultValue: 'row',
    items: ['row', 'column', 'row-reverse', 'column-reverse']
  },
  flexWrap: {
    type: 'select',
    name: 'Flex wrap',
    defaultValue: 'nowrap',
    items: ['nowrap', 'wrap', 'wrap-reverse', 'initial']
  },
  justifyContent: {
    type: 'align',
    name: 'Justify Content',
    defaultValue: 'flex-start',
    items: ['flex-start', 'center', 'flex-end']
  },
  alignItems: {
    type: 'align',
    name: 'Align Items',
    defaultValue: 'flex-start',
    items: ['flex-start', 'center', 'flex-end']
  }
}

const POSITIONING_PROPS = {
  position: {
    type: 'select',
    name: 'Position',
    defaultValue: 'static',
    items: ['static', 'relative', 'absolute', 'fixed', 'sticky']
  },
  top: {
    type: 'input',
    name: 'Position top',
    defaultValue: ''
  },
  left: {
    type: 'input',
    name: 'Position left',
    defaultValue: ''
  },
  right: {
    type: 'input',
    name: 'Position right',
    defaultValue: ''
  },
  bottom: {
    type: 'input',
    name: 'Position bottom',
    defaultValue: ''
  },
  zIndex: {
    type: 'input',
    inputType: 'number',
    name: 'Z-index',
    defaultValue: ''
  }
}

const TEXT_PROPS = {
  textAlign: {
    type: 'align',
    name: 'Text Align',
    defaultValue: 'center',
    items: ['left', 'center', 'right']
  },
  color: {
    type: 'color-pick',
    name: 'Text Color',
    defaultValue: '#000000'
  },
  lineHeight: {
    type: 'line-height',
    name: 'Line Height',
    defaultValue: '150%'
  },
  fontSize: {
    type: 'font-size',
    name: 'Text Size',
    defaultValue: '16px'
  },
  fontFamily: {
    type: 'font-family',
    name: 'Font Family',
    defaultValue: DEFAULT_FONTS[0].fontFamily,
  },
  fontWeight: {
    type: 'font-weight',
    name: 'Font Weight',
    defaultValue: DEFAULT_FONTS[0].fontWeight,
  },
  fontStyle: {
    type: 'font-style',
    name: 'Font Style',
    defaultValue: DEFAULT_FONTS[0].fontStyle,
  },
}

const ROW = {
  componentKey: 'Row',
  name: 'Row',
  withChildren: true,
  props: {
    direction: {
      type: 'tab-select',
      name: 'Direction',
      defaultValue: 'horizontal',
      items: ['horizontal', 'vertical']
    },
    size: {
      type: 'input',
      name: 'Space between children',
      defaultValue: ''
    },
    align: {
      type: 'align',
      name: 'Align',
      defaultValue: 'start',
      items: ['start', 'center', 'end']
    },
    flexWrap: {
      type: 'select',
      name: 'Flex wrap',
      defaultValue: 'nowrap',
      items: ['nowrap', 'wrap', 'wrap-reverse', 'initial']
    },
    ...POSITIONING_PROPS,
    ...COMMON_PROPS
  }
}

const COLUMN = {
  componentKey: 'Column',
  name: 'Column',
  withChildren: true,
  props: {
    ...FLEX_PROPS,
    flexDirection: {
      ...FLEX_PROPS.flexDirection,
      defaultValue: 'column'
    },
    ...POSITIONING_PROPS,
    ...COMMON_PROPS,
  }
}

const IMAGE = {
  componentKey: 'Image',
  name: 'Image',
  props: {
    src: {
      type: 'file',
      fileType: 'image',
      name: 'Image',
      defaultValue: "/static/constructor/placeholders/1200x600.png",
    },
    objectFit: {
      type: 'select',
      name: 'Object fit',
      defaultValue: 'unset',
      items: ['unset', 'contain', 'cover', 'stretch']
    },
    ...COMMON_PROPS
  }
}

const TEXT = {
  componentKey: 'Text',
  name: 'Text',
  props: {
    element: {
      type: 'select',
      name: 'Element',
      defaultValue: 'p',
      items: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
    },
    maxLines: {
      type: 'input',
      inputType: 'number',
      name: 'Max Lines',
      defaultValue: ''
    },
    content: {
      type: 'textarea',
      name: 'Text',
      defaultValue: 'Пример текста'
    },
    ...TEXT_PROPS,
    ...COMMON_PROPS
  }
}

const VIDEO = {
  componentKey: 'Video',
  name: 'Video',
  props: {
    src: {
      type: 'file',
      fileType: 'video',
      name: 'Video',
      defaultValue: "/static/constructor/placeholders/video.mp4",
    },
    poster: {
      type: 'file',
      name: 'Poster',
      defaultValue: null,
    },
    loop: {
      type: 'tab-select',
      name: 'Loop',
      defaultValue: 'Off',
      items: ['Off', 'On']
    },
    autoplay: {
      type: 'tab-select',
      name: 'Autoplay',
      defaultValue: 'Off',
      items: ['Off', 'On']
    },
    ...COMMON_PROPS,
  }
}

const YOUTUBE = {
  componentKey: 'Youtube',
  name: 'Youtube',
  props: {
    youtubeId: {
      type: 'youtube',
      name: 'Youtube Link / ID',
      defaultValue: 'MUdJyNMx8kc'
    },
    ...COMMON_PROPS,
  }
}

const ACCORDION_HEADER = {
  componentKey: 'AccordionHeader',
  name: 'Column',
  displayName: 'Accordion Header',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined
  }
}

const ACCORDION_CONTENT = {
  componentKey: 'AccordionContent',
  name: 'Column',
  displayName: 'Accordion Content',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined
  },
}

const ACCORDION = {
  componentKey: 'Accordion',
  name: 'Accordion',
  staticChildren: true,
  props: {
    iconSvgCodeCollapsed: {
      type: 'textarea',
      name: 'Svg-code of icon (collapsed)',
      defaultValue: ''
    },
    iconSvgCodeExpanded: {
      type: 'textarea',
      name: 'Svg-code of icon (expanded)',
      defaultValue: ''
    },
    ...COMMON_PROPS,
  },
  children: [
    {
      ...ACCORDION_HEADER,
      children: [
        {
          name: 'Image',
          ...IMAGE,
        }
      ]
    },
    {
      ...ACCORDION_CONTENT,
      children: [
        {
          name: 'Row',
          ...ROW,
          children: [
            {
              name: 'Column',
              ...COLUMN,
              props: {
                ...COLUMN.props,
                padding: {
                  ...COLUMN.props.padding,
                  defaultValue: '0 40px'
                },
                flexDirection: {
                  ...COLUMN.props.flexDirection,
                  defaultValue: 'column'
                },
                justifyContent: {
                  ...COLUMN.props.justifyContent,
                  defaultValue: 'center'
                },
                width: {
                  ...COLUMN.props.width,
                  value: {
                    desktop: '50%'
                  }
                }
              },
              children: [
                {
                  name: 'Text',
                  ...TEXT,
                  props: {
                    ...TEXT.props,
                    content: {
                      ...TEXT.props.content,
                      defaultValue: 'Это пример описания'
                    },
                    fontSize: {
                      ...TEXT.props.fontSize,
                      defaultValue: '16px'
                    },
                    fontWeight: {
                      ...TEXT.props.fontWeight,
                      defaultValue: '700'
                    },
                    textAlign: {
                      ...TEXT.props.textAlign,
                      defaultValue: 'left'
                    }
                  }
                },
                {
                  name: 'Text',
                  ...TEXT,
                  props: {
                    ...TEXT.props,
                    content: {
                      ...TEXT.props.content,
                      defaultValue: 'Это пример описания. Здесь шрифт поменьше, а контента - побольше.'
                    },
                    fontSize: {
                      ...TEXT.props.fontSize,
                      defaultValue: '12px'
                    },
                    textAlign: {
                      ...TEXT.props.textAlign,
                      defaultValue: 'left'
                    }
                  }
                }
              ]
            },
            {
              name: 'Column',
              ...COLUMN,
              props: {
                ...COLUMN.props,
                width: {
                  ...COLUMN.props.width,
                  value: {
                    desktop: '50%'
                  }
                }
              },
              children: [
                {
                  name: 'Image',
                  ...IMAGE,
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

const BUBBLE = {
  componentKey: 'Bubble',
  name: 'Bubble',
  withChildren: true,
  nonDraggable: true,
  possibleParents: ['Bubbles'],
  props: {
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '30px'
    },
    top: {
      type: 'input',
      inputType: 'number',
      name: 'Bubble Position Top',
      defaultValue: '24'
    },
    left: {
      type: 'input',
      inputType: 'number',
      name: 'Bubble Position Left',
      defaultValue: '72'
    },
    modalPosition: {
      type: 'select',
      name: 'Bubble Modal Position',
      defaultValue: 'left-bottom',
      items: ['left-bottom', 'left-top', 'right-bottom', 'right-top']
    },
  },
  children: [
    {
      name: 'Text',
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props.content,
          defaultValue: 'Это пример описания'
        },
        fontSize: {
          ...TEXT.props.fontSize,
          defaultValue: '4'
        },
        fontWeight: {
          ...TEXT.props.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...TEXT.props.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      name: 'Text',
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props.content,
          defaultValue: 'Это пример описания. Здесь шрифт поменьше, а контента - побольше.'
        },
        fontSize: {
          ...TEXT.props.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...TEXT.props.textAlign,
          defaultValue: 'left'
        }
      }
    }
  ]
}

const BUBBLES = {
  componentKey: 'Bubbles',
  name: 'Bubbles',
  possibleChildren: ['Bubble'],
  props: {
    image: {
      ...IMAGE.props.src,
    },
    ...COMMON_PROPS,
  },
  children: [
    {
      name: 'Bubble',
      ...BUBBLE,
    }
  ],
}

const TABLE_ROW = {
  componentKey: 'TableRow',
  name: 'TableRow',
  displayName: 'Table Row',
  possibleChildren: ['TableColumn'],
  possibleParents: ['Table'],
  noHover: true,
}

const TABLE_COLUMN = {
  componentKey: 'TableColumn',
  name: 'TableColumn',
  displayName: 'Table Column',
  withChildren: true,
  possibleParents: ['TableRow'],
  noHover: true,
  props: {
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '20px'
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #dddddd'
    },
    borderTop: {
      type: 'input',
      name: 'Border top',
      defaultValue: ''
    },
    borderRight: {
      type: 'input',
      name: 'Border right',
      defaultValue: ''
    },
    borderBottom: {
      type: 'input',
      name: 'Border bottom',
      defaultValue: ''
    },
    borderLeft: {
      type: 'input',
      name: 'Border left',
      defaultValue: ''
    },
    colspan: {
      type: 'input',
      inputType: 'number',
      defaultValue: ''
    }
  },
}

const TABLE = {
  componentKey: 'Table',
  name: 'Table',
  possibleChildren: ['TableRow'],
  props: {
    ...COMMON_PROPS,
  },
  children: new Array(3).fill({}).map((_, rowIndex) => ({
    name: 'TableRow',
    order: rowIndex + 1,
    ...TABLE_ROW,
    children: new Array(5).fill({}).map((_, colIndex) => ({
      name: 'TableColumn',
      order: colIndex + 1,
      ...TABLE_COLUMN,
    }))
  }))
}

const SLIDE = {
  componentKey: 'Slide',
  name: 'Slide',
  withChildren: true,
  possibleParents: ['Slider'],
  props: {
    padding: {
      ...COMMON_PROPS.padding,
    },
  },
  children: [
    {
      name: 'Image',
      ...IMAGE,
    }
  ]
}

const SLIDER = {
  componentKey: 'Slider',
  name: 'Slider',
  possibleChildren: ['Slide'],
  props: {
    slidesPerView: {
      type: 'input',
      inputType: 'number',
      minValue: 1,
      name: 'Slides Per View',
      defaultValue: 1
    },
    leftArrowSvgCode: {
      type: 'textarea',
      name: 'Svg-code of arrow (left)',
      defaultValue: ''
    },
    arrowColor: {
      type: 'color-pick',
      name: 'Navigation Arrow Color (default only)',
      defaultValue: '#000000'
    },
    arrowWidth: {
      type: 'input',
      name: 'Navigation Arrow Width',
      defaultValue: '46px'
    },
    arrowHeight: {
      type: 'input',
      name: 'Navigation Arrow Height',
      defaultValue: '46px'
    },
    bulletColor: {
      type: 'color-pick',
      name: 'Pagination Bullet Color',
      defaultValue: '#cccccc'
    },
    showBullets: {
      type: 'tab-select',
      name: 'Enable pagination',
      defaultValue: 'on',
      items: ['on', 'off']
    },
    activeBulletColor: {
      type: 'color-pick',
      name: 'Pagination Bullet Color (active)',
      defaultValue: '#16c7dc'
    },
    ...COMMON_PROPS,
  },
  children: [
    {
      name: 'Slide',
      ...SLIDE,
    }
  ]
}

const LINK = {
  componentKey: 'Link',
  name: 'Link',
  withChildren: true,
  props: {
    url: {
      type: 'url',
      name: 'URL',
      defaultValue: ''
    },
    target: {
      type: 'tab-select',
      name: 'Target',
      defaultValue: '_blank',
      items: ['_self', '_blank']
    },
    internal: {
      defaultValue: false
    },
    ...COMMON_PROPS,
  }
}

const POPOVER_HEADER = {
  componentKey: 'PopoverHeader',
  name: 'Column',
  displayName: 'Popover Header',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined
  },
  children: [
    {
      name: 'Text',
      ...TEXT
    }
  ]
}

const POPOVER_CONTENT = {
  componentKey: 'PopoverContent',
  name: 'Column',
  displayName: 'Popover Content',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined
  },
  children: new Array(3).fill({
    name: 'Text',
    ...TEXT,
    props: {
      ...TEXT.props,
      margin: {
        ...TEXT.props.margin,
        defaultValue: '10px 0'
      }
    }
  })
}

const POPOVER = {
  componentKey: 'Popover',
  name: 'Popover',
  staticChildren: true,
  props: {
    trigger: {
      type: 'tab-select',
      name: 'Trigger',
      defaultValue: 'hover',
      items: ['hover', 'click']
    },
    triggered: {
      type: 'tab-select',
      name: 'Triggered (for visual)',
      defaultValue: 'off',
      items: ['on', 'off']
    },
    ...COMMON_PROPS,
    ...POSITIONING_PROPS,
    position: {
      ...POSITIONING_PROPS.position,
      defaultValue: 'relative'
    }
  },
  children: [
    POPOVER_HEADER,
    POPOVER_CONTENT
  ]
}

const SCRIPT = {
  componentKey: 'Script',
  name: 'Script',
  noHover: true,
  noAdaptive: true,
  props: {
    code: {
      type: 'code-editor',
      name: 'Code',
      defaultValue: ''
    },
    auto: {
      type: 'tab-select',
      name: 'Run the code automatically (in editor)',
      defaultValue: 'off',
      items: ['on', 'off']
    }
  }
}

const THE360VIEWER_LOADER = {
  ...COLUMN,
  componentKey: 'The360ViewerLoader',
  noManualRemove: true,
  nonDraggable: true,
  customRender: true,
  noDynamic: true,
  name: 'Column',
  displayName: 'Loader'
}

const THE360VIEWER_ADDITIONAL_CONTENT = {
  ...COLUMN,
  componentKey: 'The360ViewerAdditionalContent',
  props: {
    ...COLUMN.props,
    hide: {
      ...COLUMN.props.hide,
      defaultValue: 'on'
    }
  },
  noManualRemove: true,
  nonDraggable: true,
  noDynamic: true,
  name: 'Column',
  displayName: 'Additional Content'
}

const THE360VIEWER = {
  componentKey: 'The360Viewer',
  name: 'The360Viewer',
  noHover: true,
  noAdaptive: true,
  staticChildren: true,
  displayName: '360 Viewer',
  props: {
    images: {
      type: 'files',
      name: 'Images',
      defaultValue: ''
    }
  },
  children: [
    THE360VIEWER_LOADER,
    THE360VIEWER_ADDITIONAL_CONTENT,
  ]
}

const FORM = {
  componentKey: "Form",
  name: 'Form',
  displayName: 'Form',
  noHover: true,
  noAdaptive: true,
  withChildren: true,
  showId: true,
  prohibitedUpperElements: ['Form'],
  props: {
    submissionLink: {
      type: 'input',
      name: 'Submission link',
      defaultValue: ''
    },
    scrollToFirstValidationError: {
      type: 'tab-select',
      name: 'Scroll to first validation error',
      defaultValue: 'on',
      items: ['on', 'off']
    },
    afterSubmitAction: {
      name: 'After submit action',
      defaultValue: 'redirect',
      items: ['redirect', 'manipulateElements']
    },
    redirectLink: {
      name: 'Redirect link',
      defaultValue: ''
    },
    redirectLinkTarget: {
      type: 'tab-select',
      name: 'Target',
      defaultValue: '_blank',
      items: ['_self', '_blank']
    },
    redirectLinkInternal: {
      defaultValue: false
    },
    hideElementsOnSuccessfulSubmit: {
      type: 'select',
      name: 'Hide elements on successful submit',
      defaultValue: []
    },
    showElementsOnSuccessfulSubmit: {
      type: 'select',
      name: 'Show elements on successful submit',
      defaultValue: []
    },
    hideElementsOnFailedSubmit: {
      type: 'select',
      name: 'Hide elements on failed submit',
      defaultValue: []
    },
    showElementsOnFailedSubmit: {
      type: 'select',
      name: 'Show elements on failed submit',
      defaultValue: []
    }
  }
}

const FORM_ERROR = {
  componentKey: "FormError",
  name: "FormError",
  displayName: "Error Message",
  noManualRemove: true,
  nonDraggable: true,
  noDynamic: true,
  isBaseComponent: true,
  props: {
    ...TEXT.props,
    color: {
      ...TEXT.props.color,
      defaultValue: '#ff0000'
    },
    textAlign: {
      ...TEXT.props.textAlign,
      defaultValue: 'left'
    },
    maxLines: undefined,
    element: undefined,
    content: undefined,
    hide: undefined,
  }
}

const FORM_INPUT = {
  componentKey: "FormInput",
  name: 'FormInput',
  displayName: 'Form Input',
  passStyle: true,
  staticChildren: true,
  requiredUpperElements: ['Form'],
  props: {
    placeholder: {
      name: 'Placeholder',
      defaultValue: ''
    },
    name: {
      name: 'Name',
      defaultValue: ''
    },
    autocomplete: {
      name: 'Autocomplete',
      defaultValue: '',
      items: ['', 'new-password']
    },
    type: {
      name: 'Type',
      defaultValue: 'text',
      items: ['text', 'number', 'password']
    },
    rules: {
      name: 'Rules',
      defaultValue: []
    },
    ...TEXT_PROPS,
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left'
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%'
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '4px 12px'
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #d9d9d9'
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px'
    },
  },
  children: [
    FORM_ERROR
  ]
}

const FORM_BUTTON = {
  componentKey: 'FormButton',
  name: 'FormButton',
  displayName: 'Form Button',
  withChildren: true,
  props: {
    type: {
      name: 'Type',
      defaultValue: 'submit',
      items: ['submit', 'button']
    },
    connectedForm: {
      name: 'Connected form',
      defaultValue: '',
    },
    ...COMMON_PROPS,
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000'
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '4px 12px'
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px'
    },
    cursor: {
      ...COMMON_PROPS.cursor,
      defaultValue: 'pointer'
    }
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        color: {
          ...TEXT.props.color,
          defaultValue: '#ffffff'
        }
      }
    }
  ]
}

const DEFAULT_FORM_LABEL = {
  ...TEXT,
  customRender: true,
  displayName: 'Label',
  props: {
    ...TEXT.props,
    margin: {
      ...TEXT.props.margin,
      defaultValue: '0 0 0 6px'
    },
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left'
    },
    width: {
      ...COMMON_PROPS.width,
      defaultValue: 'fit-content'
    }
  }
}

const FORM_CHECKBOX = {
  componentKey: 'FormCheckbox',
  name: 'FormCheckbox',
  displayName: 'Form Checkbox',
  staticChildren: true,
  requiredUpperElements: ['Form'],
  props: {
    name: {
      name: 'Name',
      defaultValue: ''
    },
    activeColor: {
      name: 'Active color',
      defaultValue: '#000000'
    },
    inactiveColor: {
      name: 'Inactive color',
      defaultValue: '#ffffff'
    },
    tickColor: {
      name: 'Tick color',
      defaultValue: '#ffffff'
    },
    boxBorderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px'
    },
    boxBorder: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #000000'
    },
    boxWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px'
    },
    boxHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px'
    },
    tickWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px'
    },
    tickHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px'
    },
    rules: {
      name: 'Rules',
      defaultValue: []
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: 'fit-content'
    }
  },
  children: [
    DEFAULT_FORM_LABEL,
    FORM_ERROR
  ]
}

const FORM_RADIO_GROUP = {
  componentKey: "FormRadioGroup",
  name: "FormRadioGroup",
  displayName: "Form Radio Group",
  withChildren: true,
  noDynamic: true,
  requiredUpperElements: ['Form'],
  prohibitedUpperElements: ['FormRadioGroup'],
  props: {
    name: {
      name: 'Name',
      defaultValue: ''
    },
    defaultValue: {
      name: 'Default value',
      defaultValue: ''
    },
    rules: {
      name: 'Rules',
      defaultValue: []
    },
    ...COLUMN.props
  },
  children: [
    FORM_ERROR
  ]
}

const FORM_RADIO_BUTTON = {
  componentKey: "FormRadioButton",
  name: "FormRadioButton",
  displayName: "Form Radio Button",
  staticChildren: true,
  requiredUpperElements: ['FormRadioGroup'],
  props: {
    value: {
      name: 'Value',
      defaultValue: ''
    },
    activeColor: {
      name: 'Active color',
      defaultValue: '#000000'
    },
    inactiveColor: {
      name: 'Inactive color',
      defaultValue: '#ffffff'
    },
    dotColor: {
      name: 'Dot color',
      defaultValue: '#ffffff'
    },
    dotWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '10px'
    },
    dotHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '10px'
    },
    radioBorder: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #d9d9d9'
    },
    radioWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px'
    },
    radioHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px'
    },
    ...COMMON_PROPS,
  },
  children: [
    DEFAULT_FORM_LABEL,
  ]
}

const FORM_SELECT_SELECTOR = {
  componentKey: "FormSelectSelector",
  name: "FormSelectSelector",
  displayName: "Selector",
  staticChildren: true,
  nonDraggable: true,
  noManualRemove: true,
  noSave: true,
  customRender: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined,
    availableToForms: undefined,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%'
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #f1f4f8'
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px'
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '8px 12px'
    },
    zIndex: {
      ...POSITIONING_PROPS.zIndex,
      defaultValue: '2'
    },
    ...TEXT_PROPS,
    content: undefined,
    element: undefined,
    maxLines: undefined,
    textAlign: undefined,
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '14px'
    }
  },
}

const FORM_SELECT_OPTION = {
  componentKey: "FormSelectOption",
  name: "FormSelectOption",
  displayName: "Option",
  requiredUpperElements: ['FormSelectDropdown'],
  noSave: true,
  customRender: true,
  props: {
    value: {
      name: 'Value',
      defaultValue: ''
    },
    label: {
      name: 'Label',
      defaultValue: '',
      value: {
        desktop: 'Option'
      }
    },
  }
}

const FORM_SELECT_DROPDOWN = {
  componentKey: "FormSelectDropdown",
  name: "FormSelectDropdown",
  displayName: "Dropdown",
  nonDraggable: true,
  noManualRemove: true,
  noSave: true,
  customRender: true,
  possibleChildren: ['FormSelectOption'],
  props: {
    noOptionsText: {
      name: 'No options text',
      defaultValue: 'No data'
    },
    noOptionsColor: {
      name: 'No options color',
      defaultValue: '#34495e'
    },
    optionPadding: {
      name: 'Option padding',
      defaultValue: '6px'
    },
    optionBorderRadius: {
      name: 'Option border radius',
      defaultValue: '6px'
    },
    optionBackground: {
      name: 'Option background',
      defaultValue: '',
      value: {
        hover_desktop: '#f1f1f1'
      }
    },
    selectedOptionBackground: {
      name: 'Selected option background',
      defaultValue: '',
      value: {
        desktop: 'rgba(52, 73, 94, 0.1)'
      }
    },
    optionTransition: {
      name: 'Option transition',
      defaultValue: '',
      value: {
        desktop: 'background 0.2s ease'
      }
    },
    spaceBetweenOptions: {
      name: 'Space between options',
      defaultValue: '2px'
    },
    ...COMMON_PROPS,
    hide: undefined,
    margin: undefined,
    availableToForms: undefined,
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '2px'
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px'
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #f1f4f8'
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%'
    },
    maxHeight: {
      ...COMMON_PROPS.maxHeight,
      defaultValue: '200px'
    },
    ...POSITIONING_PROPS,
    position: undefined,
    zIndex: {
      ...POSITIONING_PROPS.zIndex,
      defaultValue: '1'
    },
    top: {
      ...POSITIONING_PROPS.top,
      defaultValue: 'calc(100% + 4px)'
    },
    left: {
      ...POSITIONING_PROPS.left,
      defaultValue: '0'
    },
    ...TEXT_PROPS,
    content: undefined,
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left'
    },
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '14px'
    },
    color: {
      ...TEXT_PROPS.color,
      defaultValue: '#34495e'
    }
  },
  children: [
    FORM_SELECT_OPTION
  ]
}

const FORM_SELECT = {
  componentKey: "FormSelect",
  name: "FormSelect",
  displayName: "Form Select",
  staticChildren: true,
  requiredUpperElements: ['Form'],
  props: {
    multiple: {
      name: 'Multiple',
      defaultValue: 'off',
      items: ['on', 'off']
    },
    name: {
      name: 'Name',
      defaultValue: ''
    },
    defaultValue: {
      name: 'Default value',
      defaultValue: ''
    },
    placeholder: {
      name: 'Placeholder',
      defaultValue: 'Search to select'
    },
    rules: {
      name: 'Rules',
      defaultValue: []
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%'
    },
  },
  children: [
    FORM_SELECT_SELECTOR,
    FORM_SELECT_DROPDOWN,
    FORM_ERROR
  ]
}

const COMMON_ELEMENTS = {
  Row: ROW,
  Column: COLUMN,
  Text: TEXT,
  Image: IMAGE,
  Video: VIDEO,
  Youtube: YOUTUBE,
  Accordion: ACCORDION,
  Bubbles: BUBBLES,
  Table: TABLE,
  Slider: SLIDER,
  Link: LINK,
  Popover: POPOVER,
  Script: SCRIPT,
  The360Viewer: THE360VIEWER,
  Form: FORM,
  FormInput: FORM_INPUT,
  FormButton: FORM_BUTTON,
  FormCheckbox: FORM_CHECKBOX,
  FormRadioGroup: FORM_RADIO_GROUP,
  FormRadioButton: FORM_RADIO_BUTTON,
  FormSelect: FORM_SELECT,
}

const DYNAMIC_DATA_PROVIDER = {
  componentKey: 'DynamicDataProvider',
  name: 'DynamicDataProvider',
  displayName: 'Dynamic Data Provider',
  staticChildren: true,
  possibleChildren: ['Row', 'Column', 'Accordion', 'Slider', 'Popover'],
  props: {
    dataSource: {
      type: 'data-source'
    },
    dataSourceItems: {
      type: 'dynamic-data-list',
      name: 'Data Source Items',
    },
    providedDataSourceItems: {
      type: 'dynamic-data-list',
      name: 'Provided Data Source Items',
      orderable: true,
    },
    resetButton: {
      type: 'dynamic-data-reset-button'
    },
  },
}

const DYNAMIC_DATA_RECEIVER = {
  componentKey: 'DynamicDataReceiver',
  name: 'DynamicDataReceiver',
  displayName: 'Dynamic Data Receiver',
  nonDraggable: true,
  staticChildren: true,
  possibleChildren: ['Text', 'Image', 'Video', 'Youtube', 'Link'],
  props: {
    content: {
      name: 'Content',
      type: 'input',
      defaultValue: ''
    },
    hideOnEmptyData: {
      type: 'tab-select',
      name: 'Hide on empty data',
      items: ['on', 'off'],
      defaultValue: 'off'
    },
    fallbackOnEmptyData: {
      type: 'tab-select',
      name: 'Fallback on empty data',
      items: ['on', 'off'],
      defaultValue: 'on'
    }
  },
}

const DYNAMIC_DATA_COMPONENTS = {
  DynamicDataProvider: DYNAMIC_DATA_PROVIDER,
  DynamicDataReceiver: DYNAMIC_DATA_RECEIVER
}

const BASE_COMPONENT_KEYS = Object.keys(COMMON_ELEMENTS)
const DYNAMIC_DATA_COMPONENT_KEYS = Object.keys(DYNAMIC_DATA_COMPONENTS)
const DYNAMIC_DATA_COMPONENT_KEYS_OBJ = DYNAMIC_DATA_COMPONENT_KEYS.reduce((a, c) => ({...a, [c]: true}), {})

const ALL_ELEMENTS = {
  ...COMMON_ELEMENTS,
  Bubble: BUBBLE,
  TableRow: TABLE_ROW,
  TableColumn: TABLE_COLUMN,
  Slide: SLIDE,
  PopoverHeader: POPOVER_HEADER,
  PopoverContent: POPOVER_CONTENT,
  AccordionHeader: ACCORDION_HEADER,
  AccordionContent: ACCORDION_CONTENT,
  The360ViewerLoader: THE360VIEWER_LOADER,
  The360ViewerAdditionalContent: THE360VIEWER_ADDITIONAL_CONTENT,
  FormError: FORM_ERROR,
  FormSelectSelector: FORM_SELECT_SELECTOR,
  FormSelectDropdown: FORM_SELECT_DROPDOWN,
  FormSelectOption: FORM_SELECT_OPTION,
}

export {
  CSS_PROPS,
  COMMON_ELEMENTS,
  DYNAMIC_DATA_COMPONENTS,
  COMMON_PROPS,
  FLEX_PROPS,
  POSITIONING_PROPS,
  BASE_COMPONENT_KEYS,
  DYNAMIC_DATA_COMPONENT_KEYS,
  DYNAMIC_DATA_COMPONENT_KEYS_OBJ,
  ALL_ELEMENTS,
}

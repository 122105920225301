<template>
  <i>
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="0.8em" height="1em"
         preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1280">
      <path fill="currentColor"
            d="M1011 928q0 13-10 23l-50 50q-10 10-23 10t-23-10L512 608l-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: "IconAngleUp"
}
</script>

<style scoped>

</style>

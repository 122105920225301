/**
 * User: denisverstov
 * Date: 08.01.2021
 * Time: 23:56
 */

import ToastifyErrorSave from "../../components/ui/toastify/ToastifyErrorSave";
import Toastify from '../../components/ui/toastify/Toastify';


const defaultConfig = {
  toastClassName: "Vue-Toastification__toast--info",
  position: 'bottom-left',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 1.65,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false,
};

/**
 * Show Toastify Error
 * @param {string} text
 * @param {object} toast
 */

const showToastError = (text, toast) => {
  toast.error(
    {
      component: ToastifyErrorSave,
      props: { text },
    }, {
      ...defaultConfig,
      toastClassName: "Vue-Toastification__toast--error",
      timeout: 2000,
    });
};

/**
 * Show Toastify Success
 * @param {string} text
 * @param {object} toast
 */

const showToastSuccess = (text, toast) => {
  toast.success(
    {
      component: ToastifyErrorSave,
      props: { text },
    }, {
      ...defaultConfig,
      toastClassName: "Vue-Toastification__toast--success",
    });
};

/**
 * Show Toastify Status
 * @param {string} text
 * @param {object} toast
 */

const showToastStatus = (text, toast) => {
  return toast.info(
    {
      component: ToastifyErrorSave,
      props: { text },
    }, {
      ...defaultConfig,
      toastClassName: "Vue-Toastification__toast--info",
      timeout: false,
    });
};

/**
 * Show Toastify User Update
 * @param {string} text
 * @param {object} toast
 */

const showToastUserUpdate = toast => {
  return toast.info(
    {
      component: Toastify,
    }, {
      ...defaultConfig,
      toastClassName: "Vue-Toastification__toast--info",
      timeout: 100000,
    });
};


export {
  showToastError,
  showToastSuccess,
  showToastStatus,
  showToastUserUpdate,
};

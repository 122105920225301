import getEpackFormattedDate from "../../../services/API/helpers/getEpackFormattedDate";

export default function ({aggregations, interval, epacks, range}) {
  const rawData = [],
    tableData = {}

  if (aggregations) {
    Object.values(aggregations).forEach(a => { // a => aggregation
      if (a.buckets) {
        a.buckets.forEach(bucket => {
          const stringDate = bucket.key_as_string,
            {formattedDate, formattedDateString, date} = getEpackFormattedDate(stringDate, range, interval)

          Object.values(bucket).filter(b => typeof b === 'object').forEach(bv => { // bv => bucket value
            if (bv.buckets) {
              bv.buckets.forEach(bvBucket => {
                const //countInRetailer = bvBucket.doc_count,
                  retailerName = bvBucket.key

                Object.values(bvBucket).filter(b => typeof b === 'object').forEach(bvBucketValue => {
                  if (bvBucketValue.buckets) {
                    bvBucketValue.buckets.forEach(bvBucketValueBucket => {
                      const licenseName = bvBucketValueBucket.key//,

                      Object.values(bvBucketValueBucket).filter(b => typeof b === 'object').forEach(bvBucketValueBucketValue => {
                        if (bvBucketValueBucketValue.buckets) {
                          bvBucketValueBucketValue.buckets.forEach(bvBucketValueBucketValueBucket => {

                              const epackage_uid = bvBucketValueBucketValueBucket.key,
                                countInProduct = bvBucketValueBucketValueBucket.doc_count
                            if (epacks[epackage_uid]) {
                              const item = {
                                epackage_uid: epackage_uid,
                                count: {
                                  [retailerName]: countInProduct
                                },
                                productName: epacks[epackage_uid]?.name,
                                mpn: epacks[epackage_uid]?.mpn,
                                ean: epacks[epackage_uid]?.ean,
                                licenseName,
                                date
                              }

                              rawData.push(item)

                              if (!tableData[formattedDate]) {
                                tableData[formattedDate] = {
                                  sample_date: date,
                                  formattedDateString: formattedDateString,
                                  formattedDate: formattedDate,
                                  licenses: {
                                    [item.licenseName]: {
                                      licenseName: item.licenseName,
                                      items: {
                                        [item.epackage_uid]: item
                                      }
                                    }
                                  }
                                }
                              } else {
                                if (tableData[formattedDate].licenses[item.licenseName]) {
                                  if (!Object.keys(tableData[formattedDate].licenses[item.licenseName].items).includes(item.epackage_uid)) {
                                    tableData[formattedDate].licenses[item.licenseName].items[item.epackage_uid] = item
                                  } else {
                                    tableData[formattedDate].licenses[item.licenseName].items[item.epackage_uid].count[retailerName] = countInProduct
                                  }
                                } else {
                                  tableData[formattedDate].licenses[item.licenseName] = {
                                    licenseName: item.licenseName,
                                    items: {
                                      [item.epackage_uid]: item
                                    }
                                  }
                                }
                                if (tableData[formattedDate].licenses[item.licenseName].items[item.epackage_uid]) {
                                  tableData[formattedDate].licenses[item.licenseName].items[item.epackage_uid].count[retailerName] = countInProduct
                                } else {
                                  tableData[formattedDate].licenses[item.licenseName].items[item.epackage_uid] = item
                                }
                              }
                            }
                          })
                        } else {
                          console.warn('There are no buckets in the bvBucketValueBucketValue. bvBucketValueBucketValue:');
                          console.log(bvBucketValueBucketValue);
                        }
                      })
                    })
                  } else {
                    console.warn('There are no buckets in the bvBucketValue. bvBucketValue:');
                    console.log(bvBucketValue);
                  }
                })
              })
            } else {
              console.warn('There are no buckets in the bv. BV (Bucket value):');
              console.log(bv);
            }
          })
        })
      }
    })
  }

  return {rawData, tableData}
}

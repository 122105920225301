/**
 * User: denisverstov
 * Date: 15/09/2020
 * Time: 22:20
 */

/**
 *
 * @param {Object} array
 * @param {Array} data
 * @returns {*}
 */

const fillArray = (array, data) => {
  data.forEach(info => {
    array.names.push(info.name);
    array.ids.push(info.id);
  });
  return array;
};

/**
 *
 * @param {Array} userLicenses
 * @param {Array} userRetailers
 * @returns {{}}
 */

const getUserLicensesRetailers = (userLicenses = null, userRetailers = null) => {

  const data = {};

  const user = JSON.parse(localStorage.getItem('ttlUserInfo'));

  let isAdmin = user.roles.includes('ROLE_ADMIN');

  if (userLicenses) {
    user.licenses = user.licenses.filter(license => {
      return userLicenses.includes(license.id);
    });
  }

  if (userRetailers) {
    user.retailers = user.retailers.filter(retailer => {
      return userRetailers.includes(retailer.id);
    });
  }

  user.retailers = user.retailers.map(retailer => {
    retailer.name = retailer.name.charAt(0).toUpperCase() + retailer.name.slice(1);
    return retailer;
  });

  let retailers = { type: "retailers", ids: [], names: [], data: user.retailers };
  let licenses = { type: "licenses", ids: [], names: [], data: user.licenses };

  data["licenses"] = fillArray(licenses, user.licenses);
  data["retailers"] = fillArray(retailers, user.retailers);
  data["isAdmin"] = isAdmin;

  return data;
};

export default {
  getUserLicensesRetailers,
};

import {
  COMMON_ELEMENTS,
  ALL_ELEMENTS,
  DYNAMIC_DATA_COMPONENTS,
  DYNAMIC_DATA_COMPONENT_KEYS_OBJ
} from "./consts";
import dcopy from "deep-copy";
import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import axios from "axios";
import {AVAILABLE_SIZES_LIST} from "@/store/modules/constructor2/consts";
import {DEFAULT_FONTS} from "@/store/modules/constructor2/fonts/consts";
import syncComponents from "@/store/modules/constructor2/components/utils/syncComponents";
import LTT from 'list-to-tree'
import {showToastError} from "@/services/Helpers/HelperToast";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

export const SPECIAL_COMPONENTS_STRING = '!$&'

const decorateComponents = (locale, template, page) =>
  `${locale}${SPECIAL_COMPONENTS_STRING}${template}${SPECIAL_COMPONENTS_STRING}${page}`

const addToUpdatedComponents = (state, updatedComponent) => {
  if (!state.updatedComponents.includes(updatedComponent))
    state.updatedComponents.push(updatedComponent)
}

const resetUpdatedComponents = (state) => {
  state.updatedComponents = []
}

const deriveValueFromProp = (valueParam, size) => {
  if (typeof valueParam === 'string') {
    return valueParam
  } else if (typeof valueParam === 'object') {
    return valueParam[size]
  }
  return undefined
}

const fixChildrenIds = (component, currentComponents, resetOrders = false) => {
  if (component.children?.length) {
    const children = component.children.slice()
    delete component.children
    children.forEach((child, index) => {
      child.parentId = component.id
      child.id = currentComponents.reduce((prev, cur) => prev < cur.id ? cur.id : prev, 0) + 1
      if (resetOrders) child.order = index + 1
      currentComponents.push(child)
    })
    children.forEach(child => {
      fixChildrenIds(child, currentComponents, resetOrders)
    })
  }
}

const getComponentNestedChildrenIds = (cid, components, idsToRemove, childrenNamesToLookFor = null) => {
  let children = components.filter(cc => cc.parentId === cid)
  children.forEach(c => {
    if (components.some(cc => cc.parentId === c.id)) {
      getComponentNestedChildrenIds(c.id, components, idsToRemove, childrenNamesToLookFor)
    }
  })
  if (childrenNamesToLookFor instanceof Array && childrenNamesToLookFor.length)
    idsToRemove.push(...children.filter(c => childrenNamesToLookFor.includes(c.name)).map(c => c.id))
  else
    idsToRemove.push(...children.map(c => c.id))
}

function compareByOrder(vector) {
  return (a, b) => {
    const aOrder = Number(a.get('order'));
    const bOrder = Number(b.get('order'));
    if (aOrder > bOrder) {
      return vector ? 1 : -1;
    } else if (aOrder < bOrder) {
      return vector ? -1 : 1;
    } else {
      return 0
    }
  }
}

const constructNestedComponents = (allComponents = []) => {
  const ltt = new LTT(allComponents, {
    key_id: 'id',
    key_parent: 'parentId',
    key_child: 'children'
  });
  ltt.sort(compareByOrder(true));
  return ltt.GetTree()
}

const normalizeParentIds = (components = []) =>
  components.map((component, index) => ({...component, parentId: component.parentId || 0, index}))

const parseNestedComponents = (nestedComponents = [], innerContext = {
  dynamicProviderIndex: undefined,
  radioGroupIndex: undefined,
}) => {
  let currentDynamicProviderIndex = innerContext.dynamicProviderIndex
  let currentRadioGroupIndex = innerContext.radioGroupIndex
  let currentChildren
  for (let i = 0; i < nestedComponents.length; i++) {
    if (nestedComponents[i].children?.length) {
      if (DYNAMIC_DATA_COMPONENT_KEYS_OBJ[nestedComponents[i].name]) {
        currentChildren = nestedComponents[i].children
        const [deletedDynamicComponent] = nestedComponents.splice(i, 1, ...currentChildren)
        if (deletedDynamicComponent.name === 'DynamicDataProvider') {
          currentDynamicProviderIndex = deletedDynamicComponent.index
        }
        currentChildren.forEach(childComponent => {
          childComponent.parentId = deletedDynamicComponent.parentId
          childComponent.isDynamic = true
          childComponent.dynamicType = deletedDynamicComponent.name
          childComponent.dynamicIndex = deletedDynamicComponent.index
          childComponent.dynamicProviderIndex = currentDynamicProviderIndex
        })
      }
      if (nestedComponents[i].componentKey === 'FormRadioGroup') {
        currentChildren = nestedComponents[i].children
        currentRadioGroupIndex = nestedComponents[i].index
        currentChildren.forEach(childComponent => {
          childComponent.radioGroupIndex = currentRadioGroupIndex
        })
      }
      parseNestedComponents(nestedComponents[i].children, {
        dynamicProviderIndex: currentDynamicProviderIndex,
        radioGroupIndex: currentRadioGroupIndex,
      })
    }
  }
  return nestedComponents
}

const setEpackDataComponents = (state, payload) => {
  state.epackData.components = payload
  onEpackDataUpdate(state)
}

const updateComponentProp = (state, payload) => {
  const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]
  const componentInEpack = components.find(c => c.id === payload.component.id)
  payload.component.props[payload.prop.key] = payload.updatedProp
  componentInEpack.props[payload.prop.key] = payload.updatedProp
  if (['fontFamily', 'fontStyle', 'fontWeight'].includes(payload.prop.key)) {
    // go through all components and get all usedFonts
    const usedFonts = []
    components.forEach(c => {
      // value can be undefined | string | object with sizes
      if (c.props && c.props.fontFamily && c.props.fontStyle && c.props.fontWeight) {
        AVAILABLE_SIZES_LIST.forEach(({slug: size}) => {
          const fontFamily = deriveValueFromProp(c.props.fontFamily.value, size)
          const fontStyle = deriveValueFromProp(c.props.fontStyle.value, size)
          const fontWeight = deriveValueFromProp(c.props.fontWeight.value, size)
          if (fontFamily && fontWeight && fontStyle
            && !usedFonts.find(f => f.fontFamily === fontFamily && f.fontStyle === fontStyle && f.fontWeight === fontWeight)
            && !DEFAULT_FONTS.find(f => f.fontFamily === fontFamily)) {
            usedFonts.push({
              fontFamily,
              fontStyle,
              fontWeight
            })
          }
        })
      }
    })
    state.epackData.usedFonts = usedFonts
    // fix reactivity issues
    state.epackData.usedFonts.push({})
    state.epackData.usedFonts.pop()
  }
  onEpackDataUpdate(state)
}

const setActiveComponent = (state, { component, dynamicOrder = null }) => {
  state.activeComponent = component
  state.activeComponentDynamicOrder = dynamicOrder || null
}

const constructNestedComponentsForComponent = (component, components) => {
  const children = []
  const restComponents = []
  components.forEach(c => {
    if (c.parentId === component.id) children.push(c)
    else restComponents.push(c)
  })
  if (children.length) {
    component.children = children
    component.children.forEach(child => {
      constructNestedComponentsForComponent(child, restComponents)
    })
  }
  return component
}

const state = {
  activeComponent: null,
  activeComponentDynamicOrder: null,
  loadedComponents: [],
  updatedComponents: []
}

const mutations = {
  setActiveComponent(state, { component, dynamicOrder = null }) {
    setActiveComponent(state, { component, dynamicOrder })
  },

  setEpackDataComponents (state, payload) {
    setEpackDataComponents(state, payload)
  },

  saveCustomComponent (state, rawComponent) {
    if (state.epackData.customComponents.find(cc => cc.customName === rawComponent.customName)) {
      throw 'already-exists'
    }
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]
    const rawComponentParent = rawComponent.parentId ? components.find(c => c.id === rawComponent.parentId) : undefined
    let component
    if (rawComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[rawComponentParent.name]) {
      component = dcopy(rawComponentParent)
      component.customName = rawComponent.customName
    }
    else component = dcopy(rawComponent)
    constructNestedComponentsForComponent(component, dcopy(components))
    if (DYNAMIC_DATA_COMPONENT_KEYS_OBJ[component.name] && component.children?.length) {
      component.children[0].customName = rawComponent.customName
    }
    state.epackData.customComponents.push(component)
    onEpackDataUpdate(state)
  },

  removeCustomComponent (state, customName) {
    const componentIndex = state.epackData?.customComponents?.findIndex(c => c.customName === customName)
    if (componentIndex >= 0) {
      state.epackData.customComponents.splice(componentIndex, 1)
      onEpackDataUpdate(state)
    }
  },

  addToLoadedComponents (state, loadedComponents) {
    const decoratedComponents = decorateComponents(loadedComponents.lang, loadedComponents.template, loadedComponents.page)
    if (!state.loadedComponents.find(c => c === decoratedComponents)) {
      state.loadedComponents.push(decoratedComponents)
    }
    if (!!loadedComponents.components) {
      if (state.epackData.components instanceof Array) state.epackData.components = {}
      state.epackData.components[loadedComponents.lang] = {
        ...(state.epackData.components[loadedComponents.lang] || {}),
        [loadedComponents.template]: {
          ...(state.epackData.components[loadedComponents.lang]?.[loadedComponents.template] || {}),
          [loadedComponents.page]: loadedComponents.components
        }
      }
    }
  },

  resetUpdatedComponents (state) {
    resetUpdatedComponents(state)
  },

  updateActiveComponentProp (state, payload) {
    updateComponentProp(state, {...payload, component: state.activeComponent})
  },

  updateComponentProp (state, payload) {
    updateComponentProp(state, payload)
  },

  addComponent (state, {
    customName,
    componentKey,
    isCustom = false,
    insideActive = false,
    makeActive = true,
    additionalProps,
  }) {
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]

    let componentToAdd = isCustom ? state.epackData.customComponents?.find(c => c.customName === customName) : ALL_ELEMENTS[componentKey]
    if (!componentToAdd) {
      console.error(`ERROR in addComponent: name doesn\'t match any existing components (${customName || componentKey})`)
      return
    }
    componentToAdd = dcopy(componentToAdd)
    componentToAdd.id = components.reduce((prev, cur) => prev < cur.id ? cur.id : prev, 0) + 1
    if (additionalProps) {
      componentToAdd.props = {
        ...componentToAdd.props,
        ...additionalProps
      }
    }
    if (state.activeComponent) {
      if (insideActive === true) {
        componentToAdd.parentId = state.activeComponent.id
        componentToAdd.order = components.filter(c => c.parentId === state.activeComponent.id)
          .reduce((prev, cur) => prev < cur.order ? cur.order : prev, 0) + 1
      } else {
        // it's possible to meet a case when the parent of the activeComponent is a Dynamic Data component
        // in that case we need to get the parent and the order of that Dynamic Data component instead of the activeComponent
        let activeComponentParent = components.find(c => c.id === state.activeComponent.parentId)
        let componentToAddAfter = state.activeComponent
        if (activeComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[activeComponentParent.name]) {
          componentToAddAfter = activeComponentParent
        }
        if (componentToAddAfter) {
          componentToAdd.parentId = componentToAddAfter.parentId
          componentToAdd.order = componentToAddAfter.order + 1
          components.filter(component => (
            (componentToAdd.parentId === 0 && component.parentId === 0) || (component.parentId === (componentToAdd.parentId || null))
          ) && component.order >= componentToAdd.order).forEach(component => {
            component.order++
          })
        } else {
          // same as if the activeComponent didn't exist
          componentToAdd.parentId = 0
          componentToAdd.order = components.filter(c => !c.parentId)
            .reduce((prev, cur) => prev < cur.order ? cur.order : prev, 0) + 1
        }
      }
    } else {
      componentToAdd.parentId = 0
      componentToAdd.order = components.filter(c => !c.parentId)
        .reduce((prev, cur) => prev < cur.order ? cur.order : prev, 0) + 1
    }
    components.push(componentToAdd)
    const dynamicComponentToAddChild =
      DYNAMIC_DATA_COMPONENT_KEYS_OBJ[componentToAdd.name] ? componentToAdd.children[0] : undefined
    fixChildrenIds(componentToAdd, components, true)
    if (makeActive) {
      state.activeComponent = dynamicComponentToAddChild || componentToAdd
    }
    onEpackDataUpdate(state)
  },

  removeComponent (state, component) {
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]

    const idsToRemove = [component.isDynamic ? components[component.dynamicIndex].id : component.id]
    if (component.isDynamic) {
      getComponentNestedChildrenIds(components[component.dynamicIndex].id, components, idsToRemove)
    } else {
      getComponentNestedChildrenIds(component.id, components, idsToRemove)
    }

    idsToRemove.forEach(id => {
      components.splice(components.findIndex(c => c.id === id), 1)
    })

    state.activeComponent = null
    onEpackDataUpdate(state)
  },

  // newIndex is the index after the parent lost the dragged component
  reorderComponent (state, { draggedComponent, desiredParentComponent, newIndex }) {
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]
    const desiredParentComponentId = desiredParentComponent.id || 0
    let draggedComponentInEpack
    let draggedComponentParent = !!draggedComponent.dynamicIndex ? components[draggedComponent.dynamicIndex] : undefined
    if (draggedComponentParent && DYNAMIC_DATA_COMPONENT_KEYS_OBJ[draggedComponentParent.name]) {
      draggedComponentInEpack = draggedComponentParent
    }
    const desiredParentChildren = components.filter(component => {
      if (component.id === draggedComponent.id) {
        if (!draggedComponentInEpack) draggedComponentInEpack = component
        return false
      }
      if (draggedComponentInEpack && component.id === draggedComponentInEpack.id) return false
      return (component.parentId === (desiredParentComponentId || null)) || (component.parentId === 0 && desiredParentComponentId === 0)
    })
    draggedComponentInEpack.parentId = desiredParentComponentId
    desiredParentChildren.sort((a, b) => b.order < a.order ? 1 : -1)
    desiredParentChildren.splice(newIndex, 0, draggedComponentInEpack)
    desiredParentChildren.forEach((child, index) => {
      child.order = index + 1
    })
    onEpackDataUpdate(state)
  },

  makeComponentDynamic (state, { componentId, dynamicName }) {
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]

    let componentToAdd = DYNAMIC_DATA_COMPONENTS[dynamicName]
    if (!componentToAdd) {
      console.error(`ERROR in addComponent: dynamicName doesn\'t match any existing components (${dynamicName})`)
      return
    }
    componentToAdd = dcopy(componentToAdd)
    const componentInEpack = components.find(c => c.id === componentId)
    componentToAdd.name = dynamicName
    componentToAdd.id = components.reduce((prev, cur) => prev < cur.id ? cur.id : prev, 0) + 1
    componentToAdd.parentId = componentInEpack.parentId
    componentToAdd.order = componentInEpack.order
    componentInEpack.parentId = componentToAdd.id
    componentInEpack.order = 1
    components.push(componentToAdd)
    state.activeComponent = componentInEpack
    onEpackDataUpdate(state)
  },

  removeDynamicDataComponent (state, dynamicId) {
    const components = state.epackData.components[state.activeLocale][state.activeTemplate][state.activePage]
    let dynamicComponent, dynamicComponentChild
    components.forEach(component => {
      if (component.id === dynamicId) dynamicComponent = component
      else if (component.parentId === dynamicId) dynamicComponentChild = component
    })
    const idsToRemove = [dynamicId]
    getComponentNestedChildrenIds(dynamicId, components, idsToRemove, ['DynamicDataReceiver'])
    idsToRemove.forEach(id => {
      const nestedChildIndex = components.findIndex(c => c.id === id)
      const nestedChildsChild = components.find(c => c.parentId === components[nestedChildIndex].id)
      nestedChildsChild.parentId = components[nestedChildIndex].parentId
      nestedChildsChild.order = components[nestedChildIndex].order
      components.splice(nestedChildIndex, 1)
    })
    dynamicComponentChild.parentId = dynamicComponent.parentId
    dynamicComponentChild.order = dynamicComponent.order
    state.activeComponent = dynamicComponentChild
    onEpackDataUpdate(state)
  },
}

const actions = {
  async loadComponents ({ commit, state }, { force, epackId, lang, template, page }) {
    if (force || (epackId
      && !state.loadedComponents.includes(decorateComponents(lang, template, page))
      && !!state.epackData.components[lang]?.[template]?.[page])) {
      await axios.get(`${BASE_URL}/api/constructor/epackages/${epackId}/components?lang=${lang}&template=${template}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      })
        .then(res => {
          commit('addToLoadedComponents', {
            lang,
            template,
            page,
            components: syncComponents(res.data?.data?.components || [])//.slice(0, 100)
          });
        })
        .catch(e => {
          if (e.response.status === 404) {
            commit('addToLoadedComponents', {
              lang,
              template,
              page,
              components: []
            });
          } else {
            console.error(e)
          }
        })
    } else if (!state.epackData.components[lang]?.[template]?.[page]) {
      commit('addToLoadedComponents', {
        lang,
        template,
        page,
        components: []
      });
    }
  },

  async syncComponents ({state, commit, dispatch}, payload) { // { epackIdFrom, epackId, lang, template, page }
    return new Promise((resolve, reject) => {
      axios.post(`${BASE_URL}/api/constructor/epackages/${
        payload.epackIdFrom || payload.epackId}/${payload.lang}/${payload.template}/${payload.page}/copy`, {
        toId: payload.epackId,
        toLang: state.activeLocale,
        toTemplate: state.activeTemplate,
        toPage: state.activePage
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      })
        .then(async () => {
          await dispatch('loadComponents', {
            epackId: payload.epackId,
            lang: state.activeLocale,
            template: state.activeTemplate,
            page: state.activePage,
            force: true
          })
          resolve(true)
        })
        .catch(e => {
          showToastError(
            e.response.data?.error || "Couldn't sync. Please try again later.",
            this._vm.$toast,
          );
          reject(e)
        })
    })
  },
}

const getters = {
  addElements () {
    return Object.entries(COMMON_ELEMENTS).map(([name, component]) => ({
      name,
      ...component
    }))
  },

  nestedComponents (state, getters) {
    return parseNestedComponents(
      constructNestedComponents(
        normalizeParentIds(getters.addedComponents)
      )
    )
  },

  addedComponents (state) {
    return state.epackData?.components?.[state.activeLocale]?.[state.activeTemplate]?.[state.activePage] || []
  },

  customComponents (state) {
    return state.epackData?.customComponents || []
  }
}

export {
  decorateComponents,
  addToUpdatedComponents,
  fixChildrenIds,
  getComponentNestedChildrenIds,
  setActiveComponent
}

export default {
  state,
  mutations,
  actions,
  getters,
}

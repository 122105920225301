const config = state => state.app.config;
const palette = state => state.app.config.palette;
const isLoading = state => state.app.isLoading;
const authError = state => state.authError;
const users = state => state.users;
const brands = state => state.brands;
const retailers = state => state.retailers;
const user = state => state.user;
const retailerStats = state => state.retailerStats;
const mapStats = state => state.mapStats;
const perTimeStats = state => state.perTimeStats;
const perBrandStats = state => state.perBrandStats;
const retailerPieStats = state => state.retailerPieStats;
const topPopularProductsStats = state => state.topPopularProductsStats;
const userStory = state => state.userStory;

export {
  config,
  palette,
  isLoading,
  authError,
  users,
  brands,
  retailers,
  user,
  retailerStats,
  mapStats,
  perTimeStats,
  perBrandStats,
  retailerPieStats,
  topPopularProductsStats,
  userStory,
};

import Vue from 'vue';
import Vuex from 'vuex';
import VuexI18n from 'vuex-i18n'; // load vuex i18n module
import app from './modules/app';
import constructorData from './modules/constructor';
import constructor2 from './modules/constructor2';

import * as getters from './getters';
import router from '../router/index';
import axios from 'axios';
import API from '../services/API/API';
import APIConstructor from '../services/API/APIConstructor';
import {getUniqArrayOfObjects} from "../services/Helpers/HelperObject";
import {showToastError} from "@/services/Helpers/HelperToast.js";

Vue.use(Vuex);

// const setUserLicenses = (uid, licenses) => {
//   return new Promise(resolve => {
//     axios.post(`${process.env.VUE_APP_API_URL}/users/${uid}/licenses/add`, {ids: licenses}, {
//       headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
//     })
//       .then(() => {
//         resolve();
//       })
//       .catch(() => {
//         return Promise.reject(new Error('Error setup licenses'));
//       });
//   });
// };
//
// const setUserRetailers = (uid, retailers) => {
//   return new Promise(resolve => {
//     axios.post(`${process.env.VUE_APP_API_URL}/users/${uid}/retailers/add`, {ids: retailers}, {
//       headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
//     })
//       .then(() => {
//         resolve();
//       })
//       .catch(() => {
//         return Promise.reject(new Error('Error setup retailers'));
//       });
//   });
// };
//
// const deleteUserRetailers = (uid, retailers) => {
//   return new Promise(resolve => {
//     axios.put(`${process.env.VUE_APP_API_URL}/users/${uid}/retailers/remove`, {ids: retailers}, {
//       headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
//     })
//       .then(() => {
//         resolve();
//       })
//       .catch(() => {
//         return Promise.reject(new Error('Error setup retailers'));
//       });
//   });
// };
//
// const deleteUserLicenses = (uid, licenses) => {
//   return new Promise(resolve => {
//     axios.put(`${process.env.VUE_APP_API_URL}/users/${uid}/licenses/remove`, {ids: licenses}, {
//       headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
//     })
//       .then(() => {
//         resolve();
//       })
//       .catch(() => {
//         return Promise.reject(new Error('Error setup licenses'));
//       });
//   });
// };

const setGroupLicenses = (uid, licenses) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-licenses`, {ids: licenses}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup licenses'));
      });
  });
};

const setGroupBrands = (uid, brands) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-brands`, {ids: brands}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup brands'));
      });
  });
};

const setGroupRetailers = (uid, retailers) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-retailers`, {ids: retailers}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup retailers'));
      });
  });
};

const deleteGroupRetailers = (uid, retailers) => {
  return new Promise(resolve => {
    axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/remove-retailers`, {ids: retailers}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup retailers'));
      });
  });
};

const setGroupUsers = (uid, users) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-users`, {ids: users}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup retailers'));
      });
  });
};

const setGroupPermissions = (uid, permissions) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-permissions`, {ids: permissions}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup permission'));
      });
  });
};

const deleteGroupUsers = (uid, users) => {
  return new Promise(resolve => {
    axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/remove-users`, {ids: users}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup retailers'));
      });
  });
};

const deleteGroupLicenses = (uid, licenses) => {
  return new Promise(resolve => {
    axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/remove-licenses`, {ids: licenses}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup licenses'));
      });
  });
};

const deleteGroupBrands = (uid, brands) => {
  return new Promise(resolve => {
    axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/remove-brands`, {ids: brands}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup brands'));
      });
  });
};

const deleteGroupPermissions = (uid, permissions) => {
  return new Promise(resolve => {
    axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/remove-permissions`, {ids: permissions}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup permissions'));
      });
  });
};

const setGroupCategories = (uid, categories) => {
  return new Promise(resolve => {
    axios.post(`${process.env.VUE_APP_API_URL}/statistics-group/${uid}/add-categories`, {categoryIds: categories}, {
      headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        return Promise.reject(new Error('Error setup categories'))
      })
  })
}

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
    constructorData,
    constructor2,
  },
  state: {
    accessToken: null,

    loggingIn: false,
    authError: null,
    user: null,
    users: [],
    retailerStats: {
      labels: ['all'],
      datasets: [],
    },
    mapStats: [],
    perTimeStats: {},
    perLicenseStats: {},
    retailerPieStats: {},
    topPopularProductsStats: {},
    userStory: {},
    contentManagerLicenses: [],
    retailerManagerLicenses: [],
    retailerEpackages: {},
    retailerReasonData: {},
    retailerGroups: null,
    retailers: null,
    licenses: null,

    contentManagerUserGroups: [],
    contentManagerUserGroupsStatistics: [],

    currentUserProfile: null,
    currentUserProfilePermissions: [],
    categories: [],
    initialCategories: [],

    amazonRedirectData: {
      amazonCallbackUri: null,
      amazonState: null,
      sellingPartnerId: null
    }
  },
  mutations: {
    SET_CONTENT_MANAGER_DATA: (state, payload) => state.contentManagerLicenses = payload,
    SET_RETAIL_MANAGER_DATA: (state, payload) => state.retailerManagerLicenses = payload,
    SET_RETAIL_EPACKAGES: (state, payload) => state.retailerEpackages = payload,

    SET_RETAILER_GROUPS: (state, payload) => state.retailerGroups = payload,
    SET_RETAILERS: (state, payload) => state.retailers = payload,
    SET_LICENSES: (state, payload) => state.licenses = payload,
    SET_CATEGORIES: (state, payload) => state.initialCategories = payload,
    clearPermissions(state) {
      state.currentUserProfilePermissions = []
    },
    setContentManagerUserGroups: (state, payload) => {
      state.contentManagerUserGroups = payload;
    },

    setContentManagerUserGroupsStatistics: (state, payload) => {
      state.contentManagerUserGroupsStatistics = payload;
    },

    removeRetailManagerLicenseReasonById(state, {id, retailerId}) {
      const index = state.retailerEpackages[retailerId]?.findIndex(i => i.id === id)
      if (index !== -1)
        state.retailerEpackages[retailerId].splice(index, 1)
    },

    loginStart: state => {
      state.loggingIn = true;
    },

    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.authError = errorMessage;
    },

    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },

    logout: (state) => {
      state.accessToken = null;
    },

    setUsers: (state, users) => {
      state.users = users;
    },

    removeUser: (state, id) => {
      state.users = state.users.filter(user => user.id !== id);
    },

    addUser: (state, user) => {
      state.users.push(user);
    },

    setUser: (state, user) => {
      state.user = user;
    },

    setCurrentUserProfile(state, payload) {
      state.currentUserProfile = payload
    },

    setPermissions(state, payload) {
      state.currentUserProfilePermissions = payload;
    },

    setCategories(state, payload) {
      state.categories = payload;
    },
    setAmazonRedirectData(state, payload) {
      state.amazonRedirectData = payload
    }
  },
  actions: {
    async getUserProfile({commit}) {
      return new Promise((resolve) => {
        if (window.localStorage.getItem('accessToken')) {
          axios.get(`${process.env.VUE_APP_API_URL}/profile`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
            }
          }).then((user) => {
            commit('setCurrentUserProfile', user.data)
            resolve(user.data)
          }).catch()
        }
        resolve(null)
      })
    },

    async getCategories({commit}) {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/categories`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      })
      if (res.data) commit('setCategories', res.data.data);
    },

    async getPermissions({commit, state}) {
      let permissions = []
      if (state.currentUserProfile) {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/profile/statistics-groups`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
          },
        })
        if (res.data?.data) {
          let retailerGroups = []
          let retailers = []
          let licenses = []
          res.data?.data?.forEach(group => {
            group.retailerGroups?.forEach(p => {
              if (p.id !== undefined)
                retailerGroups.push(p)
            })
            group.retailers?.forEach(p => {
              if (p.id !== undefined)
                retailers.push(p)
            })
            group.licenses?.forEach(p => {
              if (p.id !== undefined)
                licenses.push(p)
            })
            group.permissions?.forEach(p => {
              if (p.id !== undefined)
                permissions.push(p)
            })
          })
          commit('SET_RETAILER_GROUPS', [...new Set(retailerGroups)]);
          commit('SET_RETAILERS', [...new Set(retailers)])
          commit('SET_LICENSES', [...new Set(licenses)])
          commit('setPermissions', [...new Set(permissions)])
        }

        if (!(state.licenses?.length && state.retailers?.length)) {
          const user = JSON.parse(localStorage.getItem("ttlUserInfo"))
          commit('SET_RETAILERS', user?.retailers)
          commit('SET_LICENSES', user?.licenses)
        }
      }
      return [...new Set(permissions)]
    },

    async getRetailManagerLicenses({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/retailers/all`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
          },
        }).then(async response => {
          const retailManagerData = response.data.data.slice().filter(b => b.quantityReasonedEpackageRetailers > 0).sort((a, b) => b.quantityReasonedEpackageRetailers - a.quantityReasonedEpackageRetailers)
          const retailEpackages = {};
          for (let i = 0; i < retailManagerData.length; i++) {
            await axios.get(`${process.env.VUE_APP_API_URL}/epackage/${retailManagerData[i].id}/reasoned`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
              },
            }).then(res => {
              if (!retailManagerData[i].reason) retailManagerData[i].reason = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}
              retailEpackages[retailManagerData[i].id] = res.data.data;
              retailEpackages[retailManagerData[i].id].forEach(re => {
                re.checked = false
              })
              res.data.data.forEach(p => {
                retailManagerData[i].reason[p.reason]++;
              })
            })
          }
          commit('SET_RETAIL_MANAGER_DATA', retailManagerData);
          commit('SET_RETAIL_EPACKAGES', retailEpackages);
          resolve(true)
        }).catch(error => {
          console.log(error);
          reject(error)
        });
      })
    },

    async getContentManagerLicenses({commit}) {
      await axios.get(`${process.env.VUE_APP_API_URL}/licenses`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('SET_CONTENT_MANAGER_DATA', response.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    async getContentManagerUserGroups({commit}) {
      await axios.get(`${process.env.VUE_APP_API_URL}/statistics-group`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('setContentManagerUserGroups', response.data.data.filter(elem => !elem.permissions.find(perm => perm.name === "Disable_checking")));
      }).catch(error => {
        console.log(error);
      });
    },

    async getContentManagerUserGroupsStatistics({commit, state}) {
      await axios.get(`${process.env.VUE_APP_API_URL}/statistics-group/statistics`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('setContentManagerUserGroupsStatistics', response.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    async getLicenseData({}, licenseId) {
      return await axios.get(`${process.env.VUE_APP_API_URL}/license/${licenseId}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      });
    },

    async getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setPendingUser', true);
        API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (user) => {
          commit('setCurrentUserProfile', user);
          let APIlink = user.roles.includes('ROLE_ADMIN') ? "/statistics-group" : "/profile/statistics-groups";
          if (user.roles.includes('ROLE_ADMIN')) {
            API.APIGet(`${process.env.VUE_APP_API_URL}/retailers/all`, {}, retailers => {
              API.APIGet(`${process.env.VUE_APP_API_URL}/licenses/short-list`, {}, licenses => {
                API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, users => {

                  retailers = retailers.map(({id, name}) => {
                    return {id, name};
                  });

                  user.licenses = licenses;
                  user.retailers = retailers;
                  user.users = users;
                  localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                  commit('loginStop', null);
                  window.location.href = '/panel/dashboard';
                });
              });
            });

          } else {
            API.APIGet(`${process.env.VUE_APP_API_URL}${APIlink}`, {}, groups => {
              let licenses = [];
              let retailers = [];
              let users = [];
              groups.forEach(group => {
                licenses = getUniqArrayOfObjects(group.licenses, licenses);
                retailers = getUniqArrayOfObjects(group.retailers, retailers);
                users = getUniqArrayOfObjects(group.users, users);
              });
              user.licenses = licenses;
              user.retailers = retailers;
              user.users = users;
              localStorage.setItem('ttlUserInfo', JSON.stringify(user));
              commit('loginStop', null);
              window.location.href = '/panel/dashboard';
            });
          }
          resolve(true)
        }, (e) => reject(e));
      })
    },

    async refreshToken({commit}) {
      return new Promise((resolve, reject) => {
        const refreshToken = localStorage.getItem('refresh-token');
        window.isPendingRefreshToken = true;

        axios.post(`${process.env.VUE_APP_API_URL}/auth/refresh`, {
          refresh_token: refreshToken,
        })
          .then((res) => {
            localStorage.setItem('accessToken', res.data.token);
            localStorage.setItem('refresh-token', res.data.refresh_token);
            window.isPendingRefreshToken = false;
            resolve(res.data.token);
          })
          .catch((e) => {
            window.isPendingRefreshToken = false;
            reject(e)
          })
      })
    },

    doLogin({commit}, loginData) {
      commit('loginStart');

      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/auth/getToken`, {
          ...loginData,
        })
          .then(response => {
            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('refresh-token', response.data.refresh_token);
            commit('updateAccessToken', response.data.token);
            APIConstructor.APIPost(
              `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/login_check`,
              {...loginData}, response => {
                if (response.token) {
                  localStorage.setItem('constructorAccessToken', response.token);
                }
                API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (user) => {
                  commit('setCurrentUserProfile', user);
                  let APIlink = user.roles.includes('ROLE_ADMIN') ? "/statistics-group" : "/profile/statistics-groups";
                  if (user.roles.includes('ROLE_ADMIN')) {
                    API.APIGet(`${process.env.VUE_APP_API_URL}/retailers/all`, {}, retailers => {
                      API.APIGet(`${process.env.VUE_APP_API_URL}/licenses/short-list`, {}, licenses => {
                        API.APIGet(`${process.env.VUE_APP_API_URL}/brands/short-list`, {}, brands => {

                          API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, users => {

                            retailers = retailers.map(({id, name}) => {
                              return {id, name};
                            });

                            user.brands = brands;
                            user.licenses = licenses;
                            user.retailers = retailers;
                            user.users = users;
                            localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                            commit('loginStop', null);
                            window.location.href = '/panel/dashboard';
                          });
                        });
                      });
                    });

                  } else {
                    console.log('not adm')
                    API.APIGet(`${process.env.VUE_APP_API_URL}${APIlink}`, {}, groups => {
                      let licenses = [];
                      let retailers = [];
                      let users = [];
                      let uniQbrands = [];
                      groups.forEach(group => {
                        licenses = getUniqArrayOfObjects(group.licenses, licenses);
                        retailers = getUniqArrayOfObjects(group.retailers, retailers);
                        users = getUniqArrayOfObjects(group.users, users);
                      });
                      const brands = licenses.map(license => license.brand);
                      uniQbrands = getUniqArrayOfObjects(brands,uniQbrands);
                      user.brands = uniQbrands;
                      user.licenses = licenses;
                      user.retailers = retailers;
                      user.users = users;
                      localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                      commit('loginStop', null);
                      window.location.href = '/panel/dashboard';
                    });
                  }

                });
              }, () => {
                API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (user) => {
                  commit('setCurrentUserProfile', user);
                  let APIlink = user.roles.includes('ROLE_ADMIN') ? "/statistics-group" : "/profile/statistics-groups";
                  if (user.roles.includes('ROLE_ADMIN')) {
                    API.APIGet(`${process.env.VUE_APP_API_URL}/retailers/all`, {}, retailers => {
                      API.APIGet(`${process.env.VUE_APP_API_URL}/licenses/short-list`, {}, licenses => {
                        API.APIGet(`${process.env.VUE_APP_API_URL}/brands/short-list`, {}, brands => {
                          API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, users => {
                            retailers = retailers.map(({id, name}) => {
                              return {id, name};
                            });
                            user.brands = brands;
                            user.licenses = licenses;
                            user.retailers = retailers;
                            user.users = users;
                            localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                            commit('loginStop', null);
                            window.location.href = '/panel/dashboard';
                          });
                        });
                      });
                    });

                  } else {
                    API.APIGet(`${process.env.VUE_APP_API_URL}${APIlink}`, {}, groups => {
                      let licenses = [];
                      let retailers = [];
                      let users = [];
                      let uniQbrands = [];
                      groups.forEach(group => {
                        licenses = getUniqArrayOfObjects(group.licenses, licenses);
                        retailers = getUniqArrayOfObjects(group.retailers, retailers);
                        users = getUniqArrayOfObjects(group.users, users);
                      });
                      const brands = licenses.map(license => license.brand);
                      uniQbrands = getUniqArrayOfObjects(brands,uniQbrands);
                      user.brands = uniQbrands;
                      user.licenses = licenses;
                      user.retailers = retailers;
                      user.users = users;
                      localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                      commit('loginStop', null);
                      window.location.href = '/panel/dashboard';
                    });
                  }
                });
              });
          })
          .catch(error => {
            commit('loginStop', 'Incorrect login or password');
            commit('updateAccessToken', null);
            reject(error);
          });

      });
    },

    doAmazonLogin({commit}, data) {
      commit('loginStart');
      const loginData = {
        username: data.username,
        password: data.password,
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/auth/getToken`, {
          ...loginData,
        })
          .then(response => {
            localStorage.setItem('accessToken', response.data.token);
            commit('updateAccessToken', response.data.token);
            APIConstructor.APIPost(
              `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/login_check`,
              {...loginData}, response => {
                if (response.token) {
                  localStorage.setItem('constructorAccessToken', response.token);
                }
                API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (user) => {
                  commit('setCurrentUserProfile', user);
                  let APIlink = user.roles.includes('ROLE_ADMIN') ? "/statistics-group" : "/profile/statistics-groups";
                  if (user.roles.includes('ROLE_ADMIN')) {
                    API.APIGet(`${process.env.VUE_APP_API_URL}/retailers/all`, {}, retailers => {
                      API.APIGet(`${process.env.VUE_APP_API_URL}/licenses/short-list`, {}, licenses => {
                        API.APIGet(`${process.env.VUE_APP_API_URL}/brands/short-list`, {}, brands => {
                          API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, users => {

                            retailers = retailers.map(({id, name}) => {
                              return {id, name};
                            });

                            user.brands = brands;
                            user.licenses = licenses;
                            user.retailers = retailers;
                            user.users = users;
                            localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                            commit('loginStop', null);
                            store.dispatch('getPermissions').then(res => {
                              const localPermissions = JSON.parse(JSON.stringify(res))
                              const localPermissionsIndex = localPermissions.findIndex(r => {
                                return r.name == 'Connect_to_amazon'
                              })
                              if (localPermissionsIndex > -1 || user.roles.includes('ROLE_ADMIN')) {
                                commit('setAmazonRedirectData', data.amazonRedirectData)
                                localStorage.removeItem('makeAmazonLogin');
                                router.push('/panel/amazon');
                              } else {
                                reject('Not permitted')
                              }
                            })
                          });
                        });
                      });
                    });

                  } else {
                    API.APIGet(`${process.env.VUE_APP_API_URL}${APIlink}`, {}, groups => {
                      let licenses = [];
                      let retailers = [];
                      let users = [];
                      let uniQbrands = [];
                      groups.forEach(group => {
                        licenses = getUniqArrayOfObjects(group.licenses, licenses);
                        retailers = getUniqArrayOfObjects(group.retailers, retailers);
                        users = getUniqArrayOfObjects(group.users, users);
                      });
                      const brands = licenses.map(license => license.brand);
                      uniQbrands = getUniqArrayOfObjects(brands,uniQbrands);
                      user.brands = uniQbrands;
                      user.licenses = licenses;
                      user.retailers = retailers;
                      user.users = users;
                      localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                      commit('loginStop', null);
                      store.dispatch('getPermissions').then(res => {
                        const localPermissions = JSON.parse(JSON.stringify(res))
                        const localPermissionsIndex = localPermissions.findIndex(r => {
                          return r.name == 'Connect_to_amazon'
                        })
                        if (localPermissionsIndex > -1 || user.roles.includes('ROLE_ADMIN')) {
                          commit('setAmazonRedirectData', data.amazonRedirectData)
                          localStorage.removeItem('makeAmazonLogin');
                          router.push('/panel/amazon');
                        } else {
                          reject('Not permitted')
                        }
                      })
                    });
                  }

                });
              }, () => {
                API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (user) => {
                  commit('setCurrentUserProfile', user);
                  let APIlink = user.roles.includes('ROLE_ADMIN') ? "/statistics-group" : "/profile/statistics-groups";
                  if (user.roles.includes('ROLE_ADMIN')) {
                    API.APIGet(`${process.env.VUE_APP_API_URL}/retailers/all`, {}, retailers => {
                      API.APIGet(`${process.env.VUE_APP_API_URL}/licenses/short-list`, {}, licenses => {
                        API.APIGet(`${process.env.VUE_APP_API_URL}/brands/short-list`, {}, brands => {
                          API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, users => {

                            retailers = retailers.map(({id, name}) => {
                              return {id, name};
                            });

                            user.brands = brands;
                            user.licenses = licenses;
                            user.retailers = retailers;
                            user.users = users;
                            localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                            commit('loginStop', null);
                            store.dispatch('getPermissions').then(res => {
                              const localPermissions = JSON.parse(JSON.stringify(res))
                              const localPermissionsIndex = localPermissions.findIndex(r => {
                                return r.name == 'Connect_to_amazon'
                              })
                              if (localPermissionsIndex > -1 || user.roles.includes('ROLE_ADMIN')) {
                                commit('setAmazonRedirectData', data.amazonRedirectData)
                                localStorage.removeItem('makeAmazonLogin');
                                router.push('/panel/amazon');
                              } else {
                                reject('Not permitted')
                              }
                            })
                          });
                        });
                      });
                    });

                  } else {
                    API.APIGet(`${process.env.VUE_APP_API_URL}${APIlink}`, {}, groups => {
                      let licenses = [];
                      let retailers = [];
                      let users = [];
                      let uniQbrands = [];
                      groups.forEach(group => {
                        licenses = getUniqArrayOfObjects(group.licenses, licenses);
                        retailers = getUniqArrayOfObjects(group.retailers, retailers);
                        users = getUniqArrayOfObjects(group.users, users);
                      });
                      const brands = licenses.map(license => license.brand);
                      uniQbrands = getUniqArrayOfObjects(brands,uniQbrands);
                      user.brands = uniQbrands;
                      user.licenses = licenses;
                      user.retailers = retailers;
                      user.users = users;
                      localStorage.setItem('ttlUserInfo', JSON.stringify(user));
                      commit('loginStop', null);
                      store.dispatch('getPermissions').then(res => {
                        const localPermissions = JSON.parse(JSON.stringify(res))
                        const localPermissionsIndex = localPermissions.findIndex(r => {
                          return r.name == 'Connect_to_amazon'
                        })
                        if (localPermissionsIndex > -1 || user.roles.includes('ROLE_ADMIN')) {
                          commit('setAmazonRedirectData', data.amazonRedirectData)
                          localStorage.removeItem('makeAmazonLogin');
                          router.push('/panel/amazon');
                        } else {
                          reject('Not permitted')
                        }
                      })

                    });
                  }
                });
              });
          })
          .catch(error => {
            commit('loginStop', 'Incorrect login or password');
            commit('updateAccessToken', null);
            reject(error);
          });

      });
    },

    fetchAccessToken({commit}) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },

    logout({commit}) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('constructorAccessToken');
      localStorage.removeItem('ttlUserInfo');
      localStorage.removeItem('refresh-token');
      commit('logout');
      window.location.href = '/auth/login';
    },

    getUsers({commit}) {
      axios.get(`${process.env.VUE_APP_API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('setUsers', response.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    getUser({commit}, uid) {
      axios.get(`${process.env.VUE_APP_API_URL}/users/${uid}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('setUser', response.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    deleteUser({commit}, id) {
      axios.delete(`${process.env.VUE_APP_API_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        commit('removeUser', id);
      }).catch(error => {
        console.log(error);
      });
    },

    async createUser({commit}, data) {
      await axios.post(`${process.env.VUE_APP_API_URL}/users`, data.user, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const user = response.data.data;
        commit('addUser', user);
        API.APIPost(`${process.env.VUE_APP_API_URL}/users/${user.id}/send-created-email`, {}, (data) => {
          router.push('/panel/users');
        });
      }).catch(error => {
        // sebchik.hockey@gmail.com
        throw error.response.data.error;
      });

    },

    async saveUser({commit}, data) {
      await axios.put(`${process.env.VUE_APP_API_URL}/users/${data.user.id}`, data.user, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const user = response.data.data;
        commit('addUser', user);
        router.push('/panel/users');
      }).catch(error => {
        throw error.response.data.error;
      });
    },

    async createGroup({commit}, data) {
      await axios.post(`${process.env.VUE_APP_API_URL}/statistics-group`, data.group, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const group = response.data.data;

        const promiseLicenses = setGroupLicenses(group.id, data.licenses);
        const promiseBrands = setGroupBrands(group.id, data.brands);
        const promiseRetailers = setGroupRetailers(group.id, data.retailers);
        const promiseUsers = setGroupUsers(group.id, data.users);
        const promisePermissions = setGroupPermissions(group.id, data.permissions);
        const promiseCategories = setGroupCategories(group.id, data.categories)

        Promise.all([promiseLicenses, promiseBrands, promiseRetailers, promiseUsers, promisePermissions, promiseCategories])
          .then(() => {
            router.push('/panel/groups');
          })
          .catch(error => {
            throw error.response.data.error;
          });

      }).catch(error => {
        throw error.response.data.error;
      });

    },

    async saveGroup({commit}, data) {
      await axios.put(`${process.env.VUE_APP_API_URL}/statistics-group/${data.group.id}`, data.group, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const user = response.data.data;

        setTimeout(() => {

          const promiseDeleteRetailers = deleteGroupRetailers(user.id, data.deleteRetailers);
          const promiseDeleteLicenses = deleteGroupLicenses(user.id, data.deleteLicenses);
          const promiseDeleteBrands = deleteGroupBrands(user.id, data.deleteBrands);
          const promiseDeleteUsers = deleteGroupUsers(user.id, data.deleteUsers);
          const promiseDeletePermissions = deleteGroupPermissions(user.id, data.deletePermissions);
          const promiseLicenses = setGroupLicenses(user.id, data.licenses);
          const promiseBrands = setGroupBrands(user.id, data.brands);
          const promiseRetailers = setGroupRetailers(user.id, data.retailers);
          const promiseUsers = setGroupUsers(user.id, data.users);
          const promisePermissions = setGroupPermissions(user.id, data.permissions);

          Promise.all([promiseLicenses, promiseBrands, promiseRetailers, promiseUsers, promisePermissions, promiseDeleteRetailers, promiseDeleteLicenses, promiseDeleteBrands, promiseDeleteUsers, promiseDeletePermissions])
            .then(() => {
              router.push('/panel/groups');
            })
            .catch(error => {
              throw error.response.data.error;
            });
        }, 100);
      }).catch(error => {
        throw error.response.data.error;
      });
    },
    async generateOzonFeed(_, uids) {
      return new Promise(resolve => {
        axios.post(`${process.env.VUE_APP_API_URL} /retailer-group/ozon-jsons-generate-by-skus`, {skuIds: uids}, {
          headers: {Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`},
        })
          .then(() => {
            resolve();
          })
          .catch(() => {
            return Promise.reject(new Error('Error setup categories'))
          })
      })
    }
  },

});

Vue.use(VuexI18n.plugin, store);

export default store;

export default {
  Title: {
    template: "master_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue: "Это пример заголовка",
      },
      {
        name: "color",
        nameText: "Text Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "fontFamily",
        nameText: "Font Fmily",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "fontWeight",
        nameText: "Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
    ],
  },
  Description: {
    template: "master_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue:
          "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
      },
      {
        name: "color",
        nameText: "Text Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "fontFamily",
        nameText: "Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "fontWeight",
        nameText: "Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
    ],
  },
  Disclaimer: {
    template: "master_template",
    props: [
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "fontSize",
        nameText: "Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "content",
        nameText: "Text",
        type: "text",
        defaultValue:
          "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
      },
      {
        name: "color",
        nameText: "Text Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "fontFamily",
        nameText: "Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "fontWeight",
        nameText: "Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
    ],
  },

  Spacer: {
    template: "master_template",
    props: [
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "size",
        nameText: "Height",
        type: "select",
        defaultValue: "medium",
        values: ["extra-small", "small", "medium", "large"],
      },
    ],
  },
  Picture: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
        recommendedWidth: 1200,
      },
      {
        name: "srcMob",
        nameText: "Mobile Image",
        type: "file",
        defaultValue: "",
        recommendedWidth: 640,
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
    ],
  },
  Video: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Video",
        type: "video",
        defaultValue: "/placeholders/video.mp4",
      },
      {
        name: "poster",
        nameText: "Poster",
        type: "file",
        defaultValue: "",
      },
      {
        name: "autoplay",
        nameText: "Autoplay",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "repeat",
        nameText: "Repeat",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "controls",
        nameText: "Controls",
        type: "select",
        defaultValue: "true",
        values: ["true", "false"],
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
    ],
  },
  Youtube: {
    template: "master_template",
    props: [
      {
        name: "youtubeID",
        nameText: "Youtube ID",
        type: "text",
        defaultValue: "Mt81r2LuVzo",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
    ],
  },
  Grid: {
    template: "master_template",
    props: [
      {
        name: "direction",
        nameText: "Direction",
        type: "select",
        defaultValue: "left",
        values: ["left", "right"],
      },
      {
        name: "imgColWidth",
        nameText: "Image Column Width",
        type: "select",
        defaultValue: "50%",
        values: ["25%", "50%", "75%"],
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleTextAlign",
        nameText: "Title Text Align",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleTextAlignMobile",
        nameText: "Title Text Align Mobile",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleContent",
        nameText: "Title",
        type: "text",
        defaultValue: "Это пример заголовка.",
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "descriptionTextAlign",
        nameText: "Description Text Align",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "descriptionTextAlignMobile",
        nameText: "Description Text Align Mobile",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionContent",
        nameText: "Description",
        type: "text",
        defaultValue:
          "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "customArrow",
        nameText: "Add svg-code of left arrow",
        type: "text",
        defaultValue:
          "",
      },
      {
        name: "arrowsColor",
        nameText: "Arrows Color (doesn't work for custom)",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "dotsColor",
        nameText: "Dots Color",
        type: "color",
        defaultValue: "#333333",
      },
      {
        name: "activeDotColor",
        nameText: "Active Dot Color",
        type: "color",
        defaultValue: "#16c7dc",
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/600x600.png",
                recommendedWidth: 600,
              },
              {
                name: "srcMob",
                nameText: "Mobile Image",
                type: "file",
                defaultValue: "",
                recommendedWidth: 600,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
            ],
          },
        ]
      },

    ],
  },
  Icons: {
    template: "master_template",
    props: [
      {
        name: "colsAlign",
        nameText: "Cols Align",
        type: "select",
        defaultValue: "flex-start",
        values: ["flex-start", "center", "flex-end", "space-between", "space-around"],
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/158x158.png",
                recommendedWidth: 158,
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/158x158.png",
                recommendedWidth: 158,
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/158x158.png",
                recommendedWidth: 158,
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Icon",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/158x158.png",
                recommendedWidth: 158,
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Slider: {
    template: "master_template",
    props: [
      {
        name: "customArrow",
        nameText: "Add svg-code of left arrow",
        type: "text",
        defaultValue:
          "",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "arrowsColor",
        nameText: "Arrows Color (doesn't work for custom)",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "dotsColor",
        nameText: "Dots Color",
        type: "color",
        defaultValue: "#cccccc",
      },
      {
        name: "activeDotColor",
        nameText: "Active Dot Color",
        type: "color",
        defaultValue: "#16c7dc",
      },
      {
        name: "contentPosition",
        nameText: "Content position",
        type: "select",
        defaultValue: "top",
        values: ["top", "bottom"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "6",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "disclaimerFontFamily",
        nameText: "Disclaimer Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "disclaimerFontWeight",
        nameText: "Disclaimer Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "disclaimerFontSize",
        nameText: "Disclaimer Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Slide",
            props: [
              {
                name: "alignItems",
                nameText: "Align Items",
                type: "select",
                defaultValue: "flex-start",
                values: ["flex-start", "center", "flex-end"],
              },
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/1200x600.png",
                recommendedWidth: 1200,
              },
              {
                name: "srcMob",
                nameText: "Mobile Image",
                type: "file",
                defaultValue: "",
                recommendedWidth: 768,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "poster",
                nameText: "Poster for video",
                type: "file",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleColor",
                nameText: "Title Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue: "",
              },
              {
                name: "descriptionColor",
                nameText: "Description Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue: "",
              },
              {
                name: "disclaimerColor",
                nameText: "Disclaimer Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "disclaimerContent",
                nameText: "Disclaimer",
                type: "text",
                defaultValue: "",
              },
              {
                name: "background",
                nameText: "Background Color",
                type: "color",
                defaultValue: "#ffffff",
              },
            ],
          },
        ],
      },
    ],
  },
  Col2: {
    template: "master_template",
    props: [
      {
        name: "colsAlign",
        nameText: "Cols Align",
        type: "select",
        defaultValue: "flex-start",
        values: ["flex-start", "center", "flex-end", "space-between", "space-around"],
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "imgWidth",
        nameText: "Img Width",
        type: "text",
        defaultValue: "",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/560x560.png",
                recommendedWidth: 560,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/560x560.png",
                recommendedWidth: 560,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Col3: {
    template: "master_template",
    props: [
      {
        name: "colsAlign",
        nameText: "Cols Align",
        type: "select",
        defaultValue: "flex-start",
        values: ["flex-start", "center", "flex-end", "space-between", "space-around"],
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "imgWidth",
        nameText: "Img Width",
        type: "text",
        defaultValue: "",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Col4: {
    template: "master_template",
    props: [
      {
        name: "colsAlign",
        nameText: "Cols Align",
        type: "select",
        defaultValue: "flex-start",
        values: ["flex-start", "center", "flex-end", "space-between", "space-around"],
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "textAlignMobile",
        nameText: "Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "imgWidth",
        nameText: "Img Width",
        type: "text",
        defaultValue: "",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
          {
            component: "Col",
            props: [
              {
                name: "src",
                nameText: "Image",
                type: "file",
                defaultValue: "/placeholders/360x300.png",
                recommendedWidth: 360,
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Specs: {
    template: "master_template",
    props: [
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderWidth",
        nameText: "Border Width",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "borderColor",
        nameText: "Border Color",
        type: "color",
        defaultValue: "#dddddd",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Spec",
            props: [
              {
                name: "isMain",
                nameText: "Is Header",
                type: "select",
                values: ["Yes", "No"],
                defaultValue: "No",
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Table: {
    template: "master_template",
    props: [
      {
        name: "color",
        nameText: "Text Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "fontFamily",
        nameText: "Text Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "fontWeight",
        nameText: "Text Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "columns",
        nameText: "Columns Quantity",
        type: "number",
        defaultValue: "1",
      },
      {
        name: "borderWidth",
        nameText: "Border Width",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "borderColor",
        nameText: "Border Color",
        type: "color",
        defaultValue: "#dddddd",
      },
      {
        name: "textAlign",
        nameText: "Text Align",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "justifyContent",
        nameText: "Justify Content",
        type: "select",
        defaultValue: "flex-start",
        values: ["flex-start", "center", "flex-end"],
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Row",
            props: [
              {
                name: "rowContent",
                type: "textArray",
                nameText: "Column",
                defaultValue: ['Это пример подписи'],
              },
              {
                name: "rowImages",
                type: "imgArray",
                nameText: "Column",
                defaultValue: [''],
              },
              {
                name: "rowColors",
                type: "colorArray",
                nameText: "Text Color Column",
                defaultValue: [''],
              },
              {
                name: "rowBackgrounds",
                type: "colorArray",
                nameText: "Background color Column",
                defaultValue: [''],
              },
            ],
          },
        ],
      },
    ],
  },
  Bubbles: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "background",
        nameText: "Content Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "bubbleBackground",
        nameText: "Bubble Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "bubbleActiveBg",
        nameText: "Bubble Active Background Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "bubbleColor",
        nameText: "Bubble Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "bubbleActiveColor",
        nameText: "Bubble Active Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "closeBubbleColor",
        nameText: "Close Bubble Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "closeBubbleBackground",
        nameText: "Close Bubble Background Color",
        type: "color",
        defaultValue: "#000000",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Bubble",
            props: [
              {
                name: "top",
                nameText: "Bubble Position Top",
                type: "number",
                defaultValue: "50",
              },
              {
                name: "left",
                nameText: "Bubble Position Left",
                type: "number",
                defaultValue: "50",
              },
              {
                name: "bubbleContentPosition",
                nameText: "Bubble Content Position",
                type: "select",
                defaultValue: "left-bottom",
                values: ["left-bottom", "right-bottom", "left-top", "right-top"],
              },
              {
                name: "textAlign",
                nameText: "Text Align",
                type: "textAlign",
                defaultValue: "left",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "textAlignMobile",
                nameText: "Text Align Mobile",
                type: "textAlign",
                defaultValue: "center",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример заголовка",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  PictureWithText: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
      },
      {
        name: "srcMob",
        nameText: "Mobile Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },


      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "vertical",
                nameText: "Vertical Position",
                type: "select",
                defaultValue: "top",
                values: ["top", "middle", "bottom"],
              },
              {
                name: "horisontal",
                nameText: "Horisontal Position",
                type: "select",
                defaultValue: "middle",
                values: ["left", "middle", "right"],
              },
              {
                name: "textWidth",
                nameText: "Text Width",
                type: "number",
                defaultValue: "50",
              },
              {
                name: "textAlign",
                nameText: "Text Align",
                type: "textAlign",
                defaultValue: "left",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "textAlignMobile",
                nameText: "Text Align Mobile",
                type: "textAlign",
                defaultValue: "center",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "titleColor",
                nameText: "Title Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleColorMobile",
                nameText: "Title Color Mobile",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleFontSize",
                nameText: "Title Font Size",
                type: "fontSize",
                defaultValue: "4",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionColor",
                nameText: "Description Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionColorMobile",
                nameText: "Description Color Mobile",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionFontSize",
                nameText: "Description Font Size",
                type: "fontSize",
                defaultValue: "2",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  VideoWithText: {
    template: "master_template",
    props: [
      {
        name: "videoSrc",
        nameText: "Video",
        type: "video",
        defaultValue: "/placeholders/video.mp4"
      },
      {
        name: "autoplay",
        nameText: "Autoplay",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "repeat",
        nameText: "Repeat",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "controls",
        nameText: "Controls",
        type: "select",
        defaultValue: "true",
        values: ["true", "false"],
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "vertical",
                nameText: "Vertical Position",
                type: "select",
                defaultValue: "top",
                values: ["top", "middle", "bottom"],
              },
              {
                name: "horisontal",
                nameText: "Horisontal Position",
                type: "select",
                defaultValue: "middle",
                values: ["left", "middle", "right"],
              },
              {
                name: "textWidth",
                nameText: "Text Width",
                type: "number",
                defaultValue: "50",
              },
              {
                name: "textAlign",
                nameText: "Text Align",
                type: "textAlign",
                defaultValue: "left",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "textAlignMobile",
                nameText: "Text Align Mobile",
                type: "textAlign",
                defaultValue: "center",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "titleColor",
                nameText: "Title Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleColorMobile",
                nameText: "Title Color Mobile",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleFontSize",
                nameText: "Title Font Size",
                type: "fontSize",
                defaultValue: "4",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionColor",
                nameText: "Description Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionColorMobile",
                nameText: "Description Color Mobile",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionFontSize",
                nameText: "Description Font Size",
                type: "fontSize",
                defaultValue: "2",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
            ],
          },
        ],
      },
    ],
  },
  Accordion: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/1200x600.png",
      },
      {
        name: "videoSrc",
        nameText: "Video",
        type: "video",
        defaultValue: "",
      },
      {
        name: "autoplay",
        nameText: "Autoplay",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "repeat",
        nameText: "Repeat",
        type: "select",
        defaultValue: "false",
        values: ["true", "false"],
      },
      {
        name: "controls",
        nameText: "Controls",
        type: "select",
        defaultValue: "true",
        values: ["true", "false"],
      },
      {
        name: "youtubeID",
        nameText: "Youtube ID (replace image)",
        type: "text",
        defaultValue: "",
      },
      {
        name: "spacer",
        nameText: "Spacer Between features",
        type: "select",
        defaultValue: "no",
        values: ["no", "small", "medium", "large"],
      },
      {
        name: "customIcon",
        nameText: "Svg-code of opening icon",
        type: "text",
        defaultValue:
          "",
      },
      {
        name: "customCloseIcon",
        nameText: "Svg-code of closing icon",
        type: "text",
        defaultValue:
          "",
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "700",
        values: ["400", "700"],
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "borderRadius",
        nameText: "Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
      {
        name: "innerElements",
        type: "array",
        defaultValue: [
          {
            component: "Feature",
            props: [
              {
                name: "imgPosition",
                nameText: "Image Position",
                type: "select",
                defaultValue: "right",
                values: ["top", "bottom", "right", "left"],
              },
              {
                name: "imgPositionMobile",
                nameText: "Image Position Mobile",
                type: "select",
                defaultValue: "right",
                values: ["top", "bottom", "right", "left"],
              },
              {
                name: "src",
                nameText: "Feature Image",
                type: "file",
                defaultValue: "/placeholders/1200x600.png",
              },
              {
                name: "videoSrc",
                nameText: "Video",
                type: "video",
                defaultValue: "",
              },
              {
                name: "autoplay",
                nameText: "Autoplay",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "repeat",
                nameText: "Repeat",
                type: "select",
                defaultValue: "false",
                values: ["true", "false"],
              },
              {
                name: "controls",
                nameText: "Controls",
                type: "select",
                defaultValue: "true",
                values: ["true", "false"],
              },
              {
                name: "youtubeID",
                nameText: "Youtube ID (replace image)",
                type: "text",
                defaultValue: "",
              },
              {
                name: "textAlign",
                nameText: "Text Align",
                type: "textAlign",
                defaultValue: "left",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "textAlignMobile",
                nameText: "Text Align Mobile",
                type: "textAlign",
                defaultValue: "center",
                values: ["left", "center", "right", "justify"],
              },
              {
                name: "titleContent",
                nameText: "Title",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "descriptionContent",
                nameText: "Description",
                type: "text",
                defaultValue:
                  "Это пример описания. Здесь шрифт поменьше, а контента - побольше.",
              },
              {
                name: "titleColor",
                nameText: "Title Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "titleFontSize",
                nameText: "Title Font Size",
                type: "fontSize",
                defaultValue: "4",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "descriptionColor",
                nameText: "Description Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "descriptionFontSize",
                nameText: "Description Font Size",
                type: "fontSize",
                defaultValue: "2",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "background",
                nameText: "Content Background Color",
                type: "color",
                defaultValue: "#ffffff",
              },
              {
                name: "disclaimerContent",
                nameText: "Disclaimer",
                type: "text",
                defaultValue:
                  "Это пример описания",
              },
              {
                name: "disclaimerColor",
                nameText: "Disclaimer Color",
                type: "color",
                defaultValue: "#000000",
              },
              {
                name: "disclaimerFontSize",
                nameText: "Disclaimer Font Size",
                type: "fontSize",
                defaultValue: "1",
                values: ["1", "2", "3", "4", "5", "6", "7"],
              },
              {
                name: "disclaimerTextAlign",
                nameText: "Disclaimer Text Align",
                type: "textAlign",
                defaultValue: "left",
                values: ["left", "center", "right", "justify"],
              },
            ],
          },
        ],
      },
    ],
  },
  ARBanner: {
    template: "master_template",
    props: [
      {
        name: "src",
        nameText: "Image",
        type: "file",
        defaultValue: "/placeholders/ar.jpg",
      },
      {
        name: "background",
        nameText: "Background Color",
        type: "color",
        defaultValue: "#F5F5F5",
      },
      {
        name: "titleTextAlign",
        nameText: "Title Text Align",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleTextAlignMobile",
        nameText: "Title Text Align Mobile",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "titleFontSize",
        nameText: "Title Font Size",
        type: "fontSize",
        defaultValue: "4",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "titleContent",
        nameText: "Title",
        type: "text",
        defaultValue: "Продукт в интерьере",
      },
      {
        name: "titleColor",
        nameText: "Title Color",
        type: "color",
        defaultValue: "#151515",
      },
      {
        name: "titleFontFamily",
        nameText: "Title Font Family",
        type: "select",
        defaultValue: "Graphik LCG",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "titleFontWeight",
        nameText: "Title Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "descriptionTextAlign",
        nameText: "Description Text Align",
        type: "textAlign",
        defaultValue: "left",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "descriptionFontSize",
        nameText: "Description Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "descriptionContent",
        nameText: "Description",
        type: "text",
        defaultValue: "Оцените все преимущества продукта в 3D или посмотрите, как он будет выглядеть в интерьере с помощью камеры смартфона",
      },
      {
        name: "descriptionColor",
        nameText: "Description Color",
        type: "color",
        defaultValue: "#151515",
      },
      {
        name: "descriptionFontFamily",
        nameText: "Description Font Family",
        type: "select",
        defaultValue: "Graphik LCG",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "descriptionFontWeight",
        nameText: "Description Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "buttonContent",
        nameText: "Button Text",
        type: "text",
        defaultValue:
          "Посмотреть в интерьере",
      },
      {
        name: "buttonBackground",
        nameText: "Button Background",
        type: "color",
        defaultValue: "#151515",
      },
      {
        name: "buttonColor",
        nameText: "Button Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "buttonFontSize",
        nameText: "Button Font Size",
        type: "fontSize",
        defaultValue: "1",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "buttonFontFamily",
        nameText: "Button Font Family",
        type: "select",
        defaultValue: "Graphik LCG",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "buttonFontWeight",
        nameText: "Button Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "arLocale",
        nameText: "AR Locale",
        type: "select",
        defaultValue: "ru",
        values: ["ru", "en", "mx_es", "ae_en"],
      },
    ],
  },
  Button: {
    template: "master_template",
    props: [
      {
        name: "buttonPosition",
        nameText: "Button Position",
        type: "select",
        defaultValue: "center",
        values: ["flex-start", "center", "flex-end"],
      },

      {
        name: "textAlign",
        nameText: "Button TextAlign",
        type: "textAlign",
        defaultValue: "center",
        values: ["left", "center", "right", "justify"],
      },
      {
        name: "content",
        nameText: "Button Text",
        type: "text",
        defaultValue:
          "Скачать",
      },
      {
        name: "src",
        nameText: "File",
        type: "file",
        defaultValue: "",
      },
      {
        name: "background",
        nameText: "Background",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "buttonBackground",
        nameText: "Button Background",
        type: "color",
        defaultValue: "#151515",
      },
      {
        name: "color",
        nameText: "Button Color",
        type: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "fontSize",
        nameText: "Button Font Size",
        type: "fontSize",
        defaultValue: "2",
        values: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "fontFamily",
        nameText: "Button Font Family",
        type: "select",
        defaultValue: "Arial",
        values: ["Arial", "TimesNewRoman"],
      },
      {
        name: "fontWeight",
        nameText: "Button Font Weight",
        type: "select",
        defaultValue: "400",
        values: ["400", "700"],
      },
      {
        name: "borderRadius",
        nameText: "Button Border Radius",
        type: "number",
        defaultValue: 0,
        min: 0,
      },
    ]
  },
  Iframe: {
    template: "master_template",
    props: [
      {
        name: "link",
        nameText: "Iframe src",
        type: "text",
        defaultValue: "",
      },
      {
        name: "height",
        nameText: "Iframe height",
        type: "number",
        defaultValue: 300,
        min: 0,
      },
      {
        name: "heightUnits",
        nameText: "Height Units",
        type: "select",
        defaultValue: "px",
        values: ["px", "vh", "vw", "%"],
      },
    ],
  }
}
import ali_template from "./ali_template";
import master_template from "./master_template";
import eld_template from "./eld_template";
import ozon_template from "./ozon_template";
import img_template from "./img_template";
import amazon_template from "./amazon_template";

const collections = Object.assign(
  ali_template,
  eld_template,
  img_template,
  master_template,
  ozon_template,
  amazon_template,
);

export default collections;
<template>
  <div class="report">
    <div class="report-wrapper">
      <div class="report-close" @click="$emit('disable')"/>
      <div class="report-wrapper-header">Create a Report</div>

      <div class="report-wrapper-buttons">
        <va-checkbox :value="isAll" label="Select all" @input="toggleAll"/>
        <va-checkbox :value="isTitle" label="Add title page" @input="toggleTitle"/>
        <va-checkbox :value="isStamp" label="Add signature and stamp" @input="toggleStamp"/>
        <va-select v-if="isStamp" :value="stampType" :options="['TTL', '24TTL']" @input="toggleStampType" no-clear/>
        <va-checkbox :value="isPerTime" label="Add per time diagram" @input="togglePerTime"/>
        <va-checkbox :value="isPieChart" label="Add per chart diagram" @input="togglePieChart"/>
        <va-checkbox :value="isInfoTiles" label="Add info tiles" @input="toggleInfoTiles"/>
        <va-checkbox :value="isStats" label="Add stats" @input="toggleStats"/>
        <va-checkbox :value="isBrowsers" label="Add Browsers diagram" @input="toggleBrowsers"/>
        <va-checkbox :value="isOS" label="Add OS diagram" @input="toggleOS"/>
        <va-checkbox :value="isPlatforms" label="Add Platforms diagram" @input="togglePlatforms"/>
        <va-checkbox :value="isDevices" label="Add Devices diagram" @input="toggleDevices"/>
        <va-checkbox :value="isRetailers" label="Add Retailers stats diagram" @input="toggleRetailers"/>
        <va-checkbox :value="isBrands" label="Add Brand Stats diagram" @input="toggleBrands"/>
        <va-checkbox :value="isMap" label="Add map" @input="toggleMap"/>
        <va-checkbox :value="showValuesInLabels" label="Show values on labels" @input="toggleShowValuesInLabels"/>
      </div>
      <div class="btns flex md6 xs12 xl12">

        <va-button :style="{marginTop: 0, marginBottom: 0}" @click="downloadPdfReport">Create a Report</va-button>

        <div class="va-button va-button--outline va-button--normal btn--caps btn--white" tabindex="0"
             @click="onClose">
          <div class="va-button__content">
            <div class="va-button__content__title">Close</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateReport",

  data() {
    return {
      isAll: false,
      isStamp: false,
      isTitle: false,
      isPerTime: false,
      isPieChart: false,
      isInfoTiles: false,
      isStats: false,
      isBrowsers: false,
      isOS: false,
      isPlatforms: false,
      isDevices: false,
      isRetailers: false,
      isBrands: false,
      isMap: false,
      showValuesInLabels: false,
      stampType: 'TTL'
    }
  },

  methods: {
    onClose() {
      this.$emit('disable')
    },

    downloadPdfReport() {
      this.$root.$emit('downloadPdfReport')
    },

    checkForAll() {
      if (this.isStamp && this.isTitle && this.isPerTime &&
        this.isPieChart && this.isInfoTiles && this.isStats &&
        this.isBrowsers && this.isOS && this.isPlatforms &&
        this.isDevices && this.isRetailers && this.isBrands && this.isMap)
        this.isAll = true;
      else this.isAll = false;
    },

    toggleStamp() {
      this.isStamp = !this.isStamp
      this.checkForAll()
      this.$emit('toggleStamp')
    },

    toggleStampType(value) {
      this.stampType = value
      this.$emit('toggleStampType', value)
    },

    toggleTitle() {
      this.isTitle = !this.isTitle
      this.checkForAll()
      this.$emit('toggleTitle')
    },

    togglePerTime() {
      this.isPerTime = !this.isPerTime
      this.checkForAll()
      this.$emit('togglePerTime')
    },

    togglePieChart() {
      this.isPieChart = !this.isPieChart
      this.checkForAll()
      this.$emit('togglePieChart')
    },

    toggleInfoTiles() {
      this.isInfoTiles = !this.isInfoTiles
      this.checkForAll()
      this.$emit('toggleInfoTiles')
    },

    toggleStats() {
      this.isStats = !this.isStats
      this.checkForAll()
      this.$emit('toggleStats')
    },

    toggleBrowsers() {
      this.isBrowsers = !this.isBrowsers
      this.checkForAll()
      this.$emit('toggleBrowsers')
    },

    toggleOS() {
      this.isOS = !this.isOS
      this.checkForAll()
      this.$emit('toggleOS')
    },

    togglePlatforms() {
      this.isPlatforms = !this.isPlatforms
      this.checkForAll()
      this.$emit('togglePlatforms')
    },

    toggleDevices() {
      this.isDevices = !this.isDevices
      this.checkForAll()
      this.$emit('toggleDevices')
    },

    toggleRetailers() {
      this.isRetailers = !this.isRetailers
      this.checkForAll()
      this.$emit('toggleRetailers')
    },

    toggleBrands() {
      this.isBrands = !this.isBrands
      this.checkForAll()
      this.$emit('toggleBrands')
    },

    toggleMap() {
      this.isMap = !this.isMap
      this.checkForAll()
      this.$emit('toggleMap')
    },

    toggleShowValuesInLabels() {
      this.showValuesInLabels = !this.showValuesInLabels
      this.checkForAll()
      this.$emit('toggleShowValuesInLabels')
    },

    toggleAll() {
      if (this.isAll) {
        if (this.isStamp) this.toggleStamp();
        if (this.isTitle) this.toggleTitle();
        if (this.isPerTime) this.togglePerTime();
        if (this.isPieChart) this.togglePieChart();
        if (this.isInfoTiles) this.toggleInfoTiles();
        if (this.isStats) this.toggleStats();
        if (this.isBrowsers) this.toggleBrowsers();
        if (this.isOS) this.toggleOS();
        if (this.isPlatforms) this.togglePlatforms();
        if (this.isDevices) this.toggleDevices();
        if (this.isRetailers) this.toggleRetailers();
        if (this.isBrands) this.toggleBrands();
        if (this.isMap) this.toggleMap();
        if (this.showValuesInLabels) this.toggleShowValuesInLabels();
      } else {
        if (!this.isStamp) this.toggleStamp();
        if (!this.isTitle) this.toggleTitle();
        if (!this.isPerTime) this.togglePerTime();
        if (!this.isPieChart) this.togglePieChart();
        if (!this.isInfoTiles) this.toggleInfoTiles();
        if (!this.isStats) this.toggleStats();
        if (!this.isBrowsers) this.toggleBrowsers();
        if (!this.isOS) this.toggleOS();
        if (!this.isPlatforms) this.togglePlatforms();
        if (!this.isDevices) this.toggleDevices();
        if (!this.isRetailers) this.toggleRetailers();
        if (!this.isBrands) this.toggleBrands();
        if (!this.isMap) this.toggleMap();
        if (!this.showValuesInLabels) this.toggleShowValuesInLabels();
      }
    }
  }
}
</script>

<style lang="scss">
.report {
  width: 344px;
  height: 710px;
  background-color: #ffffff;
  padding: 40px;

  &-wrapper {
    display: flex;
    flex-direction: column;

    &-header {
      font-style: normal;
      font-weight: 550;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }

    &-buttons {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &-close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before {
        background-color: black;
      }

      &::after {
        background-color: black;
      }
    }
  }
}
</style>

// Polyfills
import "@babel/polyfill";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'allsettled-polyfill';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import {ColorThemePlugin} from '../services/vuestic-ui';
import store from '../store/index';
import router from '../router/index';
import {VuesticPlugin} from '../services/vuestic-ui/components';
import '../i18n/index';
import VueClipboard from 'vue-clipboard2';
import resize from "vue-element-resize-detector";
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import axios from "axios";
import vco from "v-click-outside";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import vueDebounce from 'vue-debounce';

import '../metrics';
import '../service-worker';

import {consoleBuildInfo} from 'vue-cli-plugin-build-info/plugin';
import VacCard from "@/components/ui/card/VacCard";

consoleBuildInfo();

Vue.use(VuesticPlugin);
Vue.use(VueClipboard);
Vue.use(resize);
Vue.use(vco);
Vue.use(Element, {locale})
Vue.component('VacCard', VacCard)
Vue.use(FloatingVue)

export const bus = new Vue();
Vue.use(vueDebounce);


Vue.use(ColorThemePlugin, {
  // override colors here.
  themes: {
    primary: '#000000',
  },
  primary: '#000000',
});

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error?.response?.status === 401) {
    localStorage.removeItem('accessToken')
    router.push('/auth/login').catch(() => {})
  }
  return Promise.reject(error)
})

// router.beforeEach((to, from, next) => {
//   store.commit('setLoading', true)
//   next()
// })

// router.afterEach((to, from) => {
//   store.commit('setLoading', false)
// })

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});

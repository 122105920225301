<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 494.332 494.332" width="1.2rem" height="1.2rem" style="enable-background: new 0 0 494.332 494.332;" xml:space="preserve">
	<path d="M255.265,494.316c0.025,0.014,0.035,0.018,0.028,0.015L255.265,494.316z"/>
    <path d="M254.001,1.414c-4.489-1.885-9.543-1.885-14.025,0L22.956,91.614c-6.021,2.502-9.943,8.383-9.941,14.903l0.079,253.738
		c0,6.87,3.857,13.152,9.984,16.249l213.943,108.422c6.381,3.234,13.922,3.231,20.301-0.007l213.542-108.415
		c6.119-3.097,9.983-9.379,9.983-16.249l0.47-249.247c0.017-9.078-5.437-17.272-13.818-20.759L254.001,1.414z M246.987,37.967
		l171.358,71.47l-171.358,83.339L75.611,109.437L246.987,37.967z M444.402,349.064l-179.21,90.865v-211.24l179.21-90.869V349.064z"
    />
</svg>
</template>

<script>
export default {
  name: "IconCube"
}
</script>

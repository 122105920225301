<template>
  <aside
    :class="computedClass"
    :style="computedStyle"
    class="app-sidebar"
  >
    <ul class="app-sidebar__menu">
      <template v-for="(item, key) in items">
        <app-sidebar-link-group
          v-if="item.children"
          :key="key"
          :active-by-default="hasActiveByDefault(item)"
          :children="item.children"
          :icon="item.meta && item.meta.iconClass"
          :minimized="minimized"
          :title="$t(item.displayName)"
        >
          <app-sidebar-link
            v-for="(subMenuItem, key) in item.children"
            :key="key"
            :title="$t(subMenuItem.displayName)"
            :to="{ name: subMenuItem.name }"
          />
        </app-sidebar-link-group>
        <app-sidebar-link
          v-else
          :key="key"
          :active-by-default="item.name === $route.name"
          :icon="item.meta && item.meta.iconClass"
          :minimized="minimized"
          :title="$t(item.displayName)"
          :to="{ name: item.name }"
        />
      </template>
    </ul>
    <PdfBox v-if="isCreatingReport" :is-optimized="isChrome" @startCreatingReport="activateReportCreating"></PdfBox>
    <div v-if="$root.$route.fullPath === '/panel/dashboard'" class="report-button va-button my-0 btn--caps va-button--default va-button--large flex"
         @click="activateReportCreating">
      <div class="va-button__content">
        <div :style="{fontWeight: 550}" class="va-button__content__title">Create a Report</div>
      </div>
    </div>
  </aside>
</template>

<script>
import {getNavigationRoutes} from './NavigationRoutes';
import AppSidebarLink from './components/AppSidebarLink';
import AppSidebarLinkGroup from './components/AppSidebarLinkGroup';
import {ColorThemeMixin} from '../../../services/vuestic-ui';
import {mapState} from "vuex";
import PdfBox from "@/components/report/PdfBox";

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
    PdfBox
  },
  mixins: [ColorThemeMixin],
  props: {
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  data() {
    return {
      items: getNavigationRoutes(),
      isCreatingReport: false,
      isChrome: false
    };
  },

  mounted() {
    this.$watch(vm => [
      vm.currentUserProfile,
      vm.currentUserProfilePermissions,
    ], () => {
      this.items = getNavigationRoutes()
    })
  },
  created() {
   this.isChrome = navigator.userAgent.indexOf("Chrome") !== -1 && navigator.userAgent.indexOf("Edg") === -1
   && navigator.userAgent.indexOf("YaBrowser") === -1 && navigator.userAgent.indexOf("OPR") === -1;
  },
  computed: {
    ...mapState(['currentUserProfile', 'currentUserProfilePermissions']),

    computedClass() {
      return {
        'app-sidebar--minimized': this.minimized,
      };
    },
    computedStyle() {
      return {
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0, 0, 0, 0.05)',
      };
    },
  },
  methods: {
    hasActiveByDefault(item) {
      return item.children.some(child => child.name === this.$route.name);
    },

    activateReportCreating() {
      this.isCreatingReport = !this.isCreatingReport
    }
  },
};

</script>

<style lang="scss">
.report-button {
  float: bottom;
  position: absolute;
  bottom: 11px;
  left: 12px;
  font-size: 16px !important;
  width: 231px;
  height: 48px;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
}

.app-sidebar {
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  &--minimized {
    flex: 0 0 3.25rem;
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }

  @media (max-width: 767px) {
    .report-button {
      display: none;
    }
  }
}
</style>

import dcopy from "deep-copy";
import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

const state = {
  activeLocale: 'ru',
}

const mutations = {
  addLocale (state, locale) {
    const components = dcopy(state.epackData.components)
    if (!components[locale]) {
      components[locale] = dcopy(state.epackData.components['ru'])
      const manifest = state.epackData.manifest
      manifest.data[locale] = {}
      manifest.data[locale].minisite = {}
      manifest.data[locale].minisite.master_template = {contentTypes: ["html", "json", "iframe"]}
      state.epackData.manifest = manifest
      state.activeLocale = locale
      state.epackData.components = components
      state.epackData.components[state.activeLocale]?.[state.activeTemplate]?.[state.activePage]?.push({})
      state.epackData.components[state.activeLocale]?.[state.activeTemplate]?.[state.activePage]?.pop()
      onEpackDataUpdate(state)
    }
  },

  deleteLocale (state, locale) {
    const components = dcopy(state.epackData.components)
    if (Object.keys(components).length > 1) {
      delete components[locale];
      if (state.activeLocale === locale) {
        state.activeLocale = Object.keys(components)[0]
      }
      const manifest = state.epackData.manifest;
      delete manifest.data[locale];
      state.epackData.components = components
      state.epackData.manifest = manifest
      onEpackDataUpdate(state, undefined, false, true)
    }
  },

  changeLocale (state, payload) {
    state.activeLocale = payload
    state.activeTemplate = 'master_template'
    state.activePage = 'index.html'
    state.activeComponent = null
  }
}

const actions = {
  async deleteLocale({commit, state}, {locale, epackId}) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: locale,
              template: null,
              page: null,
              components: null
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }
            }
          )
          .then((res) => {
            commit('deleteLocale', locale)
            resolve(res)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
      } else {
        commit('deleteLocale', locale)
        resolve(true)
      }
    })
  }
}

const getters = {
  locales (state) {
    const computedLocales = [];
    Object.keys(state.epackData.components).forEach((localeName) => {
      computedLocales.push(localeName);
    });
    return computedLocales;
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}

import dcopy from "deep-copy";
import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import {showToastError} from "@/services/Helpers/HelperToast";
import Vue from "vue";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

const state = {
  activePage: 'index.html',
}

const mutations = {
  addPage (state, page) {
    const components = dcopy(state.epackData.components)
    if (!components[state.activeLocale][state.activeTemplate][page]) {
      components[state.activeLocale][state.activeTemplate][page] =
        dcopy(state.epackData.components[state.activeLocale][state.activeTemplate]['index.html']) || []
      state.activePage = page
      state.activeComponent = null
      state.epackData.components = components
      onEpackDataUpdate(state)
    } else {
      showToastError('A page with the given name already exists', Vue.prototype.$toast);
    }
  },

  // WARN: do not call this directly, call the action to remove the page on the backend first
  deletePage (state, page) {
    const components = dcopy(state.epackData.components)
    delete components[state.activeLocale][state.activeTemplate][page];
    state.epackData.components = components
    if (state.activePage === page) {
      state.activePage = Object.keys(components[state.activeLocale][state.activeTemplate])[0]
      state.activeComponent = null
    }
    onEpackDataUpdate(state, undefined, false, true)
  },

  changePage (state, page) {
    state.activeComponent = null
    state.activePage = page
  }
}

const actions = {
  async deletePage ({commit, state}, {page, epackId}) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: state.activeLocale,
              template: state.activeTemplate,
              page: page,
              components: null
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }
            }
          )
          .then((res) => {
            commit('deletePage', page)
            resolve(res)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
      } else {
        commit('deletePage', page)
        resolve(true)
      }
    })
  }
}

const getters = {
  pages (state) {
    return Object.keys(state.epackData?.components?.[state.activeLocale]?.[state.activeTemplate] || {})
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}

<template>
  <div id="app" class="app">
    <router-view v-if="triedGettingUser && triedGettingPermissions" />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {currentUserPermissionsArray} from "@/components/admin/app-sidebar/NavigationRoutes";

  export default {
    name: 'app',

    data() {
      return {
        triedGettingUser: false,
        triedGettingPermissions: false,
        // Temporary config for 2.1.
        contextConfig: {
          gradient: true,
          shadow: 'lg', // 3 states: 'sm', 'lg', undefined (no shadow).
          invertedColor: false,
        },
      };
    },

    provide() {
      return {
        contextConfig: this.contextConfig,
      };
    },

    created() {
      this.getUserProfile().then(res => {
        this.triedGettingUser = true
        if (res === null) {
          this.triedGettingPermissions = true
        }
      })
    },

    watch: {
      // Temporary colors fix for 2.1.
      'contextConfig.invertedColor'(val) {
        const invertedColorClass = 'va-inverted-color';
        if (val) {
          document.body.classList.add(invertedColorClass);
        } else {
          document.body.classList.remove(invertedColorClass);
        }
      },

      '$route.name'() {
        this.handlePermissions()
      },

      async currentUserProfile() {
        if (this.currentUserProfile) {
          await this.getPermissions()
          this.checkPagePermissions()
          this.triedGettingPermissions = true
        } else {
          this.clearPermissions()
        }
      }
    },

    computed: {
      ...mapState(['currentUserProfile'])
    },

    methods: {
      ...mapActions(['getPermissions', 'getUserProfile']),
      ...mapMutations(['clearPermissions']),

      async handlePermissions() {
        if (this.triedGettingUser && this.currentUserProfile) {
          if (!this.$store.state.currentUserProfilePermissions.length) {
            await this.getPermissions()
          }
          this.checkPagePermissions()
        }
      },

      checkPagePermissions() {
        if (this.currentUserProfile &&
          (!(this.currentUserProfile.roles?.includes('ROLE_ADMIN') || this.$route.meta.globalPage)) &&
          this.$route.meta.requiredPermmission &&
          currentUserPermissionsArray().findIndex(p => p === this.$route.meta.requiredPermmission) === -1) {
          console.error('FORBIDDEN: The current user has no permission to use this page, redirecting to /');
          window.location.href = '/panel/dashboard'
        }
      }
    }
  };
</script>

<style lang="scss">
  @import '../sass/main.scss';

  body {
    height: auto;
    //overflow-y: scroll;

    #app {
      min-height: 100%;
    }

    .va-data-table__vuetable th.sortable {
      color: #000000;
    }
  }
</style>

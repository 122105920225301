import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import dcopy from "deep-copy";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

const state = {
  dataSources: []
}

const mutations = {
  setDataSources (state, payload) {
    state.dataSources = payload
  },

  setDataSource (state, payload) {
    if (payload) {
      const dataSourceIndex = state.dataSources.findIndex(ds => ds.id === payload.id)
      if (dataSourceIndex !== -1) {
        state.dataSources.splice(dataSourceIndex, 1, {
          ...payload,
          items: payload.items.map(item => {
            const { data, ...restArgs } = item
            return { ...data, ...restArgs }
          })
        })
      }
    }
  },

  setEpackDataSources (state, payload) {
    state.epackData.dataSources = payload
    onEpackDataUpdate(state)
  }
}

const actions = {
  async loadDataSources ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_URL}/api/shop-in-shop/data-sources`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      })
        .then(async (res) => {
          const dataSources = res.data?.data || []
          commit('setDataSources', dataSources)
          resolve(dataSources)
        })
        .catch(e => {
          console.error(e)
          commit('setDataSources', [])
          reject(e)
        })
    })
  },

  toggleEpackDataSourceItemById ({state, commit}, {dataSource, item}) {
    if (state.epackData.dataSources instanceof Array) state.epackData.dataSources = {}
    const selectedDataSources = state.epackData.dataSources ? dcopy(state.epackData.dataSources) : {}
    if (selectedDataSources[dataSource.id] && !selectedDataSources[dataSource.id].items[item.id]) {
      selectedDataSources[dataSource.id].items[item.id] = item
    } else if (!selectedDataSources[dataSource.id]) {
      selectedDataSources[dataSource.id] = {name: dataSource.name, items: {[item.id]: item}}
    } else {
      delete selectedDataSources[dataSource.id].items[item.id]
      if (!Object.keys(selectedDataSources[dataSource.id].items).length) {
        delete selectedDataSources[dataSource.id]
      }
    }
    commit('setEpackDataSources', selectedDataSources)
  },

  async loadDetailedDataSource ({commit}, dataSource) {
    await axios.get(`${BASE_URL}/api/shop-in-shop/data-sources/${dataSource.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    })
      .then(res => {
        commit('setDataSource', res.data?.data)
      })
      .catch(e => {
        console.error(e)
      })
  }
}

const getters = {
  dataSources (state) {
    return state.dataSources?.slice().sort((a,b) => b.id < a.id ? 1 : -1) || []
  },

  selectedDataSources (state) {
    return state.epackData.dataSources
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}

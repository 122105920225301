import dcopy from "deep-copy";
import {ALL_ELEMENTS} from "@/store/modules/constructor2/components/consts";
import {
  fixChildrenIds,
  getComponentNestedChildrenIds
} from "@/store/modules/constructor2/components";

const actions = {
  addTableRow ({ state, commit }) {
    if (state.activeComponent?.name === 'Table') {
      const epackDataComponents = dcopy(state.epackData.components)
      const components = epackDataComponents?.[state.activeLocale]?.[state.activeTemplate]?.[state.activePage] || []
      const componentToAdd = dcopy(ALL_ELEMENTS.TableRow)
      componentToAdd.name = 'TableRow'
      componentToAdd.id = components.reduce((prev, cur) => prev < cur.id ? cur.id : prev, 0) + 1
      componentToAdd.parentId = state.activeComponent.id
      const activeTableRows = components.filter(c => c.parentId === state.activeComponent.id).slice()
      componentToAdd.order = activeTableRows.reduce((prev, cur) => prev < cur.order ? cur.order : prev, 0) + 1
      const activeTableColumns = activeTableRows[0] ? components.filter(c => c.parentId === activeTableRows[0].id).slice() : null
      if (activeTableColumns?.length) {
        componentToAdd.children = activeTableColumns.map(col => dcopy({
          ...ALL_ELEMENTS.TableColumn,
          name: 'TableColumn',
          order: col.order,
        }))
      }
      components.push(componentToAdd)
      fixChildrenIds(componentToAdd, components)
      commit('setEpackDataComponents', epackDataComponents)
    }
  },
}

export default {
  actions
}

import locale from "./locale";
import template from "./template";
import components from "./components";
import table from "./table";
import fonts from "./fonts";
import epack from "./epack";
import dataSources from "./dataSources";
import page from "./page";
import historyModule from "./history";
import {DEFAULT_EPACK_DATA} from "@/store/modules/constructor2/consts";
import {DEFAULT_FONTS} from "@/store/modules/constructor2/fonts/consts";
import dcopy from "deep-copy";

export default {
  namespaced: true,

  state: {
    loading: true,
    activeTab: 'constructor',
    activeSize: 'desktop',
    windowWidth: 0,
    windowHeight: 0,
    ...epack.state,
    ...components.state,
    ...dataSources.state,
    ...locale.state,
    ...template.state,
    ...fonts.state,
    ...page.state,
    ...historyModule.state,
  },

  mutations: {
    set (state, payload) {
      state[payload.stateProp] = payload.value
    },

    updateWindowSize(state) {
      const container = document.querySelector('.stream-editor-editor-panel__container')
      if (container) {
        state.windowWidth = container.offsetWidth
        state.windowHeight = container.offsetHeight
      }
    },

    reset (state) {
      state.loading = true
      state.activeEpackage = null
      state.epackData = dcopy(DEFAULT_EPACK_DATA)
      state.epackSavedInBackend = false
      state.activeTemplate = 'master_template'
      state.activePage = 'index.html'
      state.activeLocale = 'ru'
      state.activeTab = 'constructor'
      state.activeSize = 'desktop'
      state.activeComponent = null
      state.loadedComponents = []
      state.updatedComponents = []
      state.dataSources = []
      state.availableFonts = dcopy(DEFAULT_FONTS)
      state.lastHistoryUpdate = null
      state.historyIndex = -1
      state.history = []
    },

    ...epack.mutations,
    ...components.mutations,
    ...dataSources.mutations,
    ...locale.mutations,
    ...template.mutations,
    ...page.mutations,
    ...historyModule.mutations,
  },

  actions: {
    ...table.actions,
    ...components.actions,
    ...dataSources.actions,
    ...fonts.actions,
    ...epack.actions,
    ...page.actions,
    ...template.actions,
    ...locale.actions,
  },

  getters: {
    ...components.getters,
    ...dataSources.getters,
    ...locale.getters,
    ...page.getters,
    ...fonts.getters,
    ...historyModule.getters,
    ...template.getters,
  }
}

import {getCurrentDateRange} from "../services/Helpers/HelperTime";

export default {
  data() {
    return {
      defaultFilters: {},
      user: JSON.parse(localStorage.getItem('ttlUserInfo')),
      loading: true,
      filters: {
        licenses: [],
        retailers: [],
        categories: [],
        contentType: 'minisite',
        range: getCurrentDateRange(),
      },
      noData: false,
      infoTiles: [
        {
          color: 'success',
          value: '0',
          text: 'E-packages',
          icon: '',
        },
        {
          color: 'danger',
          value: "0",
          text: 'Licenses',
          icon: '',
        },
        {
          color: 'info',
          value: "0",
          text: 'Retailers connected',
          icon: '',
        },
      ],
    }
  },
  methods: {
    async onChangeFilters(data) {
      this.loading = true;
      this.filters = data;
      await this.refreshData();
      this.loading = false;
    },
    onErrorFilters() {
      this.loading = false;
      this.noData = true
    }
  }
}

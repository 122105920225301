<template>
  <div class="auth-layout row align-content--center">

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center auth-block">
        <vac-card class="auth-layout__card">
          <div class="auth-header">
            <va-icon-vuestic />
            <a href="https://24ttl.net/24stream" rel="noreferrer noopener" class="auth-link">go to 24TTL</a>
          </div>
          <div class="pa-3">
            <router-view />
          </div>
        </vac-card>
      </div>
    </div>

  </div>
</template>

<script>
import VaIconVuestic from '../iconset/VaIconVuestic';

const tabs = [
  'login',
  'signup',
];

export default {
  name: 'AuthLayout',
  components: { VaIconVuestic },
  data () {
    return {
      selectedTabIndex: 0,
      tabTitles: ['login', 'createNewAccount'],
    };
  },
  computed: {
    tabIndex: {
      set (tabIndex) {
        this.$router.push({ name: tabs[tabIndex] });
      },
      get () {
        return tabs.indexOf(this.$route.name);
      },
    },
  },
};
</script>

<style lang="scss">
  .auth-layout {
    min-height: 100vh;
    align-items: center;
    background: linear-gradient(225deg, #4598db 0%, #db458d 100%);

    * {
      font-family: 'Helvetica Neue', Arial, sans-serif !important;
    }

    .auth-block {
      flex-direction: column;
      align-items: center;

      .auth-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
      }

      .auth-header {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .auth-logo {
          width: 168px;
          height: 40px;
        }

        .auth-link {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #4598db;
          transition: 0.28s;

          &:hover {
            color: #317bbc;
          }
        }
      }
    }

    &__card {
      width: 100%;
      max-width: 496px;
      box-shadow: none !important;

      .vac-card__body {
        padding: 48px;

        .pa-3 {
          padding: 0 !important;
        }

        .va-button {
          margin: 0;
        }
      }
    }

    &__options {
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }
  }
</style>

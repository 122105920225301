import moment from "moment";

export default function (date, range, interval = 'Monthly') {
  if (!date) return null
  const offset = moment(Date.now()).utcOffset()
  const timezone = (offset < 0 ? '-' : '+') + (Math.abs(offset) / 60 < 10 ? '0' : '') + (offset % 60 === 0 ? `${Math.floor(Math.abs(offset) / 60)}:00` : `${Math.floor(Math.abs(offset) / 60)}:30`)
  date = date.slice(0, -6) + timezone;
  const momentDate = moment(date),
    dateTypeDate = momentDate.toDate(),
    week = momentDate.week(),
    month = momentDate.format('MMMM'),
    year = momentDate.year()

  let lastDay = -1,
    day = momentDate.date()

  if (interval !== "Daily") {
    if (parseInt(momentDate.format('MM')) < parseInt(moment(range.time_local.lte).format('MM'))) {
      lastDay = parseInt(momentDate.endOf('month').format('DD'))
    } else if (parseInt(momentDate.format('MM')) === parseInt(moment(range.time_local.lte).format('MM'))) {
      lastDay = Math.min(parseInt(moment(range.time_local.lte).format('DD')), parseInt(momentDate.endOf('month').format('DD')))
    }

    if (parseInt(momentDate.format('MM')) > parseInt(moment(range.time_local.gte).format('MM'))) {
      day = parseInt(momentDate.startOf('month').format('DD'))
    } else if (parseInt(momentDate.format('MM')) === parseInt(moment(range.time_local.gte).format('MM'))) {
      day = Math.max(parseInt(moment(range.time_local.gte).format('DD')), parseInt(momentDate.startOf('month').format('DD')))
    }
  }


  if (interval === 'Yearly') {
    return {
      date: dateTypeDate,
      day,
      month,
      year,
      formattedDate: year.toString(),
      formattedDateString: year.toString()
    }
  } else if (interval === 'Monthly' || !interval) {
    return {
      date: dateTypeDate,
      day,
      month,
      year,
      formattedDate: `${day}${lastDay !== -1 && lastDay !== day ? '-' + lastDay : ''}-${month}-${year}`,
      formattedDateString: `${day}${lastDay !== -1 && lastDay !== day ? '-' + lastDay : ''} ${month} ${year}`
    }
  } else if (interval === 'Weekly') {
    return {
      date: dateTypeDate,
      day,
      month,
      year,
      formattedDate: `${week}-${month}-${year}`,
      formattedDateString: `Week ${week} ${month} ${year}`
    }
  } else if (interval === 'Daily') {
    return {
      date: dateTypeDate,
      day,
      month,
      year,
      formattedDate: `${day}-${month}-${year}`,
      formattedDateString: `${day} ${month} ${year}`
    }
  }
}

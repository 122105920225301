<template>
  <Portal to="body">
    <div class="pdf-window">
      <div v-if="!isOptimized && !isContinue" class="pdf-window-warning">
        <WarningScreen @continueCreating="continueCreating" @disable="disable"/>
      </div>
      <div v-if="isOptimized || isContinue" class="pdf-window-wrapper">
        <div class="pdf-window-content">
          <div class="pdf-window-content-box">
            <ContentBox
              :isBrands="isBrands"
              :isBrowsers="isBrowsers"
              :isDevices="isDevices"
              :isInfoTiles="isInfoTiles"
              :isMap="isMap"
              :isOS="isOS"
              :isPerTime="isPerTime"
              :isPieChart="isPieChart"
              :isPlatforms="isPlatforms"
              :isRetailers="isRetailers"
              :isStamp="isStamp"
              :stampType="stampType"
              :isStats="isStats"
              :isTitle="isTitle"
              :show-values-in-labels="showValuesInLabels"
              @disable="disable"/>
          </div>
          <div class="pdf-window-content-selectors">
            <CreateReport
              @disable="disable"
              @toggleBrands="toggleBrands"
              @toggleBrowsers="toggleBrowsers"
              @toggleDevices="toggleDevices"
              @toggleInfoTiles="toggleInfoTiles"
              @toggleMap="toggleMap"
              @toggleOS="toggleOS"
              @togglePerTime="togglePerTime"
              @togglePieChart="togglePieChart"
              @togglePlatforms="togglePlatforms"
              @toggleRetailers="toggleRetailers"
              @toggleStamp="toggleStamp"
              @toggleStampType="toggleStampType"
              @toggleStats="toggleStats"
              @toggleTitle="toggleTitle"
              @toggleShowValuesInLabels="toggleShowValuesInLabels"
            />
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import {Portal} from "portal-vue";
import CreateReport from "@/components/report/CreateReport";
import ContentBox from "@/components/report/ContentBox";
import WarningScreen from "@/components/report/WarningScreen";

export default {
  name: "PdfBox",
  components: {WarningScreen, ContentBox, CreateReport, Portal},
  props: {
    isOptimized: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isStamp: false,
      isTitle: false,
      isPerTime: false,
      isPieChart: false,
      isInfoTiles: false,
      isStats: false,
      isBrowsers: false,
      isOS: false,
      isPlatforms: false,
      isDevices: false,
      isRetailers: false,
      isBrands: false,
      isMap: false,
      isContinue: false,
      showValuesInLabels: false,
      stampType: 'TTL'
    }
  },
  created() {
    this.isContinue = this.isChrome
  },
  methods: {
    disable() {
      this.$emit('startCreatingReport')
    },

    toggleStamp() {
      this.isStamp = !this.isStamp
    },

    toggleStampType(value) {
      this.stampType = value
    },

    toggleTitle() {
      this.isTitle = !this.isTitle
    },

    togglePerTime() {
      this.isPerTime = !this.isPerTime
    },

    togglePieChart() {
      this.isPieChart = !this.isPieChart
    },

    toggleInfoTiles() {
      this.isInfoTiles = !this.isInfoTiles
    },

    toggleStats() {
      this.isStats = !this.isStats
    },

    toggleBrowsers() {
      this.isBrowsers = !this.isBrowsers
    },

    toggleOS() {
      this.isOS = !this.isOS
    },

    togglePlatforms() {
      this.isPlatforms = !this.isPlatforms
    },

    toggleDevices() {
      this.isDevices = !this.isDevices
    },

    toggleRetailers() {
      this.isRetailers = !this.isRetailers
    },

    toggleBrands() {
      this.isBrands = !this.isBrands
    },

    toggleMap() {
      this.isMap = !this.isMap
    },

    toggleShowValuesInLabels() {
      this.showValuesInLabels = !this.showValuesInLabels
    },

    continueCreating() {
      this.isContinue = !this.isContinue;
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6dee2;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

.pdf-window {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);

  &-wrapper {
    width: 975px;
    height: 710px;
    background: #f1f4f8;
    border-radius: 6px;
    transform: translateX(calc(50vw - 487.5px)) translateY(calc(50vh - 355px));
    position: absolute;
    opacity: 1;
  }

  &-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f4f8;
    border-radius: 6px;
    transform: translateX(calc(50vw - 212px)) translateY(calc(50vh - 177px));
    position: absolute;
    opacity: 1;
    width: 424px;
  }

  &-content {
    display: flex;
    height: 100%;

    &-box {
      height: 100%;
      overflow: auto;
    }
  }
}
</style>

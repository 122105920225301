<template>
  <div class="container">
    <span>The list of available brands<br />and retailers has been updated.</span>

    <button tabindex="0" class="va-button va-button--default va-button--normal action" @click="clicked" style="">
      <span class="va-button__content">
        <span class="va-button__content__title">Refresh</span>
      </span>
    </button>

  </div>
</template>

<script>
import API from '../../../services/API/API';

const user = JSON.parse(localStorage.getItem('ttlUserInfo'));

export default {

  data () {
    return {
      user: user,
    };
  },

  created () {},
  methods: {
    clicked () {
      API.APIPut(`${process.env.VUE_APP_API_URL}/users/${user.id}/clear-updated`, {}, () => {
        API.APIGet(`${process.env.VUE_APP_API_URL}/profile`, {}, (data) => {
          localStorage.setItem('ttlUserInfo', JSON.stringify(data));
          window.location.href = window.location.pathname + window.location.search;
        });
      });
    },
  },

};
</script>

<style lang="scss">
  .Vue-Toastification__toast--info {
    align-items: flex-start;
    background: linear-gradient(35.34deg, rgb(76, 150, 197) 0%, rgb(154, 106, 162) 50.52%, rgb(216, 72, 133) 100%);
    width: 310px;
    min-width: auto;
    min-height: auto;

    .va-button {
      color: #000000;
      border: 1px solid #ffffff;
      background: #ffffff !important;
      transition: 0.3s;
      display: block;
      margin-top: 40px;
      width: 100%;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
        background-color: transparent !important;
      }
    }
  }
</style>

<template>
  <div class="report-content">
    <Filters
      id="report-content-filters"
      :is-report="true"
      :loading="loading"
      :on-change="onChangeFilters"
      :use-categories="true"
      :load-on-init="!isAdmin"
      @filterError="onErrorFilters"
    />
    <div id="report-content">
      <TitlePage
        v-if="isTitle"
        :licenses-ids="filters.licenses"
        :is-stamp="isStamp"
        :stamp-type="stampType"
        :time-local="filters.range.time_local"
        class="html2pdf__page-break"
      />

      <PerTime v-if="isPerTime" :data="timeStats" :is-exportable="false" class="html2pdf__page-break"/>
      <template v-if="!loading && isPieChart">
        <PieTime v-if="!isCategoriesExists && isPieChart" :data="pieStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
        <PieTime v-if="isCategoriesExists && isPieChart" :data="pieCategories" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      </template>
      <template v-if="loading && isPieChart">
        <PieTime
          v-if="isPieChart"
          :data="{
            datasets: [],
            labels: [],
          }"
          :show-values-in-labels="showValuesInLabels"
          class="html2pdf__page-break"
          :is-exportable="false"/>
      </template>

      <InfoTiles v-if="isInfoTiles" :data="infoTiles" class="html2pdf__page-break"/>

      <Stats
        v-if="isStats"
        :impressionsCount="impressionsCount"
        :productsCount="productsCount"
        class="html2pdf__page-break"
        :usersCount="usersCount"/>

      <Browsers v-if="isBrowsers" :data="browserStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      <OS v-if="isOS" :data="osStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      <Platforms v-if="isPlatforms" :data="platformsStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      <Devices v-if="isDevices" :data="devicesStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false"/>


      <RetailerStats v-if="isRetailers" :data="retailerStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      <LicenseStats v-if="isLicenses" :data="licenseStats" :show-values-in-labels="showValuesInLabels" :is-exportable="false" class="html2pdf__page-break"/>
      <Maps v-if="isMap" :countriesData="mapStats" :for-report="true" :ipData="IPStats"/>
    </div>
  </div>
</template>

<script>
import BubbleMapData from '../../services/data/maps/BubbleMapData';
import ElasticAPI from '../../services/API/ElasticAPI';
import HelperUser from "../../services/Helpers/HelperUser";
import fetchFilters from '../../mixins/fetchFilters'
import {showToastError} from "@/services/Helpers/HelperToast";

export default {
  name: 'ReportContent',
  mixins: [fetchFilters],
  components: {
    Filters: () => import("@/components/filters/Filters"),
    Platforms: () => import('../stats/Platforms'),
    Devices: () => import('../stats/Devices'),
    LicenseStats: () => import('../stats/LicenseStats.vue'),
    RetailerStats: () => import('../stats/RetailerStats'),
    Maps: () => import('../stats/Maps'),
    Stats: () => import('../stats/Stats'),
    InfoTiles: () => import('../stats/InfoTiles'),
    OS: () => import('../stats/OS'),
    Browsers: () => import('../stats/Browsers'),
    PieTime: () => import('../stats/PieTime'),
    PerTime: () => import('../stats/PerTime'),
    TitlePage: () => import('../report/TitlePage')
  },

  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    isStamp: {
      type: Boolean,
      default: false
    },
    isPerTime: {
      type: Boolean,
      default: false
    },
    isPieChart: {
      type: Boolean,
      default: false
    },
    isInfoTiles: {
      type: Boolean,
      default: false
    },
    isStats: {
      type: Boolean,
      default: false
    },
    isBrowsers: {
      type: Boolean,
      default: false
    },
    isOS: {
      type: Boolean,
      default: false
    },
    isPlatforms: {
      type: Boolean,
      default: false
    },
    isDevices: {
      type: Boolean,
      default: false
    },
    isRetailers: {
      type: Boolean,
      default: false
    },
    isLicenses: {
      type: Boolean,
      default: false
    },
    isMap: {
      type: Boolean,
      default: false
    },
    stampType: {
      type: String,
      default: 'TTL'
    },
    showValuesInLabels: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    isAdmin() {
      return this.$store.state.currentUserProfile?.roles.includes('ROLE_ADMIN')
    }
  },

  data() {
    return {
      productsCount: 0,
      usersCount: 0,
      mapStats: {
        latlong: BubbleMapData.latlong,
        data: [],
      },
      checksStats: {
        datasets: [],
        labels: [],
      },
      IPStats: {
        latlong: {},
        data: [],
      },
      retailerStats: {
        labels: ['all'],
        datasets: [],
      },
      timeStats: {
        labels: ['all'],
        datasets: [],
      },
      licenseStats: {
        labels: ['all'],
        datasets: [],
      },
      pieStats: {
        labels: [],
        datasets: [],
      },
      pieCategories: {
        labels: [],
        datasets: [],
      },
      browserStats: {
        labels: [],
        datasets: [],
      },
      osStats: {
        labels: [],
        datasets: [],
      },
      devicesStats: {
        labels: [],
        datasets: [],
      },
      platformsStats: {
        labels: [],
        datasets: [],
      },
      impressionsCount: 0,
      visibleList: [],
      dataStats: [],
      progressMax: 0,
      isCategoriesExists: false
    };
  },

  methods: {
    loadData(licenses, retailers, categories) {
      this.infoTiles[1].value = this.filters.licenses.length
      const promiseRetailers = ElasticAPI.getRetailersStats(this.filters);
      const promiseMap = ElasticAPI.getMapStats(this.filters, BubbleMapData);
      const promiseTimeStat = ElasticAPI.getTimeStats(this.filters);
      const promiseLicenseStat = ElasticAPI.getLicenseStats(this.filters);
      const promisePieStat = ElasticAPI.getPieStats(this.filters);
      const promisePieCategories = ElasticAPI.getPieCategories(this.filters);
      const promisePopularProductsStat = ElasticAPI.getPopularProductsStat(this.filters);
      const promiseDataStats = ElasticAPI.getDataStats(this.filters);
      const promiseDataDevices = ElasticAPI.getDevicesStats(this.filters);
      const promiseIPMap = ElasticAPI.getIPMap(this.filters);
      const promiseEpackages = ElasticAPI.getEpackagesCount(this.filters);
      const promiseProducts = ElasticAPI.getProductsCount(this.filters);
      const promiseUsers = ElasticAPI.getUsersCount(this.filters);
      const promiseMonitor = ElasticAPI.getDataLiveMonitor(licenses.data, retailers.data);

      promiseEpackages.then(data => {
        this.infoTiles[0].value = (data).toLocaleString();
      });

      promiseProducts.then(data => {
        this.productsCount = data;
      });

      promiseUsers.then(data => {
        this.usersCount = data;
      });

      promiseIPMap.then(({latlong, datasets}) => {
        this.IPStats = {
          latlong,
          data: datasets,
        };
      });

      promiseRetailers.then(data => {
        this.impressionsCount = 0;
        data.forEach(info => {
          this.impressionsCount += info.data.reduce((sum, current) => sum + current);
        });
        this.retailerStats = {
          labels: ['all'],
          datasets: data,
        };
      });

      promiseMap.then(data => {
        this.mapStats = {
          latlong: BubbleMapData.latlong,
          data,
        };
      });

      promiseTimeStat.then(({labels, datasets}) => {
        this.timeStats = {labels, datasets};
      });

      promiseLicenseStat.then(data => {
        this.licenseStats = {
          labels: ['all'],
          datasets: data,
        };
      });

      promisePieStat.then(({labels, datasets}) => {
        this.pieStats = {labels, datasets};
      });

      promisePieCategories.then(({labels, datasets}) => {
        this.pieCategories = {labels, datasets};
        this.isCategoriesExists = datasets.some(i => !!i.data.length)
      });

      promisePopularProductsStat.then(({progressMax, datasets}) => {
        this.progressMax = progressMax;
        this.visibleList = datasets;
        this.visibleList.forEach((product, index) => {
          product.isShow = index >= 0 && index < 5;
        });
      });

      promiseDataDevices.then(({browser, os, device, platforms}) => {
        this.browserStats = {
          labels: browser.labels,
          datasets: browser.datasets,
        };
        this.osStats = {
          labels: os.labels,
          datasets: os.datasets,
        };
        this.devicesStats = {
          labels: device.labels,
          datasets: device.datasets,
        };
        this.platformsStats = {
          labels: platforms.labels,
          datasets: platforms.datasets,
        };
      });
      promiseDataStats.then(data => {
        this.dataStats = data;
      });

      promiseMonitor.then(({checks, retailersConnected}) => {
        this.checksStats = checks;
        this.$set(this.infoTiles[2], 'value', `${retailersConnected.length} / ${this.filters.retailers.length}`);
      });

      const promiseAll = Promise.allSettled([
        promiseRetailers,
        promiseMap,
        promiseTimeStat,
        promiseLicenseStat,
        promisePieStat,
        promisePieCategories,
        promisePopularProductsStat,
        promiseDataStats,
        promiseDataDevices,
        promiseIPMap,
        promiseEpackages,
        promiseProducts,
        promiseUsers,
        promiseMonitor,
      ]);

      promiseAll.then(some => {
        const rejected = some.filter(result => result.status === 'rejected').map(result => result.reason)
        if (rejected.length !== 0)
          throw rejected
        this.loading = false;
      }).catch(rejected => {
        console.error('Some api failed');
        console.error(rejected)
        showToastError('Something went wrong', this.$toast)
        this.visibleList = [];
        this.timeStats = {
          labels: ['all'],
          datasets: [],
        };
        this.pieStats = {
          labels: [],
          datasets: [],
        };
        this.infoTiles = [
          {
            color: 'success',
            value: '0',
            text: 'E-packages',
            icon: '',
          },
          {
            color: 'danger',
            value: "0",
            text: 'Licenses',
            icon: '',
          },
          {
            color: 'info',
            value: "0",
            text: 'Retailers connected',
            icon: '',
          },
        ];
        this.loading = false;
      });
    },

    async refreshData() {
      const {licenses, retailers} = HelperUser.getUserLicensesRetailers(
        this.filters.licenses,
        this.filters.retailers
      );

      this.defaultFilters["range"] = this.filters.range;
      this.defaultFilters["contentType"] = this.filters.contentType;
      return this.loadData(licenses, retailers, this.filters.categories);
    }
  },

};
</script>

<style lang="scss">
@media print {
  @page {
    size: auto;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .vac-card__header-title {
    color: #404a53 !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    margin: 50px 0 10px 0 !important;
    align-self: center !important;
  }
}

.row-equal .flex {
  .vac-card {
    height: 100%;
  }
}

.report-content {
  .vac-card {
    margin-bottom: 0 !important;
  }
}

.link-icon {
  color: #000000;
  transition: 0.3s;
  cursor: pointer;
}

.link-icon:hover {
  opacity: 0.7;
}

.td--small {
  width: 20%;
}

.dashboard-contributors-list {
  flex-direction: column;
  height: 100%;
}

.inner-loading {
  table {
    width: 100%;
  }

  .va-table td {
    word-break: break-all;
  }

  tbody,
  thead {
    width: 100%;
  }
}

#report-content {
  page-break-inside: auto;
  page-break-before: avoid;
  page-break-after: avoid;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}
.html2pdf__page-break {
  display: inline-block;
  page-break-inside: avoid;
}

</style>

import dcopy from "deep-copy";
import {ALL_ELEMENTS, DYNAMIC_DATA_COMPONENTS} from "@/store/modules/constructor2/components/consts";

const ALL_ELEMENTS_PROP_KEYS = Object.entries(ALL_ELEMENTS)
  .filter(([, value]) => value.props)
  .map(([key, value]) => ({
    key,
    props: Object.keys(value.props)
  }))
  .reduce((a, c) => ({ ...a, [c.key]: c.props }), {})

function kebabToPascal(str) {
  let result = str.replace(/-(\w)/g, (match, letter) => letter.toUpperCase());
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const syncComponentKey = (component) => {
  if (component.displayName) {
    switch (component.displayName) {
      case 'Accordion Header':
        component.componentKey = ALL_ELEMENTS.AccordionHeader.componentKey
        break
      case 'Accordion Content':
        component.componentKey = ALL_ELEMENTS.AccordionContent.componentKey
        break
      case 'Popover Header':
        component.componentKey = ALL_ELEMENTS.PopoverHeader.componentKey
        break
      case 'Popover Content':
        component.componentKey = ALL_ELEMENTS.PopoverContent.componentKey
        break
      case '360 Viewer':
        component.componentKey = ALL_ELEMENTS.The360Viewer.componentKey
        break
      case 'Loader':
        component.componentKey = ALL_ELEMENTS.The360ViewerLoader.componentKey
        break
      case 'Additional Content':
        component.componentKey = ALL_ELEMENTS.The360ViewerAdditionalContent.componentKey
        break
      case 'Dynamic Data Provider':
        component.componentKey = DYNAMIC_DATA_COMPONENTS.DynamicDataProvider.componentKey
        break
      case 'Dynamic Data Receiver':
        component.componentKey = DYNAMIC_DATA_COMPONENTS.DynamicDataReceiver.componentKey
        break
      default:
    }
  } else {
    const componentName = kebabToPascal(component.name)
    if (ALL_ELEMENTS[componentName]?.componentKey) {
      component.componentKey = ALL_ELEMENTS[componentName].componentKey
    } else {
      console.warn(`WARNING: No component key found for ${componentName}`)
    }
  }
}

const syncComponents = (componentsArray) => {
  return componentsArray.map(component => {
    const updatedComponent = dcopy(component)

    if (ALL_ELEMENTS_PROP_KEYS[updatedComponent.name]) {
      let addedNewProps = false
      syncComponentKey(updatedComponent)
      // add all missing props
      ALL_ELEMENTS_PROP_KEYS[updatedComponent.name].forEach(propKey => {
        if (!updatedComponent.props[propKey]) {
          addedNewProps = true
          updatedComponent.props[propKey] = dcopy(ALL_ELEMENTS[updatedComponent.name].props[propKey])
        }
      })
      // remove all redundant props
      const propsToRemove = []
      Object.keys(updatedComponent.props).forEach(propKey => {
        if (!ALL_ELEMENTS_PROP_KEYS[updatedComponent.name].includes(propKey)) {
          propsToRemove.push(propKey)
        }
      })
      propsToRemove.forEach(propKey => {
        delete updatedComponent.props[propKey]
      })

      // if new props are added, sort the props according to the all elements' props
      if (addedNewProps) {
        updatedComponent.props = ALL_ELEMENTS_PROP_KEYS[updatedComponent.name]
          .reduce((a, c) => ({...a, [c]: updatedComponent.props[c]}), {})
      }
    }

    return updatedComponent
  })
}

export default syncComponents

export default {
  StandardImageSidebarModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainHeadlineText",
        nameText: "Main headline text",
        type: "text",
        defaultValue: "This is main Headline text",
        maxlength: 160,
      },
      {
        name: "mainSubHeadlineText",
        nameText: "Main sub-headline text",
        type: "text",
        defaultValue: "This is main sub-headline text",
        maxlength: 200,
      },
      {
        name: "mainBodyText",
        nameText: "Main body text",
        type: "text",
        defaultValue: "This is main body text",
        maxlength: 500,
      },
      {
        name: "mainBulletPoint",
        nameText: "Main bullet point (each from new line)",
        type: "text",
        defaultValue: "This is main bullet point",
        // maxlength: 200,
      },
      {
        name: "mainImage",
        nameText: "Main Image",
        type: "file",
        defaultValue: "/placeholders/300x400.png",
        recommendedWidth: 300,
      },
      {
        name: "mainImageCaptionText",
        nameText: "Main image caption text",
        type: "text",
        defaultValue: "This is main caption text",
        maxlength: 200,
      },
      {
        name: "mainImageAltText",
        nameText: "Main image alt text",
        type: "text",
        defaultValue: "This is Main image alt text",
        maxlength: 100,
      },
      {
        name: "sidebarHeadlineText",
        nameText: "Sidebar headline text",
        type: "text",
        defaultValue: "This is Sidebar Headline text",
        maxlength: 200,
      },
      {
        name: "sidebarBodyText",
        nameText: "Sidebar body text",
        type: "text",
        defaultValue: "This is Sidebar body text",
        maxlength: 500,
      },
      {
        name: "sideBulletPoint",
        nameText: "Side bullet point (each from new line)",
        type: "text",
        defaultValue: "This is side bullet point",
        maxlength: 200,
      },
      {
        name: "sidebarImage",
        nameText: "Sidebar Image",
        type: "file",
        defaultValue: "/placeholders/350x175.png",
        recommendedWidth: 350,
      },
      {
        name: "sidebarImageAltText",
        nameText: "Sidebar image alt text",
        type: "text",
        defaultValue: "This is Sidebar image alt text",
        maxlength: 100,
      },
    ]
  },
  StandardSingleSideImageModule: {
    template: "amazon_template",
    props: [
      {
        name: "imagePosition",
        nameText: "Image position",
        type: "select",
        defaultValue: "left",
        values: ["left", "right"],
      },
      {
        name: "mainHeadlineText",
        nameText: "Main headline text",
        type: "text",
        defaultValue: "This is main Headline text",
        maxlength: 160,
      },
      {
        name: "mainBodyText",
        nameText: "Main body text",
        type: "text",
        defaultValue: "This is main body text",
        maxlength: 1000,
      },
      {
        name: "mainImage",
        nameText: "Main Image",
        type: "file",
        defaultValue: "/placeholders/300.png",
        recommendedWidth: 300,
      },
      {
        name: "mainImageAltText",
        nameText: "Main image alt text",
        type: "text",
        defaultValue: "This is Main image alt text",
        maxlength: 100,
      },
    ]
  },
  // StandardComparisonTableModule: {
  //   template: "amazon_template",
  //   props: [

  //   ]
  // },
  // StandardMultipleImageTextModule: {
  //   template: "amazon_template",
  //   props: [

  //   ]
  // },
  // StandardSingleImageSpecsDetailModule: {
  //   template: "amazon_template",
  //   props: [
  //     {
  //       name: "mainImage",
  //       nameText: "Sidebar Image",
  //       type: "file",
  //       defaultValue: "/placeholders/300.png",
  //       recommendedWidth: 300,
  //     },
  //     {
  //       name: "mainImageAltText",
  //       nameText: "Main image alt text",
  //       type: "text",
  //       defaultValue: "This is Main image alt text",
  //       maxlength: 100,
  //     },
  //     {
  //       name: "headlineText",
  //       nameText: "Headline text",
  //       type: "text",
  //       defaultValue: "This is Headline text",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "headlineDescription",
  //       nameText: "Headline description",
  //       type: "text",
  //       defaultValue: "This is Headline description",
  //       maxlength: 160,
  //     },
  //     {
  //       name: "subHeadlineDescription",
  //       nameText: "Sub-headline description",
  //       type: "text",
  //       defaultValue: "This is sub-headline description",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bodyTextDescription",
  //       nameText: "Body text description",
  //       type: "text",
  //       defaultValue: "This is Body text description",
  //       maxlength: 400,
  //     },
  //     {
  //       name: "headlineTechSpecs",
  //       nameText: "Headline tech specs",
  //       type: "text",
  //       defaultValue: "This is Headline tech specs",
  //       maxlength: 160,
  //     },
  //     {
  //       name: "subHeadlineTechSpecs",
  //       nameText: "Sub-Headline tech specs",
  //       type: "text",
  //       defaultValue: "This is Sub-Headline tech specs",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bulletPointTechSpecs",
  //       nameText: "Bullet point tech specs (each from new line)",
  //       type: "text",
  //       defaultValue: "This is bullet point tech specs",
  //       maxlength: 200, //maxLength: 100 Min position=1 (first) Max position=8 (last)
  //     },
  //     {
  //       name: "headlineTechText",
  //       nameText: "Headline tech text",
  //       type: "text",
  //       defaultValue: "This is Headline tech text",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bodyTextTechText",
  //       nameText: "Body text Tech Text",
  //       type: "text",
  //       defaultValue: "This is Body text Tech Text",
  //       maxlength: 1000,
  //     },
  //   ]
  // },
  // StandardSingleImageHighlightsModule: {
  //   template: "amazon_template",
  //   props: [
  //     {
  //       name: "mainImage",
  //       nameText: "Sidebar Image",
  //       type: "file",
  //       defaultValue: "/placeholders/350x175.png",
  //       recommendedWidth: 350,
  //     },
  //     {
  //       name: "mainImageAltText",
  //       nameText: "Main image alt text",
  //       type: "text",
  //       defaultValue: "This is Main image alt text",
  //       maxlength: 100,
  //     },
  //     {
  //       name: "headlineDescription",
  //       nameText: "Headline description",
  //       type: "text",
  //       defaultValue: "This is Headline description",
  //       maxlength: 160,
  //     },
  //     {
  //       name: "subHeadlineDescription1",
  //       nameText: "Sub-headline description 1",
  //       type: "text",
  //       defaultValue: "This is sub-headline description 1",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bodyTextDescription1",
  //       nameText: "Body text description 1",
  //       type: "text",
  //       defaultValue: "This is Body text description 1",
  //       maxlength: 1000,
  //     },
  //     {
  //       name: "subHeadlineDescription2",
  //       nameText: "Sub-headline description 2",
  //       type: "text",
  //       defaultValue: "This is sub-headline description 2",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bodyTextDescription2",
  //       nameText: "Body text description 2",
  //       type: "text",
  //       defaultValue: "This is Body text description 2",
  //       maxlength: 1000,
  //     },
  //     {
  //       name: "subHeadlineDescription3",
  //       nameText: "Sub-headline description 3",
  //       type: "text",
  //       defaultValue: "This is sub-headline description 3",
  //       maxlength: 200,
  //     },
  //     {
  //       name: "bodyTextDescription3",
  //       nameText: "Body text description 3",
  //       type: "text",
  //       defaultValue: "This is Body text description 3",
  //       maxlength: 1000,
  //     },
  //     {
  //       name: "headlineTechSpecs",
  //       nameText: "Headline tech specs",
  //       type: "text",
  //       defaultValue: "This is Headline tech specs",
  //       maxlength: 160,
  //     },
  //     {
  //       name: "bulletPointTechSpecs",
  //       nameText: "Bullet point tech specs (each from new line)",
  //       type: "text",
  //       defaultValue: "This is bullet point tech specs",
  //       maxlength: 200, //maxLength: 100 Min position=1 (first) Max position=8 (last)
  //     },
  //   ]
  // },
  StandardThreeImageTextModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainHeadlineText",
        nameText: "Main Headline text",
        type: "text",
        defaultValue: "This is Main Headline text",
        maxlength: 200,
      },
      {
        name: "innerElements",
        type: "array",
        minCount: 3,
        maxCount: 3,
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/300.png",
                recommendedWidth: 300,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/300.png",
                recommendedWidth: 300,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/300.png",
                recommendedWidth: 300,
              },
            ]
          },
        ]
      }
    ]
  },
  StandardFourImageTextModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainHeadlineText",
        nameText: "Main Headline text",
        type: "text",
        defaultValue: "This is Main Headline text",
        maxlength: 200,
      },
      {
        name: "innerElements",
        type: "array",
        minCount: 4,
        maxCount: 4,
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/220x220.png",
                recommendedWidth: 220,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/220x220.png",
                recommendedWidth: 220,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/220x220.png",
                recommendedWidth: 220,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/220x220.png",
                recommendedWidth: 220,
              },
            ]
          },
        ]
      }
    ]
  },
  StandardFourImageTextQuadrantModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainHeadlineText",
        nameText: "Main Headline text",
        type: "text",
        defaultValue: "This is Main Headline text",
        maxlength: 200,
      },
      {
        name: "innerElements",
        type: "array",
        minCount: 4,
        maxCount: 4,
        defaultValue: [
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/135x135.png",
                recommendedWidth: 135,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/135x135.png",
                recommendedWidth: 135,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/135x135.png",
                recommendedWidth: 135,
              },
            ]
          },
          {
            component: "Item",
            props: [
              {
                name: "blockHeadline",
                nameText: "Block Headline",
                type: "text",
                defaultValue: "This is Block Headline",
                maxlength: 160,
              },
              {
                name: "blockBodyText",
                nameText: "Block Body Text",
                type: "text",
                defaultValue: "This is Block Body Text",
                maxlength: 1000,
              },
              {
                name: "blockImage",
                nameText: "Block Image",
                type: "file",
                defaultValue: "/placeholders/135x135.png",
                recommendedWidth: 135,
              },
            ]
          }
        ],
      }
    ],
  },
  StandardImageTextOverlayModule: {
    template: "amazon_template",
    props: [
      {
        name: "overlayColorType",
        nameText: "Overlay color type",
        type: "select",
        defaultValue: "dark",
        values: ["dark", "light"],
      },
      {
        name: "mainImage",
        nameText: "Main Image",
        type: "file",
        defaultValue: "/placeholders/970x300.png",
        recommendedWidth: 970,
      },
      {
        name: "mainImageAltText",
        nameText: "Main image alt text",
        type: "text",
        defaultValue: "This is Main image alt text",
        maxlength: 100,
      },
      {
        name: "headlineText",
        nameText: "Headline text",
        type: "text",
        defaultValue: "This is Headline text",
        maxlength: 70,
      },
      {
        name: "bodyText",
        nameText: "Body text",
        type: "text",
        defaultValue: "This is body text",
        maxlength: 300,
      },
    ]
  },
  StandardTextModule: {
    template: "amazon_template",
    props: [
      {
        name: "headlineText",
        nameText: "Headline text",
        type: "text",
        defaultValue: "This is Headline text",
        maxlength: 160,
      },
      {
        name: "bodyText",
        nameText: "Body text",
        type: "text",
        defaultValue: "This is body text",
        maxlength: 5000,
      },
    ]
  },
  StandardProductDescriptionModule: {
    template: "amazon_template",
    props: [
      {
        name: "bodyText",
        nameText: "Body text",
        type: "text",
        defaultValue: "This is body text",
        maxlength: 6000,
      },
    ]
  },
  // StandardTechSpecsModule: {
  //   template: "amazon_template",
  //   props: [
  //     {
  //       name: "mainHeadlineText",
  //       nameText: "Main headline text",
  //       type: "text",
  //       defaultValue: "This is main Headline text",
  //       maxlength: 160,
  //     },
  //     {
  //       name: "tableCount",
  //       nameText: "Table Count",
  //       type: "select",
  //       defaultValue: "1",
  //       values: ["1", "2"],
  //     },
  //     {
  //       name: "innerElements",
  //       type: "array",
  //       minCount: 4,
  //       maxCount: 16,
  //       defaultValue: [
  //         {
  //           component: "Spec",
  //           props: [
  //             {
  //               name: "label",
  //               nameText: "Label",
  //               type: "text",
  //               defaultValue:
  //                 "This is Label",
  //             },
  //             {
  //               name: "description",
  //               nameText: "Description",
  //               type: "text",
  //               defaultValue:
  //                 "This is description",
  //             },
  //           ],
  //         },
  //         {
  //           component: "Spec",
  //           props: [

  //             {
  //               name: "label",
  //               nameText: "Label",
  //               type: "text",
  //               defaultValue:
  //                 "This is Label",
  //             },
  //             {
  //               name: "description",
  //               nameText: "Description",
  //               type: "text",
  //               defaultValue:
  //                 "This is description",
  //             },
  //           ],
  //         },
  //         {
  //           component: "Spec",
  //           props: [

  //             {
  //               name: "label",
  //               nameText: "Label",
  //               type: "text",
  //               defaultValue:
  //                 "This is Label",
  //             },
  //             {
  //               name: "description",
  //               nameText: "Description",
  //               type: "text",
  //               defaultValue:
  //                 "This is description",
  //             },
  //           ],
  //         },
  //         {
  //           component: "Spec",
  //           props: [
  //             {
  //               name: "label",
  //               nameText: "Label",
  //               type: "text",
  //               defaultValue:
  //                 "This is Label",
  //             },
  //             {
  //               name: "description",
  //               nameText: "Description",
  //               type: "text",
  //               defaultValue:
  //                 "This is description",
  //             },
  //           ],
  //         },
  //       ],
  //     }
  //   ]
  // },
  StandardCompanyLogoModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainImage",
        nameText: "Main Image",
        type: "file",
        defaultValue: "/placeholders/600x180.png",
        recommendedWidth: 600,
      },
      {
        name: "mainImageAltText",
        nameText: "Main image alt text",
        type: "text",
        defaultValue: "This is Main image alt text",
        maxlength: 100,
      },
    ]
  },
  StandardHeaderImageTextModule: {
    template: "amazon_template",
    props: [
      {
        name: "mainHeadlineText",
        nameText: "Main headline text",
        type: "text",
        defaultValue: "This is main Headline text",
        maxlength: 150,
      },
      {
        name: "mainSubHeadlineText",
        nameText: "Main sub-headline text",
        type: "text",
        defaultValue: "This is main sub-headline text",
        maxlength: 150,
      },
      {
        name: "mainBodyText",
        nameText: "Main body text",
        type: "text",
        defaultValue: "This is main body text",
        maxlength: 6000,
      },
      {
        name: "mainImage",
        nameText: "Main Image",
        type: "file",
        defaultValue: "/placeholders/970x600.png",
        recommendedWidth: 300,
      },
      {
        name: "mainImageAltText",
        nameText: "Main image alt text",
        type: "text",
        defaultValue: "This is Main image alt text",
        maxlength: 100,
      },
    ]
  },
}
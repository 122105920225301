import axios from 'axios';
import colors from '../configs/colors';
import colorsWaves from '../configs/colorsWaves';
import HelperTime from "../Helpers/HelperTime";

const handleThanks = (response, callback, errorCallback) => {
  if (response && response.status === 200) {
    const data = response.data.data ? response.data.data : response.data;
    return callback ? callback(data) : data;
  } else {
    return errorCallback ? errorCallback() : [];
  }
};

const handleError = (error, errorCallback) => {

  // if (error.response?.status && error.response.status === 401) {
  //   localStorage.removeItem('accessToken');
  //   localStorage.removeItem('constructorAccessToken');
  //   localStorage.removeItem('ttlUserInfo');
  //   window.location.href = '/auth/login';
  // }

  return errorCallback ? errorCallback(error) : console.log(error.message);
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @param {function} callback
 * @param {function} errorCallback
 */

const APIGet = (url, data, callback, errorCallback) => {
  data.headers = {
    Authorization: `Bearer ${window.localStorage.getItem('constructorAccessToken')}`,
  };

  axios.get(url, data)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      handleError(error, errorCallback);
    });
};

const APIPost = (url, data, callback, errorCallback, headers = {}, withoutAuth = false) => {
  if (!withoutAuth) {
    headers.headers = {
      Authorization: `Bearer ${window.localStorage.getItem('constructorAccessToken')}`,
    };
  }

  axios.post(url, data, headers)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      // console.log(error.message)
      handleError(error, errorCallback);
    });
};

const APIDelete = (url, data, callback, errorCallback) => {
  data.headers = { Authorization: `Bearer ${window.localStorage.getItem('constructorAccessToken')}` };
  axios.delete(url, data)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      // console.log(error.message)
      handleError(error, errorCallback);
    });
};

const APIPut = (url, data, callback, errorCallback) => {
  const headers = {};
  headers.headers = { Authorization: `Bearer ${window.localStorage.getItem('constructorAccessToken')}` };
  axios.put(url, data, headers)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      // console.log(error.message)
      handleError(error, errorCallback);
    });
};

export default {
  APIGet,
  APIPost,
  APIDelete,
  APIPut,
};

import dcopy from "deep-copy";
import {onEpackDataUpdate} from "@/store/modules/constructor2/epack";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL

const state = {
  activeTemplate: 'master_template',
}

const mutations = {
  addTemplate (state, template) {
    const components = dcopy(state.epackData.components)
    if (!components[state.activeLocale][template]) {
      components[state.activeLocale][template] = (template !== 'eld_template' && template !== 'ozon_template')
        ? dcopy(state.epackData.components[state.activeLocale].master_template)
        : { 'index.html': [] }
      state.templates = Object.keys(components[state.activeLocale])
      state.activeTemplate = template
      state.activePage = 'index.html'
      state.activeComponent = null
      state.epackData.components = components
      const manifest = state.epackData.manifest
      manifest.data[state.activeLocale].minisite[template] = {contentTypes: ["html", "json", "iframe"]}
      state.epackData.manifest = manifest
      onEpackDataUpdate(state)
    }
  },

  // WARN: do not call this directly, call the action to remove the template on the backend first
  deleteTemplate (state, template) {
    const components = dcopy(state.epackData.components)
    delete components[state.activeLocale][template];
    state.epackData.components = components
    state.templates = Object.keys(components[state.activeLocale])
    if (state.activeTemplate === template) {
      state.activeTemplate = Object.keys(components[state.activeLocale])[0]
      state.activeComponent = null
    }
    const manifest = state.epackData.manifest
    delete manifest.data[state.activeLocale].minisite[template];
    state.epackData.manifest = manifest
    onEpackDataUpdate(state, undefined, false, true)
  },

  changeTemplate (state,  payload) {
    state.activeTemplate = payload
    state.activePage = 'index.html'
    state.activeComponent = null
  }
}

const actions = {
  async deleteTemplate({commit, state}, { template, epackId }) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: state.activeLocale,
              template: template,
              page: null,
              components: null
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }
            }
          )
          .then((res) => {
            commit('deleteTemplate', template)
            resolve(res)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
      } else {
        commit('deleteTemplate', template)
        resolve(true)
      }
    })
  }
}

const getters = {
  templates (state) {
    const components = dcopy(state.epackData.components)
    const locale = components?.[state.activeLocale]
    if (locale) return Object.keys(locale)
    return []
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
